import React from 'react';

const Title1 = (props) => {
    return (
        <h1 className="content-ttl">
            {props.children}
        </h1>
    );
};
const Title2 = (props) => {
    return (
        <h2 className="content-ttl">
            {props.children}
        </h2>
    );
};
const Title3 = (props) => {
    return (
        <h3 className="content-ttl">
            {props.children}
        </h3>
    );
};

export {Title1, Title2, Title3};