import {Modal} from "react-bootstrap";
import LightBtn from "../../../component/buttons";
import React from "react";

const TaskOutputDataModal = (props) => {

    return (
        <Modal
            show={props.show} onShow={() => {
        }} onHide={() => {
            props.setShow(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>상세 데이터</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.row}
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={() => {props.setShow(false)}}>
                    취소
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskOutputDataModal;