import axios from "axios";


class ManageService {

    static getDashBoard(user) {
        return axios.get('/api/mng/dashboard', {
            params: {
                user: user
            }
        })
    }

    static getUsrDataUsage() {
        return axios.get('/api/mng/dashboard/dataUsage')
    }

    static loadMemberByUsername(){
        return axios.post('/api/mng/user');
    }

    static getProjectBoard() {
        return axios.get('/api/mng/projects')
    }

    static saveProject(project) {
        return axios.post('/api/mng/project/save', project)
    }

    static getInfoProject(prjId) {
        return axios.get('/api/mng/project/info/' +  prjId)
    }

    static checkUserProject(shareVo) {
        return axios.post('/api/mng/project/share/check', shareVo)
    }
    static getProjectUser(prjId){
        return axios.get('/api/mng/project/user/'+prjId)
    }
    static getProjectSummary(prjId){
        return axios.get('/api/mng/project/summary/'+prjId)
    }
    static getShareUserProject(prjId) {
        return axios.get('/api/mng/project/share/user/'+prjId)
    }

    static getUtilizationData(prjId){
        return axios.get('/api/mng/project/utlzData/'+prjId)
    }

    static datasetMultipleCopy(multipleDatasetVo, config){
        return axios.post('/api/mng/dataset/multiple/copy', multipleDatasetVo, config)
    }

    static shareProject(ProjectShareWithDataSetVO){
        return axios.post('/api/mng/project/share', ProjectShareWithDataSetVO)
    }

    static rePermissionProject(shareVo){
        return axios.post('/api/mng/project/repermission', shareVo)
    }

    static revokeProject(shareVo){
        return axios.post('/api/mng/project/revoke', shareVo)
    }

    static copyProject(shareVo, config){
        return axios.post('/api/mng/project/copy', shareVo, config)
    }

    static projectDeleteCheck(prjId){
        return axios.get(`/api/mng/project/deleteCheck/${prjId}`);
    }

    static deleteProject(prjId) {
        return axios.get(`/api/mng/project/delete/${prjId}`)
    }

    static getDatasetList() {
        return axios.post('/api/mng/datasets');
    }

    static getDatasetBoard(prjId) {
        return axios.get('/api/mng/datasets/' + prjId);
    }

    static saveDataset(dataset) {
        return axios.post('/api/mng/dataset/save', dataset)
    }

    static checkUserDataset(shareVo) {
        return axios.post('/api/mng/dataset/share/check', shareVo)
    }
    static getDatasetUser(dtsId){
        return axios.get('/api/mng/dataset/user/'+dtsId)
    }

    static getShareUserDataset(dtsId) {
        return axios.get('/api/mng/dataset/share/user/'+dtsId)
    }

    static shareDataset(shareVo) {
        return axios.post('/api/mng/dataset/share', shareVo)
    }

    static rePermissionDataset(shareVo) {
        return axios.post('/api/mng/dataset/repermission', shareVo)
    }

    static revokeDataset(shareVo) {
        return axios.post('/api/mng/dataset/revoke', shareVo)
    }

    static copyDataset(shareVo, config) {
        return axios.post('/api/mng/dataset/copy', shareVo, config)
    }

    static getDatasetInfo(dtsId) {
        return axios.get('/api/mng/dataset/' + dtsId);
    }

    static saveField(field) {
        return axios.post('/api/mng/field/save', field)
    }

    static deleteField(field) {
        return axios.post('/api/mng/field/delete', field)
    }

    static updateFieldOrder(fields) {
        return axios.post('/api/mng/field/update/order', fields)
    }

    static getStorageList(){
        return axios.post('/api/mng/storage');
    }

    static loadStat(){
        return axios.get('/api/mng/stat/list');
    }

    static makeTempExcel(makeExcelVO){
        return axios.post('/dataset/excel/temp', makeExcelVO)
    }

    static getDatasetByProjectShareUser(projectSharUserVO){
        return axios.post('/api/mng/project/shareDatasetUser', projectSharUserVO)
    }

    static requestCPUData(){
        return axios.get('/api/metrics/system.cpu.usage')
    }

    static requestMemoryMaxData(){
        return axios.get('/api/metrics/jvm.memory.used')
    }
}


export default ManageService;
