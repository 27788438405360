import axios from "axios";

class ProcessService {


    static getProcessBoard() {
        return axios.get('/api/prc/processes')
    }

    static saveProcess(processObj) {
        return axios.post('/api/prc/process/save', processObj)
    }

    static deleteProcess(processObj) {
        return axios.post('/api/prc/process/delete', processObj)
    }

    static getTaskList(prcId) {
        return axios.get(`/api/prc/tasks/${prcId}`)
    }

    static getProcessDatasetList(prcId) {
        return axios.get(`/api/prc/datasets/${prcId}`)
    }

    static saveProcessDataset(processDataset) {
        return axios.post('/api/prc/dataset/save', processDataset)
    }

    static deleteProcessDataset(processDataset) {
        return axios.post('/api/prc/dataset/delete', processDataset)
    }

    static getPlugins(){
        return axios.get(`/api/prc/plugins`);
    }
}

export default ProcessService;
