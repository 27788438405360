import React from 'react';
import {NavLink} from "react-router-dom";
import {Title1} from "../../../../page/component/titles";

const ATPageHeaderComponent = (props) => {
    return (
        <div className="page-header d-print-none">
            {/*<div className="col">*/}
            {/*    <div className="page-pretitle">*/}
            {/*        {props.preTitle}*/}
            {/*    </div>*/}
            {/*    <h2 className="page-title">*/}
            {/*        {props.title}*/}
            {/*    </h2>*/}
            {/*</div>*/}
            <Title1>{props.title}</Title1>
            <div className="col-auto ms-auto">
                <ol className="breadcrumb float-sm-right">
                    {props.breadcrumbs.map(({
                                                match,
                                                breadcrumb
                                            }, i) => {
                        if (i === (props.breadcrumbs.length - 1)) {
                            return (
                                <li className={`breadcrumb-item`}
                                    key={match.pathname}>
                                    <span>{breadcrumb}</span>
                                </li>
                            )
                        } else {
                            return (
                                <li className={`breadcrumb-item active`}
                                    key={match.pathname}>
                                    <NavLink to={match.pathname}>{breadcrumb}</NavLink>
                                </li>
                            )
                        }

                    })}
                </ol>
            </div>
        </div>
    );
};

export default ATPageHeaderComponent;
