import axios from "axios";

class DataService {
    static getRowData(dtsId, pageNo, pageSize) {
        return axios.get("/api/data/rows/" + dtsId, {
            params: {
                pageNo: pageNo,
                pageSize: pageSize
            }
        })
    }

    static getFileData(dtsId, pageNo, pageSize, fdCd) {
        return axios.get("/api/data/files/" + dtsId, {
            params: {
                pageNo: pageNo,
                pageSize: pageSize,
                fdCd: fdCd
            }
        })
    }

    static getOneRowData(dtsId, dtId) {
        return axios.get("/api/data/row/" + dtsId, {
            params: {
                dtId: dtId
            }
        })
    }

    static saveRowData(dtsId, dtId, data, mntr) {
        return axios.post("/api/data/row/save", {dtsId: dtsId, dtId: dtId, data: data, monitoring:mntr});
    }

    static deleteRowData(dtsId, dtIds){
        return axios.post(`/api/data/row/delete/${dtsId}`,dtIds);
    }

    static deleteZipFile(dtsId) {
        return axios.get(`/api/file/delete/zip/${dtsId}`);
    }

    static deleteDataFile(dtsId) {
        return axios.get(`/dataset/data/delete/${dtsId}`);
    }
}

export default DataService;
