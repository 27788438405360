import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Stack} from "react-bootstrap";
import StepFooter from "./component/StepFooter";
import {Title2} from "../page/component/titles";
import PopupATPageHeader from "./component/PopupATPageHeader";
import MakeFieldComponent from "../page/manage/component/MakeFieldComponent";
import DataService from "../page/service/DataService";
import "./popup.css"
import Swal from "sweetalert2";

const DataContent = (props) => {

    const [tempData, setTempData] = useState({});

    function initNewData() {
        console.log('initNewData');
        let tempData = {};
        /**
         * 저장공간 작성
         */
        props.fieldList.forEach(f => {

            let defaultValue = null;

            tempData = {
                ...tempData,
                [f.fdCd]: defaultValue
            };

            if (f.child !== undefined) {
                f.child.forEach(fc => {
                    let defaultValue = null;

                    tempData = {
                        ...tempData,
                        [fc.fdCd]: defaultValue
                    }
                })
            }
        });

        props.setData(tempData);
        setTempData(tempData);
    }

    useEffect(() => {
        initNewData();
    }, [props.dtsId]);

    const saveData = () => {
        let dtId = null;
        DataService.saveRowData(props.dtsId, dtId, props.data).then(r => {
            if (r !== undefined && r.status === 200) {
                props.loadDatasetInfo(props.dtsId);
            } else {
                Swal.fire("저장 실패")
            }
        })
    };

    const updateSetData = () => {
        DataService.getOneRowData(props.dtsId, props.pData.dataMeta.dtId).then(res => {
            props.setData(res.data.values);
        });
    };

    return (
        <>
            <PopupATPageHeader
                title={"데이터"}
                pageList={[
                    {
                        "header": "프로젝트",
                        "step":4
                    },
                    {
                        "header": "데이터셋",
                        "step":5
                    },
                    {
                        "header": "필드",
                        "step":6
                    },
                    {
                        "header": "데이터",
                        "step":8
                    }
                ]}
                goToStep={props.goToStep}
            />
            <Row>
                <Col>
                    <h5>Data Content</h5>
                    <p className={"mb-1"}>새로 생성할 데이터 정보를 작성하고 저장합니다.</p>
                </Col>
            </Row>
            <p/>
            <hr/>
            <Card className={'data-project-list'}>
                <Card.Header>
                    <Stack>
                        <Title2>데이터 정보</Title2>
                    </Stack>
                </Card.Header>
                <Card.Body>
                    <Row>
                        {
                            props.fieldList.map(f => {
                                return (
                                    <MakeFieldComponent
                                        key={f.fdCd}
                                        field={f}
                                        data={props.data}
                                        type={"new"}
                                        setData={props.setData}
                                        dtId={null}
                                        reloadData={props.reloadData}
                                        updateSetData={updateSetData}
                                    />
                                )
                            })
                        }
                    </Row>
                </Card.Body>
            </Card>
            <Card.Footer>
                <StepFooter
                    btnList={[{"Name":"취소", "Step":8}, {"Name":"저장", "Step":8}]}
                    goToStep={props.goToStep}
                    saveHandler={saveData}
                >
                </StepFooter>
            </Card.Footer>
        </>
    );
};

export default DataContent;