import React, {useEffect, useState} from 'react';
import memoize from "memoize-one";
import LightBtn from "../../component/buttons";
import {Form, Row} from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableStyle from "../../config/DataTableStyle";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";
import manageService from "../../service/ManageService";
const DATASET_COLUMNS = memoize((selectedList, setSelected) => [
    {
        name: '번호',
        maxWidth: '50px',
        center: true,
        selector: row => row.tbDatasetUser.dtsId
    },
    {
        name: '데이터셋 명',
        selector: row => row.tbDatasetUser.dts.dtsTitle,
    },
    {
        name: '분류',
        selector: row => row.tbDatasetUser.dts.dtsType,
    },
    {
        name: '소유자',
        selector: row => row.tbDatasetUser.crUsr,
    },{
        name: '권한',
        cell: row => <>
            <Form.Select defaultValue={row.tbDatasetUser.prmsnTp} onChange={(e) => {
                selectedList.forEach(s => {
                    if (s.tbDatasetUser.dtsId == row.tbDatasetUser.dtsId){
                        s.tbDatasetUser.prmsnTp = e.target.value
                        if (s.ownedDivide)
                            s.updatedValue = true;
                        }
                })
            }}>
                <option value={"R"}>R</option>
                <option value={"W"}>W</option>
                <option value={"G"}>G</option>
            </Form.Select>

        </>,
    },
    {
        name: '공유 여부',
        minWidth: '50px',
        cell: row => <>
            <Form.Check
                type={'checkbox'}
                id={`input-params-${row.tbDatasetUser.dtsId}`}
                defaultChecked={row.shareCheck}
                onChange={(e) =>{
                    if(e.target.checked){
                        selectedList.forEach(s => {
                            if (s.tbDatasetUser.dtsId == row.tbDatasetUser.dtsId){
                                s.updatedValue = true;
                                s.shareCheck = true;
                                if (s.ownedDivide) {
                                    s.deleteValue = false
                                }
                            }
                        })
                    }else {
                        selectedList.forEach(s => {
                            if (s.tbDatasetUser.dtsId == row.tbDatasetUser.dtsId){
                                if (s.ownedDivide){
                                    s.updatedValue = true;
                                    s.deleteValue = true
                                }else{
                                    s.updatedValue = false;
                                }
                                s.shareCheck = false;
                            }
                        })
                    }
                }}
            />

        </>,
    }

]);

//dataType = true 프로젝트. false 데이터셋
const DataSetShareTargetComponent = ({
                                         selectedList,
                                         setSelected
                                }) => {








    return (
        <>
            <Row>
                <DataTable
                    columns={
                        DATASET_COLUMNS(selectedList, setSelected)}
                    data={selectedList}
                    customStyles={DataTableStyle.customStyles}
                    highlightOnHover
                    // pointerOnHover
                    pagination
                />
            </Row>
        </>
    );
};

export default DataSetShareTargetComponent;
