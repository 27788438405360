import React from 'react';
import {Link} from "react-router-dom";
import {Nav, NavDropdown} from "react-bootstrap";

const ATMenusComponent = ({menus}) => {

    // const temp = (<><Nav.Link href="#features">Features</Nav.Link>
    //     <Nav.Link href="#pricing">Pricing</Nav.Link>
    //     <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
    //         <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
    //         <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
    //         <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
    //         <NavDropdown.Divider/>
    //         <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
    //     </NavDropdown></>)

    const menuNavs = menus.map(m => {
        if (m['child'] && m['child'].length > 0) {
            let sub = m['child'].map(cm => {
                return (
                    <NavDropdown.Item key={cm['menuId']} as={Link} to={cm['menuUrl']}>
                        {/*<Link to={cm['menuUrl']} className="dropdown-item">*/}
                            {cm['menuTitle']}
                        {/*</Link>*/}
                    </NavDropdown.Item>
                )
            });

            return (
                <NavDropdown className="nav-link" key={m['menuId']} title={m['menuTitle']}>
                    {sub}
                </NavDropdown>
            )
        } else {
            if(m['menuTitle'] == 'Home'){
                return ;
            }else{
                return (
                    <div key={m['menuId']} className={'nav-link'}>
                        <Nav.Link as={Link} to={m['menuUrl']}>
                            {/*<Link to={m['menuUrl']} className="nav-link">*/}
                            {m['menuTitle']}
                            {/*</Link>*/}
                        </Nav.Link>
                    </div>
                )
            }
        }


    })


    return menuNavs;
};

export default ATMenusComponent;
