import React, {useEffect} from 'react';
import {Form} from "react-bootstrap";
import DatasetSelectTool from "../tools/DatasetSelectTool";
import InputSelectTool from "../tools/InputSelectTool";
import HtmlSelectorTool from "../tools/HtmlSelectorTool";
import MultiSelectTool from "../tools/MultiSelectTool";
import DataFilterTool from "../tools/DataFilterTool";
import AddFieldSelectorTool from "../tools/AddFieldSelectorTool";
import RegExpSelectorTool from "../tools/RegExpSelectorTool";

/**
 *= 작업 설정 영역 필드 자동생성 및 값 조정
 * @param prcId
 * @param nodeDef
 * @param node
 * @param setNode
 * @returns {JSX.Element}
 * @constructor
 */
const TaskPropertyComponent = ({
                                   prcId,           //프로세스 ID
                                   nodeDef,         //Node 정보
                                   precdTask,       //선행 노드(태스크)
                                   node,            //현재 노드
                                   setNode}) => {


    useEffect(() => {
        //노드가 정의되어 있고
        if (node !== undefined) {
            //=노드의 taskDef에 localProperty가 정의되어 있지 않으면
            let localProperty = node.taskDef.localProperty;
            //=로컬 프로퍼티가 정의되어 있지 않으면 기본값이 있는 항목은 기본값으로 채움
            if ((!localProperty) || Object.keys(localProperty).length === 0) {
                //= 데이터 기본값 설정
                localProperty = {};
                if (nodeDef && nodeDef.nodeParams) {

                    for (var p in nodeDef.nodeParams) {
                        const param = nodeDef.nodeParams[p];
                        if (param.defaultValue) {
                            localProperty = {
                                ...localProperty,
                                [param.name]: param.defaultValue
                            }
                        }
                    }

                    node['taskDef']['localProperty'] = localProperty;
                }

                setNode(node);
            }
        }


    }, [node])
    return (
        <div>
            {
                nodeDef && nodeDef.nodeParams ? nodeDef.nodeParams.map((paramInfo, i) => {
                    let value = paramInfo.defaultValue ? paramInfo.defaultValue : "";
                    //Local Property에 해당 값이 존재하면 해당 값 사용
                    if (node.taskDef.localProperty && (node.taskDef.localProperty[paramInfo.name] || node.taskDef.localProperty[paramInfo.name] === "")) {
                        value = node.taskDef.localProperty[paramInfo.name];
                    }


                    let inputElement = undefined;
                    // Tool을 사용할 경우
                    if (paramInfo.tool) {
                        if (paramInfo.tool === 'dataset-selector')
                            inputElement = <DatasetSelectTool
                                pName={paramInfo.name}
                                prcId={prcId}
                                value={value ? value : ""}
                                onChange={(v) => {
                                    setNode({
                                        ...node,
                                        taskDef: {
                                            ...node.taskDef,
                                            localProperty: {
                                                ...node.taskDef.localProperty,
                                                [paramInfo.name]: v
                                            }

                                        }
                                    })
                                }
                                }
                            />
                        else if (paramInfo.tool === 'input-selector') {
                            inputElement = <InputSelectTool
                                precdTask={precdTask}
                                node={node}
                                value={value ? value : ""}
                                onChange={(v) => {
                                    setNode({
                                        ...node,
                                        taskDef: {
                                            ...node.taskDef,
                                            localProperty: {
                                                ...node.taskDef.localProperty,
                                                [paramInfo.name]: v
                                            }

                                        }
                                    })
                                }
                                }
                            />
                        }else if(paramInfo.tool ==='html-selector'){
                            inputElement = <HtmlSelectorTool
                                localProperty={node.taskDef.localProperty}
                                targetField={paramInfo.target}
                                precdTask={precdTask}
                                node={node}
                                value={value ? value : ""}
                                onChange={(v) => {
                                    setNode({
                                        ...node,
                                        taskDef: {
                                            ...node.taskDef,
                                            localProperty: {
                                                ...node.taskDef.localProperty,
                                                [paramInfo.name]: v
                                            }

                                        }
                                    })
                                }
                                }
                            />
                        }
                        else if (paramInfo.tool === 'multi-selector') {
                            inputElement = <MultiSelectTool
                                precdTask={precdTask}
                                node={node}
                                value={value ? value : ""}
                                onChange={(v) => {
                                    setNode({
                                        ...node,
                                        taskDef: {
                                            ...node.taskDef,
                                            localProperty: {
                                                ...node.taskDef.localProperty,
                                                [paramInfo.name]: v
                                            }

                                        }
                                    })
                                }
                                }
                            />
                        }
                        else if (paramInfo.tool === 'filter-selector') {
                            inputElement = <DataFilterTool
                                localProperty={node.taskDef.localProperty}
                                targetField={paramInfo.target}
                                precdTask={precdTask}
                                node={node}
                                value={value ? value : ""}
                                onChange={(v) => {
                                    setNode({
                                        ...node,
                                        taskDef: {
                                            ...node.taskDef,
                                            localProperty: {
                                                ...node.taskDef.localProperty,
                                                [paramInfo.name]: v
                                            }

                                        }
                                    })
                                }
                                }
                            />
                        }
                        else if (paramInfo.tool === 'add-field-selector') {
                            inputElement = <AddFieldSelectorTool
                                localProperty={node.taskDef.localProperty}
                                targetField={paramInfo.target}
                                precdTask={precdTask}
                                node={node}
                                value={value ? value : ""}
                                onChange={(v) => {
                                    setNode({
                                        ...node,
                                        taskDef: {
                                            ...node.taskDef,
                                            localProperty: {
                                                ...node.taskDef.localProperty,
                                                [paramInfo.name]: v
                                            }

                                        }
                                    })
                                }
                                }
                            />
                        }
                        else if (paramInfo.tool === 'regexp-selector') {
                            inputElement = <RegExpSelectorTool
                                title={paramInfo.title}
                                precdTask={precdTask}
                                node={node}
                                value={value ? value : ""}
                                onChange={(v) => {
                                    setNode({
                                        ...node,
                                        taskDef: {
                                            ...node.taskDef,
                                            localProperty: {
                                                ...node.taskDef.localProperty,
                                                [paramInfo.name]: v
                                            }

                                        }
                                    })
                                }
                                }
                            />
                        }
                        //= 미등록 Tool
                        else
                            inputElement = <p>[미등록 입력 도구] {paramInfo.tool}-{paramInfo.title}</p>
                    } else if (paramInfo.select) {
                        inputElement = (
                            <Form.Select
                                value={value}
                                size="sm"
                                onChange={(e) => {
                                    setNode({
                                        ...node,
                                        taskDef: {
                                            ...node.taskDef,
                                            localProperty: {
                                                ...node.taskDef.localProperty,
                                                [paramInfo.name]: e.target.value
                                            }

                                        }
                                    })
                                }}
                            >
                                <option>{paramInfo.title} 선택</option>
                                {
                                    paramInfo.select.map(s => {

                                        if(s.includes(':')){
                                            const split = s.split(':');
                                            return <option key={`${split[0]}-paramInfo.name`} value={split[0]}>{split[1]}</option>
                                        }else{
                                            return <option key={`${s}-paramInfo.name`} value={s}>{s}</option>
                                        }


                                    })
                                }
                            </Form.Select>
                        );
                    } else if (paramInfo.type) {

                        inputElement = (
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder={paramInfo.title}
                                value={value ? value : ""}
                                onChange={(e) => {
                                    setNode({
                                        ...node,
                                        taskDef: {
                                            ...node.taskDef,
                                            localProperty: {
                                                ...node.taskDef.localProperty,
                                                [paramInfo.name]: e.target.value
                                            }

                                        }
                                    })
                                }}
                            />)
                    }


                    return (
                        <Form.Group key={`${paramInfo.name}-${i}`} className="mb-3">
                            <Form.Label>{paramInfo.title}</Form.Label>
                            {inputElement}
                        </Form.Group>
                    )
                }) : ""
            }
        </div>
    );
};

export default TaskPropertyComponent;
