import ManageService from "./ManageService";
import axios from "axios";

class DatasetService {

    static loadDatasetInfo(dtsId, setDatasetInfo, setFieldList, successHandler, failHandler) {
        ManageService.getDatasetInfo(dtsId).then((r) => {
            if (r !== undefined && r.status === 200) {
                // dataset info 가져오기
                if (setDatasetInfo !== undefined)
                    setDatasetInfo(r.data.dataset);

                if (setFieldList !== undefined) {
                    // field list 가져오기
                    var fields = r.data.dataset.tbFieldsByDtsId;

                    // 부모아래로 보내기
                    fields.forEach(d => {
                        if (d.fdParent) {
                            fields.forEach(p => {
                                if (p.fdCd === d.fdParent) {
                                    if (p['child'])
                                        p['child'] = [...p['child'], d];
                                    else
                                        p['child'] = [d]
                                }
                            })
                        }
                    });
                    // 자식은 제거
                    fields = fields.filter(a => a.fdParent == null).sort(function (a, b) {
                        return a.fdOrder < b.fdOrder ? -1 : a.fdOrder < b.fdOrder ? 1 : 0;
                    });
                    setFieldList(fields);
                }

                successHandler(r.data, fields);
            } else {
                failHandler("데이터셋 정보 불러오기 실패");
            }
        })
    };

    static datasetDeleteCheck(dtsId){
        return axios.get(`/api/mng/dataset/deleteCheck/${dtsId}`);
    }

    static datasetDelete(dtsId){
        return axios.get(`/api/mng/dataset/delete/${dtsId}`);
    }
}


export default DatasetService;
