import {Dropzone, FileItem} from "@dropzone-ui/react";
import React, {useState} from 'react';

export default function DropZoneUpdate(props) {
    const [files, setFiles] = useState([]);

    const mntrCheck = props.mntrCheck ? props.mntrCheck : "V";

    // const [uploadLog, setUploadLog] = useState([]);
    const updateFiles = (incommingFiles) => {
        setFiles(incommingFiles);
    };
    const onDelete = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };
    return (
        <Dropzone
            // header={false}
            // footer={false}
            onChange={updateFiles}
            value={files}
            onClean
            // accept={"audio/*,video/*,image/*,MIME_type,application/*,text/*"}
            maxFiles={1}
            maxFileSize={104857600}
            label={"이곳을 클릭하거나 파일을 드래그하면 이미지를 불러옵니다."}
            minHeight={"100px"}
            maxHeight={"500px"}
            // maxFiles={1}
            localization={"EN-en"}
            url={!props.uploadDtId ? props.saveCheck ? "/api/file/upload/" + props.dtsId + "?fdCd=" + props.fdCd + "&mntr=" + mntrCheck : null : "/api/file/update/" + props.dtsId + "/" + props.uploadDtId + "?fdCd=" + props.fdCd + "&mntr=" + mntrCheck}
            method={"POST"}
            config={{
                headers: {
                    "content-type": "multipart/form-data"
                },
            }}
            // uploadOnDrop={true}
            onUploadFinish={(r) => {
                console.log(r);
                // setUploadLog(r);
                props.reloadData();
                // props.updateSetData();
                props.setCheck(new Date().getTime());
                setFiles([]);
            }}
        >
            {files.map((file) => (
                <FileItem
                    {...file}
                    key={file.id}
                    onDelete={onDelete}
                    alwaysActive
                    localization={"EN-en"}
                    preview
                    info
                    resultOnTooltip
                />
            ))}
        </Dropzone>
    );
}
