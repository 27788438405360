import React, {useEffect, useState} from 'react';
import {Outlet} from "react-router-dom";
import {Container} from "react-bootstrap";
import "./ManageTopLayout.css"
import ATNavComponent from "../../../component/layout/admintop/component/ATNavComponent";
import LayoutService from "../../../component/service/LayoutService";
import ATPageHeaderComponent from "../../../component/layout/admintop/component/ATPageHeaderComponent";

const ManageTopLayout = (props) => {

    const [menus, setMenus] = useState([
        {
            menuDepth: 0,
            menuDisabled: false,
            menuId: "M_USER",
            menuOrder: 1,
            menuParent: "",
            menuTitle: "유저 관리",
            menuUrl: "/manage"
        },
        {
            menuDepth: 0,
            menuDisabled: false,
            menuId: "M_DATA",
            menuOrder: 2,
            menuParent: "",
            menuTitle: "데이터 관리",
            menuUrl: "/manage/data"
        }
    ]);

    useEffect(() => {
        setMenus(LayoutService.permitSplitter(menus))
    }, []);

    return (
        <div className="page">

            <ATNavComponent menus={menus}/>

            <Container>
                {
                    // Dashboard Page Header Disabled
                    props.breadcrumbs.length===1 && props.breadcrumbs[0].key==="/"?
                        ""
                        :<ATPageHeaderComponent preTitle={"Data Factory"}
                                                title={props.breadcrumbs[props.breadcrumbs.length - 1].breadcrumb}
                                                breadcrumbs={props.breadcrumbs}/>
                }


                <Outlet/>

            </Container>

        </div>
    );
};

export default ManageTopLayout;
