import React, {useState} from 'react';
import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import Swal from "sweetalert2";
import LightBtn from "../../component/buttons";
import DataWorkService from "../../service/DataWorkService";
import {useParams} from "react-router-dom";

const WorkPrtcpModalComponent = ({show, onHide, workPrtcp, setWorkPrtcp,reloadBoard}) => {

    const params = useParams();

    //사용자 검색 실패, 코드
    const [userCode, setUserCode] = useState({state: true, exp:'사용자 id 또는 이메일을 검색해주세요'});
    //사용자 검색 input disable
    const [userInput, setUserInput] = useState(false);

    const checkUser = () => {
        DataWorkService.checkPrtcp(params.wkId, workPrtcp.prtcpUsr).then(r => {
            if (r !== undefined && r.status === 200) {
                setUserInput(!r.data.state);
                if (r.data.state){
                    setUserCode({state: r.data.state, exp:r.data.exp});
                }else{
                    setUserCode({state: false, exp:''});
                }
            } else {
                setUserInput(false);
                Swal.fire("유저 검색 실패");
            }
        });
    };

    const savePrtcp = () => {
        DataWorkService.addPrtcp(workPrtcp).then((r) => {
            if (r !== undefined && r.status === 200) {

                setWorkPrtcp({
                    wkId: params.wkId,
                    prtcpUsr: "",
                    prtcpAcpt: "대기",
                });
                reloadBoard();
                onHide();
            } else {
                Swal.fire("저장 실패")
            }

        })
    }

    return (
        <Modal
            enforceFocus={false}
            show={show} onHide={() => {
                onHide();
                setUserCode({state: true, exp:'사용자 id 또는 이메일을 검색해주세요'});
                setWorkPrtcp({...workPrtcp, prtcpUsr: ""});
        }}>
            <Modal.Header closeButton>
                <Modal.Title>작업자 추가</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-3">
                    <Form.Control isValid={userInput} isInvalid={userCode.state} placeholder={"작업대상"} disabled={userInput} type="text" value={workPrtcp.prtcpUsr}
                                  onChange={(e) => {
                                      if (e.target.value.length == 0){
                                          setUserCode({state: true, exp:'사용자 id 또는 이메일을 검색해주세요'})
                                      }else{
                                          setUserCode({state: false, exp:''})
                                      }
                                      setWorkPrtcp({...workPrtcp, prtcpUsr: e.target.value});
                                  }}/>
                    <Button variant={"outline-secondary"} onClick={checkUser}>확인</Button>
                    <Form.Control.Feedback>사용자 체크 완료</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{userCode.exp}</Form.Control.Feedback>
                </InputGroup>
                {/*<Form.Group className="mb-3">*/}
                {/*    <Form.Label>작업자명</Form.Label>*/}
                {/*    <Form.Control type="text" value={workPrtcp.prtcpUsr}*/}
                {/*                  onChange={(e) => {*/}
                {/*                      setWorkPrtcp({...workPrtcp, prtcpUsr: e.target.value})*/}
                {/*                  }}/>*/}
                {/*</Form.Group>*/}
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={() => {
                    setUserInput(false);
                    onHide();
                }}>
                    취소
                </LightBtn>
                <LightBtn color={"green"} size={"middle"} clickHandler={() => {
                    if(!workPrtcp.prtcpUsr) {
                        Swal.fire("작업자 명을 입력해주세요.");
                    }
                    else {
                        setUserInput(false);
                        savePrtcp();
                    }
                }}>
                    저장
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default WorkPrtcpModalComponent;
