import React from 'react';
import UserManagePage from "./user/UserManagePage";


const ManagePage = (props) => {

    return (
        <div>
            <UserManagePage
                setUserManageInfo={props.setUserManageInfo}
            />
        </div>
    );
};

export default ManagePage;
