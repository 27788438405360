import React from 'react';
import BoxBlack from "../../../component/Boxes";
import {Card} from "react-bootstrap";
import LightBtn from "../../../component/buttons";
import ReactTooltip from "react-tooltip";



const nodeTypeConvert=(type)=>{
    switch(type){
        case "Loader":
            return "가져오기";
        case "Node":
            return "처리하기";
        case "Saver":
            return "저장하기";
        default :
            return type;
    }
}



/**
 * React Flow 에 Drag & Drop으로 노드 추가를 위한 Side Bar
 * @returns {JSX.Element}
 * @constructor
 */
const ProcessGraphNodeDndSidebar = ({
                                        nodeMap,
                                        graphRef
                                    }) => {


    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };


    /**
     *= NodeType : Task종류
     *= NodeName : TaskType
     *= NodeTitle : TaskName(default)
     */

    return (
        <BoxBlack>
            {
                Object.keys(nodeMap).map(type => {
                    return (<Card className={'dnd-sidebar'} key={`dnd-${type}`}>
                        <p className={"mb-1 text-center"}>{nodeTypeConvert(type)}</p>
                        {
                            nodeMap[type].map(n => (
                                <>
                                    <div key={n.nodeName} className="dndnode DF_NODE"
                                         data-tip data-for={"nodeDescription-" + n.nodeTitle.replaceAll(" ", "")}
                                         onDragStart={(event) => onDragStart(event, n.nodeName)} draggable>
                                        <span className={"ms-2"}>{n.nodeTitle}</span>
                                        <LightBtn
                                            className={"ms-auto me-2"}
                                            type="button"
                                            color={"blue"}
                                            data-tip data-for="nodeAdd"
                                            clickHandler={
                                                () => {
                                                    graphRef.current.addNodeByType(n.nodeName, {x: 100, y: 100})
                                                }
                                            }>추가</LightBtn>
                                        <ReactTooltip place="top" type="dark" effect="solid" id="nodeAdd">
                                            <span>그래프에 작업 유형을 추가합니다.</span>
                                        </ReactTooltip>
                                    </div>
                                    <ReactTooltip place="right" type="dark" effect="solid"
                                                  id={"nodeDescription-" + n.nodeTitle.replaceAll(" ", "")}>
                                        <span>{n.nodeDescription}</span>
                                    </ReactTooltip>
                                </>
                            ))
                        }

                    </Card>)

                })
            }
        </BoxBlack>
    );
};

export default ProcessGraphNodeDndSidebar;
