import React from 'react';
import {Card, Form, ListGroup} from "react-bootstrap";
import LightBtn from "../../component/buttons";
import "./DatasetBuildManageTableComponent.css";

const DatasetBuildManageTableComponent = ({datasetList, setDatasetList, utilizationData}) => {
    
    return (
        <div className={'data-list'}>
            <div className={'data-list-header'}>
                <ListGroup horizontal>
                    <ListGroup.Item className={'data-list-item'}>프로젝트</ListGroup.Item>
                    <ListGroup.Item className={'data-list-item'}>데이터셋</ListGroup.Item>
                    <ListGroup.Item className={'data-list-item'}>공유/복제</ListGroup.Item>
                    <ListGroup.Item className={'data-list-item'}>삭제</ListGroup.Item>
                </ListGroup>
            </div>
            <div className={'data-list-body'}>
                {
                    datasetList.map((row, index) => {
                        return <Card.Body key={index}>
                            <ListGroup horizontal>
                                <ListGroup.Item className={'data-list-item'}>{utilizationData !== undefined?<Form.Select defaultValue={row.prjId !== undefined?row.prjId:''} onChange={(e) => {
                                    datasetList[index].prjId = parseInt(e.target.value);
                                    datasetList[index].dtsList = utilizationData[row.prjId].dtsList;
                                    datasetList[index].dtsId = datasetList[index].dtsList[0].dtsId;
                                    datasetList[index].ownedDts = datasetList[index].dtsList[0].ownedDts;
                                    datasetList[index].type = datasetList[index].ownedDts ? 'copy':'share';
                                    console.log(datasetList[index]);
                                    setDatasetList(JSON.parse(JSON.stringify(datasetList)));
                                }}>
                                    {
                                        Object.values(utilizationData).map((d, i) => {
                                            return <option key={d.prjId} value={d.prjId}>{d.prjNm}</option>
                                        })
                                    }
                                </Form.Select>:''}</ListGroup.Item>
                                <ListGroup.Item className={'data-list-item'}>{row.dtsList !== undefined ?<Form.Select defaultValue={row.dtsId !== undefined? row.dtsId:''} onChange={(e) => {
                                    datasetList[index].dtsId = parseInt(e.target.value);
                                    const dts = utilizationData[row.prjId].dtsList.find(item => item.dtsId === row.dtsId);
                                    datasetList[index].ownedDts = dts.ownedDts;
                                    datasetList[index].type = datasetList[index].ownedDts ? 'copy':'share';
                                    console.log(datasetList[index]);
                                    setDatasetList(JSON.parse(JSON.stringify(datasetList)));
                                }
                                }>
                                    {
                                        row.dtsList.map((d, i) => {return <option key={d.dtsId} value={d.dtsId}>{d.dtsNm}</option>})
                                    }
                                </Form.Select>:''}</ListGroup.Item>
                                <ListGroup.Item className={'data-list-item'} disabled={row.ownedDts}>{row.dtsList !== undefined ?<Form.Select defaultValue={row.type !== undefined? row.type:''} onChange={(e) => {
                                    datasetList[index].type = e.target.value;
                                    console.log(datasetList[index])
                                    setDatasetList(JSON.parse(JSON.stringify(datasetList)));
                                }
                                }>
                                    <option key={'share'} value={'share'}>공유</option>
                                    <option key={'copy'} value={'copy'}>복제</option>
                                </Form.Select>:''}</ListGroup.Item>
                                <ListGroup.Item className={'data-list-item'}><LightBtn size={'tiny'} color={'red'} icon={'delete'} clickHandler={(e) => {
                                    const list= datasetList.filter((item, i) => {
                                        return i !== index})
                                    setDatasetList(JSON.parse(JSON.stringify(list)));
                                }}></LightBtn></ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    })
                }
            </div>
        </div>
    );
};

export default DatasetBuildManageTableComponent;