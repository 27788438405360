import React from 'react';
import {Modal} from "react-bootstrap";
import DataViewer from "./DataViewer";

const DataViewerModal = ({open, openHandler, dtsId}) => {
    return (
        <Modal
            size={'xl'}
            enforceFocus={false}
            show={open} onHide={
            () => {
                openHandler(false)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>데이터 뷰어</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {open ?
                    <DataViewer perPage={20} dtsId={dtsId}/> : ""
                }
            </Modal.Body>
        </Modal>
    );
};

export default DataViewerModal;
