import React from 'react';
import {Card, Col, Row, Stack, Form} from "react-bootstrap";
import StepFooter from "./component/StepFooter";
import ManageService from "../page/service/ManageService";
import Swal from "sweetalert2";
import "./popup.css"
import PopupATPageHeader from "./component/PopupATPageHeader";

const DatasetContent = (props) => {

    const saveDataset = () => {

        let dataset = {
            ...props.datasetObj,
            prjId: props.prjId
        };

        ManageService.saveDataset(dataset).then((r) => {
            if (r !== undefined && r.status === 200) {
                props.reloadDataset(props.prjId);
                props.setDtsId(r.data)
            } else {
                Swal.fire("저장 실패")
            }

        })


    }

    return (
        <>
            <div className={"popup-wrapper"}>
                <div className={"popup-header"}>
                    <div className={"ph-ttl"}>
                        {/*<PopupATPageHeader*/}
                        {/*    pageList={[*/}
                        {/*        {*/}
                        {/*            "header": "프로젝트",*/}
                        {/*            "step":4*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*            "header": "데이터셋",*/}
                        {/*            "step":5*/}
                        {/*        }*/}
                        {/*    ]}*/}
                        {/*    goToStep={props.goToStep}*/}
                        {/*/>*/}
                        <h5>데이터셋 정보</h5>
                        <p>{
                            props.dtsId === null ? "새로 생성할 데이터셋 정보를 입력합니다." : "선택한 데이터셋 정보를 보여줍니다."
                        }</p>
                    </div>
                </div>
                <div className={"popup-body"}>
                    <div className={'popup-form'}>
                        <div className={"popup-form-body"}>
                            <div className={"popup-form-row"}>
                                <div className={"popup-form-th"}>데이터셋 형식</div>
                                <div className={"popup-form-td"}>
                                    <div className={"popup-form-select"}>
                                        {
                                            props.datasetObj.dtsType === 'FILE' && props.dtsId !== null ?
                                                <label>
                                                    <div className={"pfs-ttl"}>
                                                        <input type={"radio"} name={"pfs-file"} checked/>
                                                        파일 데이터셋
                                                    </div>
                                                    <p>
                                                        파일 기반 데이터셋을 생성합니다. 자유롭게 파일을 업로드 할 수 있습니다.
                                                    </p>
                                                </label> : props.datasetObj.dtsType === "FIELD" && props.dtsId !== null ?
                                                    <label>
                                                        <div className={"pfs-ttl"}>
                                                            <input type={"radio"} name={"pfs-field"} checked/>
                                                            필드 데이터셋
                                                        </div>
                                                        <p>
                                                            필드 정의 기반의 데이터셋을 생성합니다. 데이터는 정의된 필드에 맞춰 입력되어야 합니다.
                                                        </p>
                                                    </label> :
                                                    <Row>
                                                        <Col onClick={() => {
                                                            props.setDatasetObj({
                                                                ...props.datasetObj,
                                                                dtsType: 'FILE'
                                                            })
                                                        }}>
                                                            <label>
                                                                <div className={"pfs-ttl"}>
                                                                    <input type={"radio"} name={"pfs-select"}/>
                                                                    파일 데이터셋
                                                                </div>
                                                                <p>
                                                                    파일 기반 데이터셋을 생성합니다. 자유롭게 파일을 업로드 할 수 있습니다.
                                                                </p>
                                                            </label>
                                                        </Col>
                                                        <Col onClick={() => {
                                                            props.setDatasetObj({
                                                                ...props.datasetObj,
                                                                dtsType: 'FIELD'
                                                            })
                                                        }}>
                                                            <label>
                                                                <div className={"pfs-ttl"}>
                                                                    <input type={"radio"} name={"pfs-select"}/>
                                                                    필드 데이터셋
                                                                </div>
                                                                <p>
                                                                    필드 정의 기반의 데이터셋을 생성합니다. 데이터는 정의된 필드에 맞춰 입력되어야 합니다.
                                                                </p>
                                                            </label>
                                                        </Col>
                                                    </Row>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={"popup-form-row"}>
                                <div className={"popup-form-th"}>데이터셋 명</div>
                                <div className={"popup-form-td"}>
                                    <input type="text" className={"popup-input"} value={props.datasetObj.dtsTitle}
                                           style={{"maxWidth": "100%"}}
                                           onChange={(e) => {
                                               props.setDatasetObj({...props.datasetObj, dtsTitle: e.target.value})
                                           }}/>
                                </div>
                            </div>
                            <div className={"popup-form-row"}>
                                <div className={"popup-form-th"}>데이터셋 설명</div>
                                <div className={"popup-form-td"}>
                                    <textarea rows={3} className={"popup-input"} value={props.datasetObj.dtsDscr}
                                              style={{"maxWidth": "100%"}}
                                              onChange={(e) => {
                                                  props.setDatasetObj({
                                                      ...props.datasetObj,
                                                      dtsDscr: e.target.value
                                                  })
                                              }}/>
                                </div>
                            </div>
                        </div>
                        <div className={"popup-footer"}>
                            <StepFooter
                                btnList={[
                                    {"Name": "이전", "Step": 4},
                                    {
                                    "Name": "다음",
                                    "Step": props.datasetObj.dtsType === "FILE" ? 10 : 6
                                }]}
                                goToStep={props.goToStep}
                                saveHandler={saveDataset}
                                id={props.dtsId}
                                message={"데이터셋명을 적어주세요."}
                                check={props.datasetObj.dtsTitle}
                            >
                            </StepFooter>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DatasetContent;
