import axios from "axios";


class DataWorkService {

    static getWorkBoard() {
        return axios.get('/api/work/list')
    }

    static getWork(wkId) {
        return axios.get(`/api/work/${wkId}`)
    }


    static saveWork(tbWork) {
        return axios.post('/api/work/save', tbWork)
    }

    static deleteWork(wkId) {
        return axios.get('/api/work/delete', {
            params: {
                wkId: wkId
            }
        })
    }

    static getWorkPrtcpUser(wkId) {
        return axios.get('/api/work/prtcp/list/' + wkId);
    }

    static checkPrtcp(wkId, user) {
        return axios.get('/api/work/prtcp/check', {
            params: {
                wkId: wkId,
                user: user
            }
        })
    }

    static acceptWorkPrtcpUser(wkId, user, role) {
        return axios.get('/api/work/prtcp/accept', {
            params: {
                wkId: wkId,
                user: user,
                role: role
            }
        });
    }

    static deleteWorkPrtcpUser(wkId, user) {
        return axios.get('/api/work/prtcp/delete', {
            params: {
                wkId: wkId,
                user: user
            }
        });
    }

    static acceptWorkHistory(wkId, hsId) {
        return axios.get('/api/work/rslt/accept', {
            params: {
                wkId: wkId,
                hsId: hsId
            }
        });
    }

    static rejectWorkHistory(wkId, hsId, expl) {
        return axios.get('/api/work/rslt/reject', {
            params: {
                wkId: wkId,
                hsId: hsId,
                expl: expl
            }
        });
    }

    static getWorkHistory(wkId) {
        return axios.get('/api/work/rslt/all/' + wkId);
    }

    static getWorkUserHistory(wkId, user) {
        return axios.get('/api/work/rslt/list/' + wkId, {
            params: {
                user: user
            }
        });
    }

    static getWorkHistoryCount() {
        return axios.get('/api/work/history/count');
    }

    static getWorkUserMonitoring(wkId, user) {
        return axios.get('/api/work/mntr/list/' + wkId, {
            params: {
                user: user
            }
        });
    }

    static getNextMntrData(dtId, wkId) {
        return axios.get("/api/work/next/mntr", {
            params: {
                dtId: dtId,
                wkId: wkId
            }
        })
    }

    static getPrevMntrData(dtId, wkId, mntrType) {
        return axios.get("/api/work/prev/mntr", {
            params: {
                dtId: dtId,
                wkId: wkId,
                mntrType: mntrType
            }
        })
    }

    static getMntrValueList(wkId) {
        return axios.get(`/api/work/mntr/list/${wkId}`)
    }

    static getOneRowTempData(dtsId, dtId, wkId) {
        return axios.get("/api/work/temp/row/" + dtsId, {
            params: {
                dtId: dtId,
                wkId: wkId
            }
        })
    }

    static saveHistory(history) {
        return axios.post('/api/work/save/mntr/history', history)
    }

    static saveTempValue(tempValue) {
        return axios.post('/api/work/save/mntr', tempValue)
    }

    static loadMntrData(wkId, conditionList) {

        var conditionMap = {};
        for(var key in conditionList) {
            var condition = conditionList[key];
            var check = condition["check"];
            if(check !== "") {
                conditionMap[key] = condition["check"];
            }
        }
        var workRowVO = {};
        workRowVO["wkId"] = wkId;
        workRowVO["condition"] = conditionMap;

        return axios.post(`/api/work/mntr/row`, workRowVO )
    }

    static addPrtcp(prtcp) {
        return axios.post(`/api/work/prtcp/add`, prtcp)
    }

    static workReflect(wkId) {
        return axios.get(`/api/work/hs/reflect`, {
            params: {
                wkId: wkId
            }
        })
    }
}


export default DataWorkService;
