import React, {useEffect, useState} from "react";
import "./DounutCircle.css"
import ReactTooltip from "react-tooltip";
import TextUtilService from "../../component/service/TextUtilService";

const DonutCircle = (props) => {

    // 도넛 지름 고정값
    const circularGraphDiameter = (props.type==='main' ? 1194 : 942);
    const circleProgress = (props.type==='main' ? 190 : 150);

    const [circularGraphRange, setCircularGraphRange] = useState(circularGraphDiameter);

    //NOTE 도넛그래프 채워지는 양 계산
    let percent = props.dataUsage / props.dataCapacity;
    let dpPercent = (percent * 100).toFixed(1) + "%";
    dpPercent = !props.dataCapacity?"0%":dpPercent;
    useEffect(()=>{
        const circularGraphRange =
            circularGraphDiameter -
            circularGraphDiameter * percent;

        setCircularGraphRange(circularGraphRange)
    },[props.dataUsage,props.dataCapacity]);

    //NOTE 데이터단위 변경
    let dataUsageUnit;
    let dataCapacityUnit;
    if(props.type==='main'){
        dataUsageUnit=TextUtilService.bytesToSize(props.dataUsage);
        dataCapacityUnit=TextUtilService.bytesToSize(props.dataCapacity);
    }



    return (
        <>
            <div className={"dashboard-circulargraph " + props.type}>
                {
                    props.type==='main'||!props.type?<div className="dashboard-circulargraph-info">
                        <div className="ttl">
                            <h4>데이터 사용량</h4>
                            <span>Data usage</span>
                        </div>
                        <div className="count">
                            <div className="count-usage">{dataUsageUnit}</div>
                            <div className="count-capacity"> / {dataCapacityUnit}</div>
                        </div>
                    </div>:
                        <div className="dashboard-circulargraph-info" data-tip data-for="progress">
                        <div className="percentage">{dpPercent}</div>
                        <div className="ttl"><span>{props.dataCapacity?"잔여 작업량":"작업량"}</span></div>
                        <div className="count">
                            <span className="count-usage">{props.dataUsage}</span>
                            <span className="count-capacity">{props.dataCapacity?" / " + props.dataCapacity:""}</span>
                        </div>
                            <ReactTooltip type="dark" effect="solid" id="progress">
                                <span>데이터 모니터링 작업 진행률 및 잔여 작업량을 표시합니다.</span>
                            </ReactTooltip>
                    </div>
                }

                <svg>
                    <circle
                        cx="50%"
                        cy="50%"
                        r={circleProgress}
                        style={
                            { strokeDasharray: circularGraphDiameter ,
                                strokeDashoffset: circularGraphRange
                            }
                        }
                    ></circle>
                </svg>
            </div>
        </>
    );
};
export default DonutCircle;
