import React, {useState} from 'react';
import {Form, Row, Col} from "react-bootstrap";
import ProcessGraphNodeDndSidebar from "../graph/ProcessGraphNodeDndSidebar";
import LightBtn from "../../../component/buttons";
import Swal from "sweetalert2";
import DataWorkService from "../../../service/DataWorkService";

const RegExpSelectorTool = ({pName,precdTask, value, onChange, title}) => {

    if(!precdTask){
        return <p>선행노드가 없습니다.</p>
    }else if(!precdTask.lastTest){
        return <p>선행 노드를 먼저 테스트해주세요.</p>
    }

    const searchRegExp =
        {
            "01(?:0|1|[6-9])[.-]?([0-9]{3}|[0-9]{4})[.-]?([0-9]{4})": '전화번호',
            "([a-z0-9_\.-]+)@(([\da-z\.-]+)\.([a-z\.]{2,6}))": '이메일주소',
            "(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}": 'URL',
            "[ㄱ-ㅣ가-힣]": '한글',
            "[ㄱ-ㅣ가-힣a-zA-Z]": '한글 및 영문자',
            "[ㄱ-ㅣ가-힣0-9]": '한글 및 숫자',
            "[a-zA-Z]": '영문자',
            "[0-9]": '숫자',
            "[a-zA-Z0-9]": '영문자 및 숫자',
        };

    const regExpSwal = () => {
        Swal.fire({
            title: '찾을 정규식',
            input: 'select',
            inputOptions: searchRegExp,
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: '확인',
            showLoaderOnConfirm: true,
            preConfirm: (key) => {
                onChange(key);
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }

    const openExp = () => {
        var url = `https://ko.wikipedia.org/wiki/%EC%A0%95%EA%B7%9C_%ED%91%9C%ED%98%84%EC%8B%9D`;
        window.open(url, "title", "width=1200, height=900, top=10, left=10");
    };

    return (
        <>
            <Row>
                <Col md={9}>
                    <Form.Control
                        size="sm"
                        type="text"
                        placeholder={title}
                        value={value ? value : ""}
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                    />
                </Col>
                <Col>
                    <LightBtn
                        className={"ms-auto me-2"}
                        clickHandler={()=>{
                            regExpSwal()
                        }
                        }
                        color={"outline-warning"} size={"sm"}>예시</LightBtn>
                    <LightBtn
                        clickHandler={()=>{
                            openExp()
                        }
                        }
                        color={"outline-info"} size={"sm"}>설명</LightBtn>
                </Col>
            </Row>
        </>
    );
};

export default RegExpSelectorTool;