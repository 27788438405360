import React from 'react';
import {Card, Row, Stack} from "react-bootstrap";
import {Title2} from "../../../page/component/titles";
import DataTable from "react-data-table-component";
import DataTableStyle from "../../../page/config/DataTableStyle";
import DataViewer from "../../../page/manage/DataViewer";

const CurrentFieldData = ({dtsId}) => {

    return (
        <>
            {
                dtsId ?
                    <DataViewer
                        title={"선택한 데이터셋에 최근 등록된 필드 데이터 (10개)"}
                        perPage={10}
                        dtsId={dtsId}
                    /> :
                    <Card className={'data-project-list'}>
                        <Card.Header>
                            <Stack direction="horizontal">
                                <Title2>선택한 데이터셋에 최근 등록된 필드 데이터 (10개)</Title2>
                            </Stack>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <DataTable
                                    columns={[]}
                                    data={[]}
                                    customStyles={DataTableStyle.customStyles}
                                    highlightOnHover
                                    // pointerOnHover
                                    pagination
                                />
                            </Row>
                        </Card.Body>
                    </Card>
            }
        </>
    );
};

export default CurrentFieldData;
