import React from 'react';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import {Title3} from "../../component/titles";
import {Link} from "react-router-dom";

const DashboardSlider = (props) => {

    const settings = {
        speed: 300,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        draggable: false,
    };

    //TODO 각각 컴포넌트에 맞는 데이터 적용

    return (
        <div className={"dashboard-slider"}>
            <Title3>{props.title}</Title3>
            <Slider {...settings}>
                {
                    props.list? props.list.map(l=>{
                        return <Link key={l.title} to={l.linkTo?l.linkTo:"/"}>
                            <div className={"list"}>
                                <div className={"progress"} style={{width: l.progress}}/>
                                <h5>{l.title}</h5>
                                <span>{l.progress}</span>
                            </div>
                        </Link>
                    }):""
                }

            </Slider>
        </div>
    );

}

export default DashboardSlider
