import React, {useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import ProcessService from "../../service/ProcessService";
import LightBtn from "../../component/buttons";
import Swal from "sweetalert2";

const NewProcessModal = (props) => {

    const [processObj, setProcessObj] = useState({
        prcNm: '',
        prcType: '전처리'
    })

    const prcTypeList = [
        "전처리", "자연어처리", "수집"
    ];

    const saveProcess = () => {

        ProcessService.saveProcess(processObj).then((r) => {
            if (r !== undefined && r.status === 200) {
                setProcessObj({
                    prcNm: '',
                    prcType: '전처리'
                });
                props.reloadProcess();
                props.setOpen(false)
            } else {

            }

        })


    }


    return (
        <Modal
            enforceFocus={false}
            show={props.isOpen} onHide={() => {
            props.setOpen(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>프로세스 생성</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>프로세스 명</Form.Label>
                    <Form.Control type="text" value={processObj.prcNm}
                                  onChange={(e) => {
                                      setProcessObj({...processObj, prcNm: e.target.value})
                                  }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>프로세스 분류</Form.Label>
                    <Form.Select value={processObj.prcType || ""}
                                 onChange={(e) => {
                                     setProcessObj({...processObj, prcType: e.target.value})
                                 }}>
                        {
                            prcTypeList.map((e, i) => {
                                return (
                                    <option key={i} value={e}>{e}</option>
                                )
                            })
                        }
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn size={'middle'} color={'red'} clickHandler={() => {
                    props.setOpen(false)
                }}>
                    취소
                </LightBtn>
                <LightBtn size={'middle'} color={'green'} clickHandler={() => {
                    if(!processObj.prcNm) {
                        Swal.fire("프로세스 명을 입력해주세요.")
                    }
                    else {
                        saveProcess();
                    }
                }}>
                    추가
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default NewProcessModal;
