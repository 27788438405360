import React from 'react';
import {Modal} from "react-bootstrap";
import LightBtn from "../../../component/buttons";
import ExcelResultTable from "./ExcelResultTable";

const ExcelDataResultModal = (props) => {

    return (
        <Modal
            enforceFocus={false}
            show={props.excelDataResult} onHide={() => props.setExcelDataResult(false)}>
            <Modal.Header closeButton>
                <Modal.Title>엑셀업로드 결과</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"mb-3 excelResult"}>
                    <h5>{"수행 소요 시간"}</h5>
                    <span>{props.uploadTime + " ms"}</span>
                </div>
                <div className={"mb-3 excelResult"}>
                    <h5>{"업로드 데이터 크기"}</h5>
                    <span>{((props.excelDataSize / 1024).toFixed(2) + "").replace(/\B(?=(\d{3})+(?!\d))/g, ',') + " KB"}</span>
                </div>
                <div className={"excelResult"}>
                    <h5>{"업로드 데이터 수"}</h5>
                    <span>{(props.excelDataCount + "").replace(/\B(?=(\d{3})+(?!\d))/g, ',') + " 건"}</span>
                </div>
                <div className={"mt-3"}>
                    <h5>{"유효 항목 수"}</h5>
                    <ExcelResultTable
                        fieldList={props.fieldList}
                        excelValidation={props.excelValidation}
                        excelDataCount={props.excelDataCount}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"green"} size={"middle"} clickHandler={() => props.setExcelDataResult(false)}>
                    확인
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default ExcelDataResultModal;
