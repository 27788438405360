import React, {useEffect, useState} from 'react';
import DataTableStyle from "../../../config/DataTableStyle";
import DataTable from "react-data-table-component";
import {Card, Overlay} from "react-bootstrap";
import TaskOutputDataModal from "./TaskOutputDataModal";

const TaskOutputDataTable = ({lastTest}) => {

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [isModalShow, setIsModalShow] = useState(false);
    const [dataRow, setDataRow] = useState("");

    const makeColumnByOutputParam= ()=>{
        const outputParams = lastTest.node.outputParams;
        const columns = outputParams.filter(p=>!p.includes('#')).map(p=>{

            return {
                name: p,
                selector: row => row[p],
                cell: row => {
                    return <div onClick={() => openPopup(row, p)}>
                        {
                            (row[p] !== undefined && row[p]!==null)? row[p].length > (100 / outputParams.length) ?
                                row[p].slice(0, (100 / outputParams.length)) + "···" : <div>{row[p]}</div> : null
                        }
                        {/*{*/}
                        {/*    row[p]!==undefined?row[p].length > 100?<div onClick={() => {*/}
                        {/*        setIsModalShow(true);*/}
                        {/*        setDataRow(row[p]);*/}
                        {/*    }}>{row[p].slice(0, 20) + "···"}</div>:<div>{row[p]}</div>:null*/}
                        {/*}*/}
                    </div>
                }
            }
        })

        setColumns(columns);
    }

    useEffect(() => {
        if (lastTest && Object.keys(lastTest).length !== 0 && lastTest.node.outputParams) {
            makeColumnByOutputParam();
            if(lastTest.outputData!=null)
                setData(lastTest.outputData);
            else setData([])
        }else{
            setColumns([])
            setData([])
        }
    }, [lastTest]);

    const openPopup = (row, p) => {
        var token = sessionStorage.getItem("token");
        var url = `${window.location.origin}/popup/${token}?targetUrl=/html`;
        var child = window.open(url, "title", "width=1200, height=900, top=10, left=10");
        var enterRow = [];
        row[p].split("\n").map((r) => {
            enterRow.push(r);
            enterRow.push("br")
        });
        child.value = enterRow;
    }

    return (
        <Card.Body>
            <DataTable
                columns={columns}
                data={data}
                customStyles={DataTableStyle.smCompactGrid}
                pagination
                // onRowClicked={openPopup}
            />
            <TaskOutputDataModal
                show={isModalShow}
                setShow={setIsModalShow}
                row={dataRow}
            />
        </Card.Body>
    );
};

export default TaskOutputDataTable;
