import React, {useEffect, useState} from 'react';
import {Form, InputGroup, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import ManageService from "../../service/ManageService";
import Swal from "sweetalert2";
import LightBtn from "../../component/buttons";

const DatasetFieldModal = (props) => {

    const [fieldObj, setFieldObj] = useState({
        fdCd: '',
        fdType: 'TEXT',
        fdNm: '',
        fdFormat: null,
        fdParent: null
    })

    const fdTypeList = [
        "TEXT", "LARGE_TEXT",
        "INTEGER",
        "DATE", //"DATE_RANGE",
        "DATETIME", //"DATETIME_RANGE",
        "FILE"
    ];

    /**
     * 데이터 유형
     */
    const fdFormat = {
        "TEXT": "",
        "LARGE_TEXT": "",
        "INTEGER": ["NUMBER", "RANGE"],
        "DATE": ["yyyy-MM-dd"],
        "DATETIME": ["yyyy-MM-dd HH:mm"],
        "FILE": ["IMAGE", "VIDEO", "DOCUMENT", "ETC"]
    }


    useEffect(() => {
        if(props.selectedRow.fdCd !== undefined) {
            setFieldObj(props.selectedRow);
        }
    }, [props.selectedRow]);


    const saveField = (successfulHandler) => {
        if(!fieldObj.fdCd) {
            Swal.fire("필드 코드를 입력해주세요.");
            return
        }
        else if(!fieldObj.fdNm) {
            Swal.fire("필드 명을 입력해주세요.");
            return
        }
        let order;
        if (fieldObj.fdParent != null) {
            for (var i in props.fieldList) {
                var f = props.fieldList[i];
                if (f.fdCd === fieldObj.fdParent) {
                    if (f.child == null) {
                        order = 1;
                    } else {
                        order = f.child.length + 1;
                    }
                    break;
                }
            }
        } else {
            order = props.fieldList.length + 1
        }

        let field = {};
        if (!props.selectedRow.fdNm) {
            field = {
                ...fieldObj,
                dtsId: props.dtsId,
                fdOrder: order
            };
        } else {
            field = {...fieldObj};
        }

        ManageService.saveField(field).then((r) => {
            if (r !== undefined && r.status === 200) {
                setFieldObj({
                    fdCd: '',
                    fdType: 'TEXT',
                    fdNm: '',
                    fdFormat: null,
                    fdParent: null
                });
                props.setSelectedRow({
                    fdCd: '',
                    fdType: 'TEXT',
                    fdNm: '',
                    fdFormat: null,
                    fdParent: null
                });

                successfulHandler();

            }
            else if(r.status === 204) {
                Swal.fire("이미 존재하는 필드 코드 입니다.")
            }
            else {
                Swal.fire("저장 실패")
            }

        })
    };

    return (
        <Modal
            className={props.className}
            enforceFocus={false}
            show={props.isOpen} onHide={() => {
            props.setOpen(false);
            props.setSelectedRow({
                fdCd: '',
                fdType: 'TEXT',
                fdNm: '',
                fdFormat: null,
                fdParent: null
            });
        }}>
            <Modal.Header closeButton>
                <Modal.Title>필드 정보</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>필드 코드</Form.Label>

                    <Form.Control required
                                  isInvalid={fieldObj.fdCd && fieldObj.fdCd.match(/[^a-zA-Z0-9_]/)}
                                  type="text" style={{"textTransform": "uppercase"}} value={fieldObj.fdCd || ""} readOnly={props.fieldModify===null?false:props.fieldModify} maxLength={"20"}
                                  onChange={(e) => {
                                      // 영어 대문자 및 '_' 특수문자만 입력
                                      var i_fdCd = String(e.target.value).toUpperCase();
                                      i_fdCd = i_fdCd.replace(/[^a-zA-Z0-9_]/, "");
                                      setFieldObj({...fieldObj, fdCd: i_fdCd});
                                  }}
                                  overlay={
                                      <Tooltip id={`tooltip-fdCd`}>
                                          대문자 영어 및 '_' 특수문자, 숫자만 입력 가능합니다.
                                      </Tooltip>
                                  }
                                  placeholder={"대문자 영어 및 '_' 특수문자, 숫자만 입력 가능합니다."} />
                    {
                            <Form.Control.Feedback type="invalid">
                                대문자 영어 및 '_' 특수문자, 숫자만 입력 가능합니다.
                            </Form.Control.Feedback>//:""
                    }

                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>필드 명</Form.Label>
                    <Form.Control type="text" value={fieldObj.fdNm || ""} maxLength={"15"}
                                  onChange={(e) => {
                                      setFieldObj({...fieldObj, fdNm: e.target.value})
                                  }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>순서</Form.Label>
                    <Form.Control type="text" value={fieldObj.fdOrder || ""} maxLength={"3"}
                                  onChange={(e) => {
                                      // 숫자만 입력
                                      var i_fdOrder = e.target.value;
                                      i_fdOrder = i_fdOrder.replace(/[^0-9]/, "");
                                      setFieldObj({...fieldObj, fdOrder: i_fdOrder})
                                  }} placeholder={"숫자만 입력 가능합니다."}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>데이터 분류</Form.Label>
                    <Form.Select value={fieldObj.fdType || ""}
                                 onChange={(e) => {
                                     setFieldObj({...fieldObj, fdType: e.target.value})
                                 }}>
                        {
                            fdTypeList.map((e, i) => {
                                return (
                                    <option key={i} value={e}>{e}</option>
                                )
                            })
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>데이터 형식</Form.Label>
                    {
                        Array.isArray(fdFormat[fieldObj.fdType]) ?


                            <Form.Select value={fieldObj.fdFormat || ""}
                                         onChange={(e) => {
                                             setFieldObj({...fieldObj, fdFormat: e.target.value})
                                         }}>
                                {
                                    fdFormat[fieldObj.fdType] ? fdFormat[fieldObj.fdType].map((e, i) => {
                                        return (
                                            <option key={i} value={e}>{e}</option>
                                        )
                                    }) : ""
                                }
                            </Form.Select>
                            :
                            <Form.Control type="text" value={""} readOnly={true}
                                          onChange={(e) => {
                                              setFieldObj({...fieldObj, fdFormat: e.target.value})
                                          }}/>
                    }

                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>상위 필드</Form.Label>
                    <Form.Select value={fieldObj.fdParent || ""}
                                 onChange={(e) => {
                                     if(!e.target.value)
                                     {
                                         setFieldObj({...fieldObj, fdParent: null});
                                     }
                                     else {
                                        setFieldObj({...fieldObj, fdParent: e.target.value});
                                     }
                                 }}
                    >
                        <option value=""></option>
                        {
                            props.fieldList?props.fieldList.map((e, i) => {
                                return (
                                    e.fdCd !== fieldObj.fdCd?
                                    <option key={i} value={e.fdCd}>{e.fdCd + ":" + e.fdNm}</option>:null
                                )
                            }):""
                        }
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={() => {
                    props.setOpen(false);
                    props.setSelectedRow({
                        fdCd: '',
                        fdType: 'TEXT',
                        fdNm: '',
                        fdFormat: null,
                        fdParent: null
                    });
                }}>
                    취소
                </LightBtn>
                <LightBtn color={"green"} size={"middle"} clickHandler={() => {
                        saveField(()=>{
                            props.reloadField();
                            props.setOpen(false);
                        });
                }}>
                    저장
                </LightBtn>
                <LightBtn className={''} style={{width:'130px'}} color={"green"} size={"middle"} clickHandler={() => {
                    saveField(()=>{
                        props.reloadField();
                    });
                }}>
                    저장 후 계속
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default DatasetFieldModal;
