import React, {useEffect, useState} from 'react';
import {Card, Modal} from "react-bootstrap";
import LightBtn from "../../../component/buttons";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useNavigate, useParams} from "react-router-dom";
import DataService from "../../../service/DataService";
import ManageService from "../../../service/ManageService";
import FileService from "../../../service/FileService";
import DropZoneExcelUpload from "../../../component/DropZoneExcelUpload";
import DataTableStyle from "../../../config/DataTableStyle";
import DataTable from "react-data-table-component";
import memoize from "memoize-one";
import Swal from "sweetalert2";
import orderArr from "../../../../images/btn_positionSwich.png";

const ERROR_DATA_COLUMNS = memoize(() => [
    {
        name: '행 번호',
        center: true,
        selector: row => row.rowNo
    },
    {
        name: '열 번호',
        selector: row => row.cellNo,
    },
    {
        name: '필드 타입',
        selector: row => row.fieldType,
    },
    {
        name: '입력 데이터',
        selector: row => row.dataValue,
    }
]);

const ErrorExcelModal = (props) => {

    return (
        <DataTable
            columns={ERROR_DATA_COLUMNS()}
            data={props.excelDataError}
            customStyles={DataTableStyle.smCompactGrid}
            highlightOnHover
            // pointerOnHover
            pagination
        />
    );
};

export default ErrorExcelModal;
