import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Card, Row, Stack} from "react-bootstrap";
import DataTableStyle from "../config/DataTableStyle";
import DataTable from "react-data-table-component";
import ProcessService from "../service/ProcessService";
import NewProcessModal from "./component/NewProcessModal";
import Swal from "sweetalert2";
import LightBtn from "../component/buttons";
import {Title2} from "../component/titles";
import ReactTooltip from "react-tooltip";

const ProcessBoard = (props) => {
    const [isOpenNewModal, setIsOpenNewModal] = useState(false);

    const [processList, setProcessList] = useState([]);

    const reloadProcessList = () => {
        ProcessService.getProcessBoard().then(r => {
            if (r !== undefined && r.status === 200) {
                var data = r.data;
                data.sort((p1, p2) => p2.crDt - p1.crDt);
                setProcessList(data);
            }
        })
    };

    const deleteProcess = (process) => {
        ProcessService.deleteProcess(process).then(r => {
            if (r !== undefined && r.status === 200) {
                reloadProcessList();
            }
        });
    };

    useEffect(() => {
        reloadProcessList();
    }, []);

    const columns = [
        {
            name: '번호',
            maxWidth: '50px',
            center: true,
            selector: row => row.prcId
        },
        {
            name: '프로세스 명',
            selector: row => row.tbProcessByPrcId.prcNm,
        },
        {
            name: '프로세스 분류',
            selector: row => row.tbProcessByPrcId.prcType,
        },
        {
            name: '데이터셋',
            selector: row => {
                const proc = row.tbProcessByPrcId;
                const dtsTitleList = proc.tbProcessDatasetsByPrcId.map(d => d.tbDatasetByDtsId.dtsTitle)
                return dtsTitleList.join(",")
            },
        },
        {
            name: '상태',
            selector: row => row.tbProcessByPrcId.prcState,
        },
        {
            name: '진척도',
            selector: row => "",
        },
        {
            name: '관리',
            button: true,
            minWidth: '150px',
            cell: row => <>
                <LightBtn size={'tiny'} color={'green'} icon={'link'}
                          data-tip data-for="processManage"
                          clickHandler={() => {
                    nav(`/process/${row.prcId}`)
                }}/>
                <ReactTooltip place="left" type="dark" effect="solid" id="processManage">
                    <span>데이터 공정 프로세스 관리 페이지로 이동합니다.</span>
                </ReactTooltip>
                <LightBtn size={'tiny'} color={'red'} icon={'delete'}
                          data-tip data-for="processDelete"
                          clickHandler={() => {
                    //TODO 삭제 기능 구현
                    Swal.fire({
                        title : '',
                        text : "프로세스를 삭제하시겠습니까?",
                        type : "warning",
                        showCancelButton : true,
                        confirmButtonClass : "btn-danger",
                        confirmButtonText : "예",
                        cancelButtonText : "아니오",
                        closeOnConfirm : false,
                        closeOnCancel : true
                    }).then(result => {
                        if(result.isConfirmed) {
                            deleteProcess(row);
                            Swal.fire("프로세스가 삭제되었습니다.")
                        }
                    })
                    // Swal.fire("Not Implemented. ")
                }}/>
                <ReactTooltip place="top" type="dark" effect="solid" id="processDelete">
                    <span>프로세스를 삭제합니다.</span>
                </ReactTooltip>
            </>,
        }
    ];


    const nav = useNavigate();
    // const [pp] = useOutletContext();
    return (
        <>
            <Card className={'data-project-list'}>
                <Card.Header>
                    <Stack direction="horizontal" gap={3}>
                        <Title2>프로세스 목록</Title2>
                        <LightBtn
                            color="green"
                            size="small"
                            data-tip data-for="processAdd"
                            clickHandler={() => {
                            setIsOpenNewModal(true)
                        }}>+ 프로세스 추가</LightBtn>
                        <ReactTooltip place="top" type="dark" effect="solid" id="processAdd">
                            <span>프로세스를 추가합니다.</span>
                        </ReactTooltip>
                    </Stack>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <DataTable
                            columns={columns}
                            data={processList}
                            customStyles={DataTableStyle.customStyles}
                            highlightOnHover
                            // pointerOnHover
                            pagination
                        />
                    </Row>
                </Card.Body>
            </Card>
            <NewProcessModal isOpen={isOpenNewModal} setOpen={setIsOpenNewModal}
                             reloadProcess={reloadProcessList}/>
        </>
    );
};

export default ProcessBoard;
