import React, {useEffect} from 'react';
import "./popup.css"
import ManageService from "../page/service/ManageService";
import FileService from "../page/service/FileService";

const StartPopup = (props) => {

    const fileDownload = (dtsId) => {
        if(dtsId) {
            ManageService.getDatasetInfo(dtsId).then((res) => {
                var data = res.data;
                var dtsType = data.dataset.dtsType;
                var dtsTitle = data.dataset.dtsTitle;
                var fileName = "";
                if(dtsType === "FIELD") {
                    fileName = dtsTitle + ".xlsx";
                    FileService.getDataExcel(dtsId, fileName, true);
                }
                else if(dtsType === "FILE") {
                    fileName = dtsTitle + ".zip";
                    FileService.getZipFileData(dtsId, fileName, true);
                }
            });
        }
    };

    const getProject = (prjId) => {
        if(prjId) {
            ManageService.getInfoProject(prjId).then((res) => {
                var data = res.data;
                props.setNProject({
                    "prjId":data.prjId,
                    "prjNm":data.prjNm,
                    "prjDscr":data.prjDscr,
                    "crUsr":data.crUsr,
                    "crDt":data.crDt
                });
            });
        }
    }

    const getDataset = (dtsId) => {
        if(dtsId) {
            ManageService.getDatasetInfo(dtsId).then((res) => {
                var data = res.data;
                props.setDatasetObj({
                    dtsType: data.dataset.dtsType,
                    dtsTitle: data.dataset.dtsTitle,
                    dtsDscr: data.dataset.dtsDscr
                });
            });
        }
    }

    // targetUrl을 기반으로 페이지 이동
    const getStep = (query) => {
        // targetUrl 값이 있으면 실행
        if(query.indexOf("project") !== -1) {
            var querySplit = query.split("/");
            var prjId = querySplit[2];
            var dtsId = querySplit.length >= 4?querySplit[3]:null;
            var type = querySplit.length >= 5?querySplit[4]:null;
            props.setPrjId(prjId);
            getProject(prjId);
            props.reloadDataset(prjId);
            // 프로젝트 정보 이동
            if(querySplit.length === 3) {
                props.goToStep(4);
            }
            //필드 정보 이동
            else if(querySplit.length === 4) {
                props.setDtsId(dtsId);
                props.loadDatasetInfo(dtsId);
                getDataset(dtsId);
                props.goToStep(6);
            }
            else if(querySplit.length === 5 && type === "viewer") {
                props.setDtsId(dtsId);
                props.loadDatasetInfo(dtsId);
                getDataset(dtsId);
                props.goToStep(8);
            }
            else if(querySplit.length === 5 && type === "file") {
                props.setDtsId(dtsId);
                props.loadDatasetInfo(dtsId);
                getDataset(dtsId);
                props.goToStep(10);
            }
        }
        else if(query.indexOf("html") !== -1) {
            props.goToStep(11);
        }
        else {
            props.goToStep(2);
        }
    };

    useEffect(() => {
        // targetUrl값이 있는지 판단
        var query = props.stepUrl?props.stepUrl:"";
        if(query.indexOf("download") === -1) {
            getStep(query);
        }
        else {
            var dtsId = query.split("/")[2];
            fileDownload(dtsId);
        }
    }, []);
    return (
        <>
        </>
    );
};

export default StartPopup;
