import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {Button, Card, Dropdown, DropdownButton, Form, Modal, Stack} from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableStyle from "../config/DataTableStyle";
import ManageService from "../service/ManageService";
import Swal from "sweetalert2";
import DataWorkService from "../service/DataWorkService";
import memoize from "memoize-one";
import FieldTableComponent from "../manage/component/FieldTableComponent";
import DatasetService from "../service/DatasetService";
import LightBtn from "../component/buttons";
import {Title2} from "../component/titles";
import ReactTooltip from "react-tooltip";
import WorkConditionComponent from "./component/WorkConditionComponent";

const columns = memoize((nav, deleteWork, workProgress) => [
    {
        name: '번호',
        maxWidth: '50px',
        center: true,
        selector: row => row.wkId
    },
    {
        name: '작업 명',
        selector: row => row.wkNm,
    },
    {
        name: '데이터 셋',
        selector: row => {
            return <Link to={`/project/${row.tbDatasetByDtsId.prjId}/${row.tbDatasetByDtsId.dtsId}`}>
                {row.tbDatasetByDtsId.dtsTitle}</Link>;
        }
    },
    {
        name: '분류',
        selector: row => row.wkType
    },
    {
        name: '작업 상태',
        selector: row => row.wkStage,
    },
    {
        name: '진척도',
        selector: row => workProgress(row),
    },
    {
        name: '관리',
        button: true,
        minWidth: '150px',
        cell: row => <>
            {
                row["tbWorkPrtcpsByWkId"].length===0||row["tbWorkPrtcpsByWkId"][0]["prtcpRole"] === "승인권한자"?
                    <>
                    <LightBtn color={"green"} size={"tiny"} icon={"link"}
                              data-tip data-for="workManage"
                              clickHandler={() => {
                                  nav(`/dataWork/${row.wkId}`)
                              }}/>
                    <ReactTooltip place="left" type="dark" effect="solid" id="workManage">
                <span>진행중인 모니터링 작업 관리 페이지로 이동합니다.</span>
                </ReactTooltip></>:null
            }
            <LightBtn color={"blue"} size={"tiny"} icon={"manage-blue"}
                      data-tip data-for="pjUpdate"
                      clickHandler={() => {
                          nav(`/dataWork/mntr/${row.wkId}`)
                      }}/>
            <LightBtn color={"red"} size={"tiny"} icon={"delete"}
                      data-tip data-for="pjDelete"
                      clickHandler={() => {
                          Swal.fire({
                              title : '',
                              text : "작업을 삭제하시겠습니까?",
                              type : "warning",
                              showCancelButton : true,
                              confirmButtonClass : "btn-danger",
                              confirmButtonText : "예",
                              cancelButtonText : "아니오",
                              closeOnConfirm : false,
                              closeOnCancel : true
                          }).then(result => {
                              if(result.isConfirmed) {
                                  deleteWork(row.wkId);
                                  Swal.fire("작업이 삭제되었습니다.")
                              }
                          })
                      }}/>
            <ReactTooltip place="top" type="dark" effect="solid" id="workDelete">
                <span>데이터 모니터링 작업을 삭제합니다.</span>
            </ReactTooltip>
            {/*<DropdownButton*/}
            {/*    title={""}*/}
            {/*>*/}
            {/*    <Dropdown.Item size={'sm'}*/}
            {/*                   data-tip data-for="pjUpdate"*/}
            {/*                   onClick={() => {*/}
            {/*                       nav(`/dataWork/mntr/${row.wkId}`)*/}
            {/*                   }}>작업</Dropdown.Item>*/}
            {/*    <Dropdown.Item size={'sm'}*/}
            {/*                   data-tip data-for="pjDelete"*/}
            {/*                   onClick={() => {*/}
            {/*                       //TODO 삭제 확인창 추가필요*/}
            {/*                       // Swal.fire("Not Implemented Yet");*/}
            {/*                       deleteWork(row.wkId);*/}
            {/*                   }}>삭제</Dropdown.Item>*/}
            {/*    <ReactTooltip place="top" type="dark" effect="solid" id="workDelete">*/}
            {/*        <span>데이터 모니터링 작업을 삭제합니다.</span>*/}
            {/*    </ReactTooltip>*/}
            {/*</DropdownButton>*/}
        </>,

    }
]);

const DataWorkBoard = (props) => {
    const nav = useNavigate();

    const [workList, setWorkList] = useState([]);

    const [datasetList, setDatasetList] = useState([]);

    const workTypeList = ["라벨링", "수집"];

    const [nWork, setNWork] = useState({wkNm: '', wkType: workTypeList[0]});

    const [datasetInfo, setDatasetInfo] = useState([]);
    const [fieldList, setFieldList] = useState([]);

    const [selector, setSelector] = useState(undefined);

    const [wkCount, setWkCount] = useState({});

    const workProgress = (row) => {
        var wkCnt = wkCount[row.wkId];

        if(row.wkCount && wkCnt) {
            return (wkCnt / row.wkCount * 100).toFixed(2) + '%';
        }
        else {
            return "0%";
        }
    };

    const loadDatasetInfo = (dtsId) => {
        DatasetService.loadDatasetInfo(dtsId, setDatasetInfo, setFieldList,
            (data, fields) => {
                if(datasetInfo.length === 0) {

                }
            },
            (msg) => {
                Swal.fire(msg)
            })
    };

    const reloadBoard = () => {
        DataWorkService.getWorkBoard().then((r) => {
            if (r !== undefined && r.status === 200) {
                var data = r.data;
                data.sort((w1, w2) => w2.crDt - w1.crDt);
                setWorkList(data);
            }

            console.log(r);
        });

        DataWorkService.getWorkHistoryCount().then((r) => {
            if (r !== undefined && r.status === 200) {
                var data = r.data;

                var wkCnt = {};
                for(var i in data) {
                    wkCnt[data[i]["WK_ID"]] = data[i]["cnt"];
                }
                setWkCount(wkCnt);
            }
        });
    };

    const loadDatasetList = () => {
        ManageService.getDatasetList().then((r) => {
            if (r !== undefined && r.status === 200) {
                setDatasetList(r.data);
                if(r.data.length !== 0) {
                    setNWork({...nWork, dtsId: r.data[0].dtsId});
                }
            }
        })
    };

    useEffect(() => {
        reloadBoard();
        loadDatasetList();
    }, []);

    /**
     * 작업 신규 생성 모달
     */
    const [isOpenNewModal, setIsOpenNewModal] = useState(false);

    const newWorkClose = () => setIsOpenNewModal(false);
    const newWorkOpen = () => setIsOpenNewModal(true);

    const saveWork = () => {
        var conditionMap = {};
        var j_selector = JSON.parse(selector);
        for(var i in j_selector) {
            var s = j_selector[i];
            var column = s["fieldCode"];
            var type;
            if(nWork.wkType === "라벨링") {
                type = s["fieldType"];
            } else if(nWork.wkType === "수집") {
                type = "modify";
            }
            var check = s["fieldCondition"];
            conditionMap[column] = {type:type, check:check};
        }
        var conditionList = [];
        for(var i in fieldList) {
            var condition = {};
            var fdCd = fieldList[i].fdCd;
            condition["column"] = fieldList[i].fdCd;
            condition["type"] = conditionMap[fdCd]?conditionMap[fdCd].type: nWork.wkType === "라벨링"?"read":"modify";
            condition["check"] = conditionMap[fdCd]?conditionMap[fdCd].check:"";
            conditionList.push(condition);
        }
        nWork.wkCondition = JSON.stringify(conditionList);
        DataWorkService.saveWork(nWork).then((r) => {
            if (r !== undefined && r.status === 200) {

                setNWork({
                    wkNm: '', wkType: workTypeList[0], dtsId: datasetList[0].dtsId
                });
                reloadBoard();
                newWorkClose();
            } else {
                Swal.fire("저장 실패")
            }

        })
    };

    const deleteWork = (wkId) => {
        console.log(wkId);
        DataWorkService.deleteWork(wkId).then((r) => {
            if (r !== undefined && r.status === 200) {
                reloadBoard();
            } else {
                Swal.fire("삭제 실패")
            }
        })
    };

    return (
        <>
            <Card className={'data-work-list'}>
                <Card.Header>
                    <Stack direction="horizontal">
                        <Title2>데이터 모니터링 작업 목록</Title2>
                        <LightBtn color={"green"} size={"small"}
                                  data-tip data-for="workAdd"
                                  clickHandler={() => {
                            newWorkOpen();
                            loadDatasetInfo(nWork.dtsId);
                        }}>+ 작업 추가</LightBtn>
                        <ReactTooltip place="top" type="dark" effect="solid" id="workAdd">
                            <span>데이터 모니터링 작업을 추가합니다.</span>
                        </ReactTooltip>
                    </Stack>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        columns={columns(nav, deleteWork, workProgress)}
                        data={workList}
                        customStyles={DataTableStyle.customStyles}
                        highlightOnHover
                        // pointerOnHover
                        pagination
                    />

                </Card.Body>
            </Card>

            <Modal
                enforceFocus={false}
                show={isOpenNewModal} onHide={newWorkClose}>
                <Modal.Header closeButton>
                    <Modal.Title>작업 추가</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>작업명</Form.Label>
                        <Form.Control type="text" value={nWork.wkNm}
                                      onChange={(e) => {
                                          setNWork({...nWork, wkNm: e.target.value})
                                      }}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>작업 유형</Form.Label>
                        <Form.Select value={nWork.wkType}
                                     onChange={(e) => {
                                         setNWork({...nWork, wkType: e.target.value})
                                     }}>
                            {
                                workTypeList.map((e, i) => {
                                    return (
                                        <option key={i} value={e}>{e}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>대상 데이터셋 정보</Form.Label>
                        <Form.Select value={nWork.dtsId}
                                     onChange={(e) => {
                                         setNWork({...nWork, dtsId: e.target.value});
                                         loadDatasetInfo(e.target.value);
                                     }}>
                            {
                                datasetList.map((e, i) => {
                                    if(e === null) return null;
                                    return (
                                        <option key={i} value={e.dtsId}>{e.dts.dtsTitle}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <Card>
                        <Card.Header>
                            <Stack className={"form-label"}>
                                필드 정의
                            </Stack>
                        </Card.Header>
                        <Card.Body>
                            <FieldTableComponent
                                dtsId={nWork.dtsId}
                                fieldList={fieldList}
                            />
                        </Card.Body>
                    </Card>
                    <Form.Group className="mb-3">
                        <Form.Label>{nWork.wkType==="라벨링"?"작업 시작 번호":"작업 개수"}</Form.Label>
                        <Form.Control type="text" value={nWork.wkCount}
                                      onChange={(e) => {
                                          setNWork({...nWork, wkCount: e.target.value})
                                      }}/>
                    </Form.Group>
                    <WorkConditionComponent
                        onChange={(v) => {
                            setSelector(v);
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <LightBtn size={"middle"} color={"red"} clickHandler={newWorkClose}>
                        취소
                    </LightBtn>
                    <LightBtn size={"middle"} color={"green"} clickHandler={() => {
                        if(!nWork.wkNm) {
                            Swal.fire("작업 명을 입력해주세요.")
                        }
                        else {
                            saveWork();
                        }
                    }}>
                        저장
                    </LightBtn>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DataWorkBoard;
