import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import {format, parse} from "date-fns";
import DatePicker from "react-datepicker";
import './DateRange.css'; // main css file
import { DateRange } from 'react-date-range';
import DropZoneUpdate from "../../component/DropZoneUpdate";
import {useParams} from "react-router-dom";
import Swal from "sweetalert2";
import notImage from "../../../images/image_noimage.png";
import LightBtn from "../../component/buttons";

const FdTypeFormComponent = (props) => {

    const params = useParams();

    const [check, setCheck] = useState(new Date().getTime());

    const haveData = Object.keys(props.data).length !== 0;

    const handleImgError = (e) => {
        e.target.src = notImage;
    };

    const dtsId = props.dtsId !== undefined ? props.dtsId : params.dtsId;

    return (
        <>
            {
                props.field.fdType === 'TEXT' ?
                    <Form.Control readOnly={props.type === 'read'}
                                  type="text"
                                  value={haveData && props.data[props.field.fdCd] ? props.data[props.field.fdCd] : ""}
                                  onChange={e => props.setData({...props.data, [props.field.fdCd]: e.target.value})}
                    /> : null
            }
            {
                props.field.fdType === 'LARGE_TEXT' ?
                    <Form.Control readOnly={props.type === 'read'}
                                  as="textarea" rows={3}
                                  value={haveData && props.data[props.field.fdCd] ? props.data[props.field.fdCd] : ""}
                                  onChange={e => props.setData({...props.data, [props.field.fdCd]: e.target.value})}
                    /> : null
            }
            {
                props.field.fdType === 'INTEGER' ?
                    <Form.Control readOnly={props.type === 'read'}
                                  type="text"
                                  value={haveData && props.data[props.field.fdCd] ? props.data[props.field.fdCd] : ""}
                                  onChange={e => {
                                      var fd_INTEGER = e.target.value;
                                      fd_INTEGER = fd_INTEGER.replace(/[^0-9]/g, "");
                                      props.setData({...props.data, [props.field.fdCd]: fd_INTEGER})
                                  }}
                    /> : null
            }
            {
                props.field.fdType === 'DATE' ?
                    <DatePicker readOnly={props.type === 'read'}
                                selected={
                                    haveData && props.data[props.field.fdCd] ? parse(props.data[props.field.fdCd], props.field.fdFormat || "yyyy-MM-dd", new Date()) : undefined
                                }
                                onChange={(date) => {
                                    var formatDate = format(date, props.field.fdFormat || "yyyy-MM-dd");

                                    if(formatDate.length > 10) {
                                        props.setData({
                                            ...props.data,
                                            [props.field.fdCd]: format(new Date(), props.field.fdFormat || "yyyy-MM-dd")
                                        });
                                    }
                                    else {
                                        props.setData({
                                            ...props.data,
                                            [props.field.fdCd]: formatDate
                                        });
                                    }
                                }}

                                dateFormat={props.field.fdFormat || "yyyy-MM-dd"}
                                className="form-control"
                    /> : null
            }
            {
                props.field.fdType === 'DATE_RANGE' ?
                    <DateRange
                                ranges={[
                                    {
                                        startDate: props.field.fdType==="DATE_RANGE"&&props.data[props.field.fdCd]?new Date(props.data[props.field.fdCd].split(" ~ ")[0]):new Date(),
                                        endDate: props.field.fdType==="DATE_RANGE"&&props.data[props.field.fdCd]?new Date(props.data[props.field.fdCd].split(" ~ ")[1]):new Date(),
                                        key: 'selection'
                                    }
                                ]}
                                onChange={(date) => {
                                    var fdFormat = props.field.fdFormat;
                                    var startDate = date.selection.startDate;
                                    startDate = format(startDate, fdFormat || "yyyy-MM-dd");
                                    var endDate = date.selection.endDate;
                                    endDate = format(endDate, fdFormat || "yyyy-MM-dd");
                                    const dateR = startDate + ' ~ ' + endDate;
                                    props.setData({
                                        ...props.data,
                                        [props.field.fdCd]: dateR
                                    });
                                    // setRangeState([date.selection]);
                                }}
                                className="form-control"
                    /> : null
            }
            {
                props.field.fdType === 'DATETIME' ?
                    <DatePicker readOnly={props.type === 'read'}
                                selected={
                                    haveData && props.data[props.field.fdCd] ? parse(props.data[props.field.fdCd], props.field.fdFormat || "yyyy-MM-dd HH:mm", new Date()) : undefined
                                }
                                onChange={(date) => {
                                    var formatDate = format(date, props.field.fdFormat || "yyyy-MM-dd HH:mm");

                                    if(formatDate.length > 16) {
                                        props.setData({
                                            ...props.data,
                                            [props.field.fdCd]: format(new Date(), props.field.fdFormat || "yyyy-MM-dd HH:mm")
                                        });
                                    }
                                    else {
                                        props.setData({
                                            ...props.data,
                                            [props.field.fdCd]: formatDate
                                        });
                                    }
                                }}
                                timeInputLabel="Time:"
                                dateFormat={props.field.fdFormat || "yyyy-MM-dd HH:mm"}
                                showTimeInput
                                className="form-control"
                    /> : null
            }
            {
                props.field.fdType === 'FILE_JSON' || props.field.fdType === 'FILE' ?
                    <div>
                        <div className={"dropzone-area"}>
                            {
                                props.dtId !== null ?
                                    <>
                                        <div className={"dropzone-image"} style={{"textAlign": "center"}}>
                                            <img src={`${process.env.REACT_APP_SERVER_PROXY}/media/${dtsId}/${props.dtId}`
                                                + "?fdCd=" + props.field.fdCd + "&token="
                                                + sessionStorage.getItem("token") + "&" + check}
                                                 onError={handleImgError}
                                                 style={{maxWidth: "100%", maxHeight: "100%"}}
                                                 alt='이미지가 없습니다.'/>
                                        </div>
                                        <div className={"dropzone-view"}>
                                            <LightBtn
                                                color={"blue"}
                                                size={"small"}
                                                type={"button"}
                                                clickHandler={() => {
                                                    Swal.fire({
                                                        imageUrl: `${process.env.REACT_APP_SERVER_PROXY}/media/${dtsId}/${props.dtId}`
                                                            + "?fdCd=" + props.field.fdCd + "&token="
                                                            + sessionStorage.getItem("token")
                                                    })
                                                }}>
                                                미리보기
                                            </LightBtn>
                                        </div>
                                    </>:null
                            }
                            {
                                props.type === 'read' ? null :
                                    <div className={"dropzone-file"}>
                                        {
                                            <DropZoneUpdate
                                                dtsId={dtsId}
                                                dtId={props.dtId}
                                                reloadData={props.reloadData}
                                                setData={props.setData}
                                                updateSetData={props.updateSetData}
                                                setCheck={setCheck}
                                                uploadDtId={props.uploadDtId}
                                                saveCheck={props.saveCheck}
                                                mntrCheck={props.mntrCheck}
                                                fdCd={props.field.fdCd}
                                            />
                                        }
                                    </div>
                            }

                        </div>
                    </div> : null
            }
        </>
    );
};

export default FdTypeFormComponent;
