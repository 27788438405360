import React, {useEffect, useMemo, useState} from 'react';
import AuthenticationService from "../../../auth/AuthenticationService";
import LayoutService from "../../service/LayoutService";
import {Outlet} from "react-router-dom";
import {Container} from "react-bootstrap";
import ATNavComponent from "./component/ATNavComponent";
import ATPageHeaderComponent from "./component/ATPageHeaderComponent";
import "./AdminTopLayout.css"
import LoadingOverlay from 'react-loading-overlay'
import RingLoader from 'react-spinners/RingLoader'

const AdminTopLayout = (props) => {

    const [menus, setMenus] = useState([]);

    const [loaderActive,setLoaderActive] = useState(true);


    useEffect(() => {

        AuthenticationService.getMenus().then((r) => {
            if (r !== undefined && r.status === 200) {
                setMenus(LayoutService.permitSplitter(r.data))
            } else {
                console.log(r)
            }
        }).catch((e) => {
            console.log(e);
            if (e.response !== undefined && e.response.status === '401') {
                console.log(401);
            }
        })

    }, []);

    return (
        // <LoadingOverlay
        //     active={loaderActive}
        //     spinner={<RingLoader color="#3ABFBD" />}
        //     style={{
        //         position:"absolute",
        //         top:0,
        //         left:0,
        //         width:'100vw',
        //         height:'100vh'
        //     }}
        // >
        <div className="page">

            <ATNavComponent menus={menus}/>

            <Container>
                {
                    // Dashboard Page Header Disabled
                    props.breadcrumbs.length===1 && props.breadcrumbs[0].key==="/"?
                        ""
                        :<ATPageHeaderComponent preTitle={"Data Factory"}
                                                title={props.breadcrumbs[props.breadcrumbs.length - 1].breadcrumb}
                                                breadcrumbs={props.breadcrumbs}/>
                }


                <Outlet/>

            </Container>

        </div>
        // </LoadingOverlay>
    );
};

export default AdminTopLayout;
