import React, {useEffect, useState} from "react";
import {Card, Col, Form, ListGroup, Stack} from "react-bootstrap";
import LightBtn from "../../component/buttons";
import Swal from "sweetalert2";

const WorkConditionComponent = ({onChange}) => {

    const [selectors, setSelectors] = useState([]);

    useEffect(() => {
        onChange(JSON.stringify(selectors))
    }, [selectors]);


    const [newSelector, setNewSelector] = useState(undefined);

    const deleteSelector = ()=>{
        const result = selectors.filter(s => s.fieldCode !== newSelector.fieldCode);
        setSelectors([...result]);
        setNewSelector(undefined);
    };

    const saveSelector = ()=>{
        if(newSelector&& newSelector.fieldCode===""){
            Swal.fire("필드 코드를 입력해주세요.");

            return;
        }
        let isChange = false;
        for(let idx in selectors){
            if(selectors[idx].fieldCode === newSelector.fieldCode){
                selectors[idx]=newSelector;
                isChange=true;
            }
        }
        if(isChange)
        {
            setSelectors([...selectors]);
        }else{
            setSelectors([...selectors,
                newSelector]);
        }

        setNewSelector(undefined);
    };

        return (
            <Card>
                <Card.Header>
                    <Stack direction="horizontal" gap={3}>
                        <div>작업 조건</div>
                        <div className="ms-auto">
                            <LightBtn  size="sm" color={"green"} size={"sm"} clickHandler={() => {
                                setNewSelector(
                                    {
                                        fieldCode: "",
                                        fieldType: "",
                                        fieldCondition: "",
                                    }
                                )
                            }}>추가</LightBtn>
                        </div>
                    </Stack>
                </Card.Header>
                <Card.Body className="border-bottom pb-1">
                    {
                        newSelector!==undefined?<div>
                            <Form.Group className="mb-3">
                                <Form.Label>필드 코드 (필수)</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Field Code"
                                    value={newSelector.fieldCode ? newSelector.fieldCode : ""}
                                    onChange={(e) => {
                                        setNewSelector({
                                            ...newSelector,
                                            fieldCode:e.target.value.toUpperCase()
                                        })
                                    }}
                                />
                            </Form.Group>
                            <Form.Label>작업 타입</Form.Label>
                            <Form.Select
                                size="sm"
                                onChange={(e) => {
                                    setNewSelector({
                                        ...newSelector,
                                        fieldType:e.target.value
                                    })
                                }}
                            >
                                <option value={"read"} selected={newSelector.fieldType === "read"}>{"읽기"}</option>
                                <option value={"modify"} selected={newSelector.fieldType === "modify"} >{"수정"}</option>
                            </Form.Select>
                            <Form.Label>필드 조건</Form.Label>
                            <Form.Select
                                size="sm"
                                onChange={(e) => {
                                    setNewSelector({
                                        ...newSelector,
                                        fieldCondition:e.target.value
                                    })
                                }}
                            >
                                <option value={""} selected={newSelector.fieldType === ""}>{"미선택"}</option>
                                <option value={"empty"} selected={newSelector.fieldType === "empty"} >{"비어있음"}</option>
                            </Form.Select>

                            <LightBtn clickHandler={saveSelector} color={"blue"} size={"sm"}>저장</LightBtn>
                            <LightBtn className={"ms-2"} clickHandler={()=>{deleteSelector()}} color={"red"} size={"sm"}>삭제</LightBtn>
                        </div>:null
                    }
                </Card.Body>
                <ListGroup variant="flush" className={"mt-1"}>
                    {selectors.map((s, i) => {
                        return <Card key={s}>
                            <Card.Body className={"bg-transparent border-1 border-secondary p-1"}>
                                <Stack direction="horizontal" gap={3}>
                                    <div>{s.fieldCode}</div>
                                    <div className="ms-auto">
                                        <LightBtn
                                            clickHandler={()=>{
                                                setNewSelector(s)
                                            }
                                            }
                                            color={"outline-warning"} size={"sm"}>수정</LightBtn>
                                    </div>
                                </Stack>
                            </Card.Body>
                        </Card>;
                    })
                    }

                </ListGroup>
            </Card>
        );
    }
;
export default WorkConditionComponent;
