import React from 'react';
import StepFooter from "./component/StepFooter";
import DataTable from "react-data-table-component";
import DataTableStyle from "../page/config/DataTableStyle";
import Swal from "sweetalert2";
import ManageService from "../page/service/ManageService";
import "./popup.css"
import {toast} from "react-toastify";
import PopupATPageHeader from "./component/PopupATPageHeader";

const ProjectContent = (props) => {

    const columns = [
        {
            name: '번호',
            maxWidth: '50px',
            center: true,
            selector: row => row.dtsId
        },
        {
            name: '데이터셋 명',
            selector: row => row.dts.dtsTitle,
        },
        {
            name: '분류',
            selector: row => row.dts.dtsType,
        },
        {
            name: '관리',
            button: true,
            cell: row => <>
                <button
                    className={"popupBtn popupBtn-white"} onClick={() => {
                    props.setDatasetObj({
                        dtsType: row.dts.dtsType,
                        dtsTitle: row.dts.dtsTitle,
                        dtsDscr: row.dts.dtsDscr
                    })
                    props.goToStep(5);
                    props.setDtsId(row.dtsId);
                    props.loadDatasetInfo(row.dtsId);
                }}>바로가기
                </button>
            </>,
        }
    ];

    const saveProject = (btnCheck) => {
        const toast_id = toast.loading("프로세스를 저장중입니다.")
        ManageService.saveProject(props.nProject).then((r) => {
            if (r !== undefined && r.status === 200) {
                props.setPrjId(r.data);
                toast.update(toast_id, {render: "프로젝트가 저장되었습니다.", type: "success", autoClose: 2000, isLoading: false})
                if (btnCheck === undefined) {
                    props.goToStep(5);
                }
            }
        }).catch(reason => {
            toast.update(toast_id, {render: "저장에 실패했습니다.", type: "error", autoClose: 2000, isLoading: false});
        })
    }

    return (
        <>
            <div className={"popup-wrapper"}>
                <div className={"popup-header"}>
                    <div className={"ph-ttl"}>
                        {/*<PopupATPageHeader*/}
                        {/*    pageList={[*/}
                        {/*        {*/}
                        {/*            "header": "프로젝트",*/}
                        {/*            "step":4*/}
                        {/*        }*/}
                        {/*    ]}*/}
                        {/*    goToStep={props.goToStep}*/}
                        {/*/>*/}
                        <h5>프로젝트 정보</h5>
                        <p>{
                            !props.prjId ? "새로 생성할 프로젝트 정보를 입력합니다." : "선택한 프로젝트 정보 및 프로젝트에 포함된 데이터셋 목록을 보여줍니다.\n"
                        }</p>
                    </div>
                </div>
                <div className={"popup-body"}>
                    <div className={'popup-form'}>
                        <div className={"popup-form-body"}>
                            <div className={"popup-form-row"}>
                                <div className={"popup-form-th"}>프로젝트 명</div>
                                <div className={"popup-form-td"}>
                                    <input type="text" className={"popup-input"} value={props.nProject.prjNm}
                                           onChange={(e) => {
                                               props.setNProject({...props.nProject, prjNm: e.target.value})
                                           }}/>
                                </div>
                            </div>
                            <div className={"popup-form-row"}>
                                <div className={"popup-form-th"}>프로젝트 설명</div>
                                <div className={"popup-form-td"}>
                                    <textarea rows={3} className={"popup-input"} value={props.nProject.prjDscr}
                                              onChange={(e) => {
                                                  props.setNProject({
                                                      ...props.nProject,
                                                      prjDscr: e.target.value
                                                  })
                                              }}/>
                                </div>
                            </div>
                        </div>
                        <div className={"popup-form-footer"} style={{marginTop: '10px'}}>
                            <div className={"pth-btnWrapper"}>
                                {
                                    props.datasetList.length !== 0 ? null :
                                        <button style={{marginLeft: 'auto'}} className={"popupBtn popupBtn-white"} onClick={() => {
                                            if (props.nProject.prjNm !== "") {
                                                saveProject();
                                            } else {
                                                Swal.fire("프로젝트명을 적어주세요.");
                                            }
                                            props.setDatasetObj({
                                                dtsType: "FILE",
                                                dtsTitle: "",
                                                dtsDscr: ""
                                            })
                                        }}>+ 데이터셋 추가</button>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        props.datasetList.length === 0 ? null :
                            <div className={'popup-table'} style={{marginTop: '30px'}}>
                                <div className={"popup-table-header"}>
                                    <div className={"pth-ttl"}>데이터셋 목록</div>
                                    <div className={"pth-btnWrapper"}>
                                        <button className={"popupBtn popupBtn-white"} onClick={() => {
                                            if (props.nProject.prjNm !== "") {
                                                props.goToStep(5);
                                                saveProject();
                                            } else {
                                                Swal.fire("프로젝트명을 적어주세요.");
                                            }
                                            props.setDatasetObj({
                                                dtsType: "FILE",
                                                dtsTitle: "",
                                                dtsDscr: ""
                                            })
                                        }}>+ 데이터셋 추가
                                        </button>
                                    </div>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={props.datasetList}
                                    customStyles={DataTableStyle.popupStyle}
                                    highlightOnHover
                                    // pointerOnHover
                                    pagination
                                />
                            </div>
                    }

                    <div className={"popup-footer"}>
                        {/*<StepFooter*/}
                        {/*    btnList={[{"Name": "이전", "Step": 2}]}*/}
                        {/*    goToStep={props.goToStep}*/}
                        {/*    saveHandler={saveProject}*/}
                        {/*    id={props.prjId}*/}
                        {/*    check={props.nProject.prjNm}*/}
                        {/*>*/}
                        {/*</StepFooter>*/}

                        <button className={"popupBtn popupBtn-blue ms-auto"} onClick={() => {
                            saveProject(1);
                        }}
                        >
                            저장
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectContent;
