import React, {useState} from 'react';
import StepFooter from "./component/StepFooter";
import {Row} from "react-bootstrap";
import FieldTableComponent from "../page/manage/component/FieldTableComponent";
import "./popup.css"
import ManageService from "../page/service/ManageService";
import Swal from "sweetalert2";
import DatasetAllDataModal from "../page/manage/component/modal/DatasetAllDataModal";
import DataViewer from "../page/manage/DataViewer";
import DataTable from "react-data-table-component";
import DataTableStyle from "../page/config/DataTableStyle";

const FieldList = (props) => {

    const [isOpenNewModal, setIsOpenNewModal] = useState();
    // 일괄데이터 입력 모달
    const [isOpenAllDataModal, setIsOpenAllDataModal] = useState(false);

    const deleteField = (row) => {
        ManageService.deleteField(row).then((r) => {
            if (r !== undefined && r.status === 200) {
                props.loadDatasetInfo(props.dtsId);
            } else {
                Swal.fire("삭제 실패");
            }
        })
    };

    return (
        <>
            <div className={"popup-wrapper"}>
                <div className={"popup-header"}>
                    <div className={"ph-ttl"}>
                        <h5>필드 데이터셋</h5>
                        <p>선택한 데이터셋의 필드 정보를 보여주고 새 필드를 추가할 수 있습니다.</p>
                    </div>
                </div>
                <div className={"popup-body"}>
                    <div className={"popup-table-header"}>
                        <div className={"pth-btnWrapper"}>
                            {
                                props.datasetInfo.dtsType === "FIELD" ?
                                    <button className={"popupBtn popupBtn-white"}
                                            data-tip data-for="fieldDataInput"
                                            onClick={() => {
                                                //TODO 일괄업로드 기능 구현
                                                if (props.fieldList.length === 0) {
                                                    Swal.fire("필드를 최소 한개 이상 만들어주세요.")
                                                } else {
                                                    setIsOpenAllDataModal(true);
                                                }
                                                // Swal.fire("Not Implemented. ")
                                            }}>일괄데이터 입력</button> : null
                            }
                            <button className={"popupBtn popupBtn-white"} onClick={() => {
                                setIsOpenNewModal(true)
                            }}>+ 필드 추가
                            </button>
                        </div>
                    </div>
                    <div>
                        {
                            props.dtsId !== null ?
                                <FieldTableComponent
                                    className={"popupModal"}
                                    dtsId={props.dtsId}
                                    isOpenNewModal={isOpenNewModal}
                                    setIsOpenNewModal={setIsOpenNewModal}
                                    deleteField={deleteField}
                                    styleType={"popup"}
                                /> : null
                        }
                        <DatasetAllDataModal
                            className={"popupModal"}
                            prjId={props.prjId}
                            dtsId={props.dtsId}
                            fieldList={props.fieldList}
                            isOpenAllDataModal={isOpenAllDataModal}
                            setIsOpenAllDataModal={setIsOpenAllDataModal}
                            dtsTitle={props.datasetInfo.dtsTitle}
                        />
                    </div>
                    <div className={"popup-footer"}>
                        <StepFooter
                            btnList={[
                                // {"Name": "이전", "Step": 5},
                                {"Name": "데이터보기", "Step": 8}]}
                            goToStep={props.goToStep}
                        >
                        </StepFooter>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FieldList;