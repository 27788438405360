import React from 'react';
import LightBtn from "../../page/component/buttons";

const ProjectType = (props) => {
    return (
        <div className={"project-type-btn"} onClick={() => {
            if (props.reloadBoard !== undefined) {
                props.reloadBoard();
            }
            if (props.setProjectList !== undefined) {
                props.setPrjId(null);
                props.setDtsId(null);
                props.setProjectList([]);
                props.setDatasetList([]);
                props.setFieldList([]);
                props.setNProject({prjNm: '', prjDscr: ''});
            }
            props.nextStep(props.stepN);
        }}>
            <div className={"typeSelect-ttl"}>
                <h3 className={"project-type-title"}>{props.ttl}</h3>
                <span className={"typeSelect-ettl"}>{props.ettl}</span>
            </div>
            <div className={"typeSelect-img " + props.icon}></div>
            <div className={"typeSelect-sttl"}>{props.sttl}</div>
        </div>
    );
};

export default ProjectType;
