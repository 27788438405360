import React, {useEffect, useState} from 'react';
import ProcessService from "../../../service/ProcessService";
import {Form} from "react-bootstrap";

const DatasetSelectTool = ({pName, prcId, value, onChange}) => {

    const [datasetList, setDatasetList] = useState([]);

    useEffect(() => {
        ProcessService.getProcessDatasetList(prcId).then(r => {
            if (r !== undefined && r.status === 200) {
                setDatasetList(r.data);
            } else {
                //TODO 예외처리
            }
        })
    }, [])

    return (
        <Form.Select  size="sm" value={value} onChange={(e) => {
            onChange(e.target.value)
        }}>
            <option value={''} defaultValue>미선택</option>
            {
                datasetList.map(n => {
                    return <option key={`${prcId}-${n.dtsId}-${pName}`}
                                   value={n.dtsId}>{`${n.dtsType} : ${n.dtsTitle}`}</option>
                })
            }
        </Form.Select>
    );
};

export default DatasetSelectTool;
