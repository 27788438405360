import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import DataTableStyle from "../../config/DataTableStyle";
import DataTable from "react-data-table-component";
import DataService from "../../service/DataService";
import DatasetService from "../../service/DatasetService";
import Swal from "sweetalert2";
import DataViewModal from "./DataViewModal";

const DataViewTableComponent =forwardRef((props,ref) => {
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(props.perPage);
    const [nowPape, setNowPage] = useState(0);
    const [data, setData] = useState([]);


    /**
     * 단일 선택 데이터 정보
     */
    const [selectedData, setSelectedData] = useState({});
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    /**
     * 데이터 테이블 Pending (보류) Column 및 데이터 불러온 후 처리 -> Loading 처리
     */
    const [pending, setPending] = React.useState(true);
    const [columns, setColumns] = useState([]);

    const [fieldList, setFieldList] = useState([]);
    const [datasetInfo, setDatasetInfo] = useState({});


    const fieldToColumn = (fields) => {
        const columnData = [{
            name: '데이터번호',
            selector: row => row.dataMeta.dtId,
            sortable: true,
            width: "120px",
        }];

        fields.forEach(f => {
            columnData.push({
                name: f.fdNm,
                selector: row => row.values[f.fdCd],
            });

            if (f.child !== undefined) {
                f.child.forEach(c => {
                    columnData.push({
                        name: c.fdNm,
                        selector: row => row.values[c.fdCd],
                    })
                });
            }
        });

        return columnData;
    };
    const reloadData = () => {
        DataService.getRowData(props.dtsId, nowPape, perPage).then(r => {
            if (r !== undefined && r.status === 200) {
                var rowData = r.data;
                if(props.title) {
                    rowData = rowData.sort((a, b) => {
                        return b.dataMeta.crDt - a.dataMeta.crDt
                    }).slice(0, 10);
                }
                else {
                    rowData = rowData.sort((a, b) => {
                        return a.dataMeta.dtId - b.dataMeta.dtId
                    });
                }
                setData(rowData);
                loadDatasetInfo();
                setToggleClearRows(!toggledClearRows);
            } else {
                //TODO  예외처리
            }
        })
    };

    useEffect(()=>{
        reloadData();
    },[nowPape, perPage]);



    const handlePageChange = page => {
        setNowPage(page - 1);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setNowPage(0);
        setPerPage(newPerPage);
    };

    /**
     * 데이터셋 정보 가져오기 [ 필드 정보, 데이터 전체 개수 등 ]
     */
    const loadDatasetInfo = () => {
        DatasetService.loadDatasetInfo(props.dtsId, setDatasetInfo, setFieldList,
            (data, fields) => {
                setTotalRows(data.totalDataSize);
                const c = fieldToColumn(fields);
                setColumns(c);
                setPending(false);
                if(datasetInfo.length === 0) {

                }

                //! Data Load
                // reloadData(nowPape, perPage);
            },
            (msg) => {
                Swal.fire(msg)
            })
    };

    useEffect(() => {
        if (props.dtsId !== undefined)
            loadDatasetInfo();
            reloadData();
    }, [props.dtsId]);

    useImperativeHandle(ref, () => ({
        reloadData() {
            reloadData();
        }
    }));

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                progressPending={pending}
                customStyles={props.styleType==="popup"?DataTableStyle.popupStyle:DataTableStyle.smCompactGrid}
                pagination
                paginationServer
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={
                    (row, event) => {
                        setSelectedData(row);
                        props.rowClick(row)
                    }
                }
                selectableRows
                clearSelectedRows={toggledClearRows}
                selectableRowsHighlight
                onSelectedRowsChange={(selectedRows) => {
                    props.setSelectedRows(selectedRows.selectedRows);
                }}
            />
            <DataViewModal className={props.className}
                           data={selectedData}
                           type={props.dataViewModalType}
                           dtsId={props.dtsId}
                           reloadData={reloadData}
                           fieldList={fieldList}
                           show={props.dataViewModalOpen}
                           onHide={() => {
                               props.setDataViewModalOpen(false);
                               setSelectedData({})
                           }}
            />
        </>
    );
});

export default DataViewTableComponent;
