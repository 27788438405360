import React from 'react';
import {Badge, ListGroup} from "react-bootstrap";
import {format} from "date-fns";

const TaskExceptionList = ({exceptions}) => {
    if(!exceptions) return '';
    return (
        <ListGroup as="ol" numbered>
            {exceptions.map((e,i) => {


                    return <ListGroup.Item
                        key={i+':taskexception'}
                        variant="dark"
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">{e.title}</div>
                            {e.message}
                        </div>
                        <Badge bg="primary" pill>
                            {format(e.errorTime, 'yyyy-MM-dd hh:mm:ss')}
                        </Badge>
                    </ListGroup.Item>
                }
            )}

        </ListGroup>
    );
};

export default TaskExceptionList;
