import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import Select from "react-select";

const MultiSelectTool = ({pName, precdTask, value, onChange}) => {
    if(!precdTask){
        return <p>선행노드가 없습니다.</p>
    }else if(!precdTask.lastTest){
        return <p>선행 노드를 먼저 테스트해주세요.</p>
    }

    const options =  precdTask.lastTest.node?precdTask.lastTest.node.outputParams.map((p) => {
        return {value:p, label: p}
    }):[];
    return (
        <Select
            defaultValue={value}
            onChange={(e) => {
                var fieldList = [];
                for(var i in e) {
                    var field = {
                        userName: sessionStorage.authenticatedUser,
                        targetDataset: precdTask.taskDef.localProperty.targetDataset,
                        fieldCode: e[i].value
                    };
                    fieldList.push(field);
                }
                onChange(JSON.stringify(fieldList));
            }}
            options={options}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            isSearchable={false}
        />
        // <Form.Select  size="sm" value={value} onChange={(e) => {
        //     onChange(e.target.value)
        // }}>
        //     <option value={''} defaultValue>미선택</option>
        //     {
        //         precdTask.lastTest.node.outputParams.map((p)=>{
        //             return <option key={`${pName}-${p}`}
        //                            value={p}>{p}</option>
        //         })
        //     }
        // </Form.Select>
    );
};

export default MultiSelectTool;
