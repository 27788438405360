import React, {useMemo, useState} from "react";

import "./theme/darkmode/bootstrap.min.css";
import "./App.css";
import {ToastContainer, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes, useNavigate } from "react-router-dom";
import LoginPage from "./auth/component/LoginComponent";
import ErrorPage from "./error/ErrorPage";
import RequireAuth from "./auth/RequireAuth";
import DashboardPage from "./page/dashboard/DashboardPage";
import DataManagePage from "./page/manage/DataManagePage";
import ProjectBoard from "./page/manage/ProjectBoard";
import DatasetBoard from "./page/manage/DatasetBoard";
import FileDataManager from "./page/manage/FileDataManager";
import DatasetInfo from "./page/manage/DatasetInfo";
import DataViewer from "./page/manage/DataViewer";
import DataWorkBoard from "./page/work/DataWorkBoard";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import AdminTopLayout from "./component/layout/admintop/AdminTopLayout";
import DataProcessPage from "./page/process/DataProcessPage";
import ProcessBoard from "./page/process/ProcessBoard";
import DataWorkPage from "./page/work/DataWorkPage";
import DataWorkManager from "./page/work/DataWorkManager";
import DataWorkHistory from "./page/work/DataWorkHistory";
import axios from "axios";
import ManagePopup from "./popup/ManagePopup";
import AuthenticationService from "./auth/AuthenticationService";
import ProcessEditor from "./page/process/ProcessEditor";
import ReactTooltip from "react-tooltip";
import DataWorkMonitoring from "./page/work/DataWorkMonitoring";
import ManagePage from "./manage/ManagePage";
import ManageTopLayout from "./manage/component/layout/ManageTopLayout";
import DetailUserPage from "./manage/user/DetailUserPage";
import ManageDataPage from "./manage/data/ManageDataPage";


const routes = [
    {
        path: "/project",
        breadcrumb: (/*match*/) => {
            return <span>프로젝트 관리</span>;
        },
    },
    {
        path: "/project/:prjId",
        breadcrumb: (/*match*/) => {
            return <span>프로젝트 정보</span>;
        },
    },
    {
        path: "/project/:prjId/:dtsId",
        breadcrumb: (/*match*/) => {
            return <span>데이터셋 정보</span>;
        },
    },
    {
        path: "/project/:prjId/:dtsId/file",
        breadcrumb: (/*match*/) => {
            return <span>파일 관리</span>;
        },
    },
    {
        path: "/project/:prjId/:dtsId/viewer",
        breadcrumb: (/*match*/) => {
            return <span>데이터 뷰어</span>;
        },
    },
    {
        path: "/dataWork",
        breadcrumb: (/*match*/) => {
            return <span>작업 관리</span>;
        },
    },
    {
        path: "/dataWork/mntr/:wkId",
        breadcrumb: (/*match*/) => {
            return <span>작업 진행</span>;
        },
    },
    {
        path: "/dataWork/:wkId",
        breadcrumb: (/*match*/) => {
            return <span>작업 정보</span>;
        },
    },
    {
        path: "/dataWork/:wkId/:prtcpUsr",
        breadcrumb: (/*match*/) => {
            return <span>작업 이력</span>;
        },
    },
    {
        path: "/process",
        breadcrumb: (/*match*/) => {
            return <span>프로세스 관리</span>;
        },
    },
    {
        path: "/process/:prcId",
        breadcrumb: (/*match*/) => {
            return <span>프로세스 정보</span>;
        },
    },
    {
        path: "/manage",
        breadcrumb: (/*match*/) => {
            return <span>유저 관리</span>;
        },
    },
    {
        path: "/manage/:mbId",
        breadcrumb: (/*match*/) => {
            return <span>유저 세부 정보</span>;
        },
    },
    {
        path: "/manage/data",
        breadcrumb: (/*match*/) => {
            return <span>데이터 관리</span>;
        },
    }
];

axios.defaults.baseURL = process.env.REACT_APP_SERVER_PROXY;
const App = () => {
    let nav = useNavigate();

    const breadcrumbs = useBreadcrumbs(routes);

    const [userInfo, setUserInfo] = useState({});
    //token Interceptor 중복처리 방지
    const [prevInterceptor, setPrevInterceptor] = useState({});

    const [userManageInfo, setUserManageInfo] = useState({});

    useMemo(() => {
        AuthenticationService.setupAxiosInterceptors(setUserInfo, nav);
    }, []);

    return (
        <div>
            <ReactTooltip place="top" type="dark" effect="solid"/>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={true}
                transition={Flip}
                theme={"dark"}
            />
            {/*Routes는 URL 경로에서 필요한 페이지를 로딩하기 위해 필요함*/}
            <Routes>
                <Route
                    path="/login"
                    element={
                        <LoginPage
                            nav={nav}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                        />
                    }
                />
                <Route path="/popup/:token" element={<ManagePopup
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    prevInterceptor={prevInterceptor}
                    setPrevInterceptor={setPrevInterceptor}
                />} />
                {/*아래 페이지는 로그인이 되어야만 접근이 가능함*/}
                <Route element={<RequireAuth nav={nav} />}>
                    {/* 관리 페이지(유저 관리, 데이터 관리) 전용 레이아웃*/}
                    <Route element={
                        <ManageTopLayout
                            nav={nav}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            prevInterceptor={prevInterceptor}
                            setPrevInterceptor={setPrevInterceptor}
                            breadcrumbs={breadcrumbs}
                        />
                    } >
                        <Route path="/manage" element={
                            <ManagePage
                                setUserManageInfo={setUserManageInfo}
                            />}
                        />
                        <Route path="/manage/:mbId" element={
                            <DetailUserPage
                                userManageInfo={userManageInfo}
                            />}
                        />
                        <Route path="/manage/data" element={
                            <ManageDataPage
                            />}
                        />
                    </Route>
                    {/* Admin 페이지 레이아웃을 기반으로 상세 페이지를 라우트함*/}
                    <Route
                        element={
                            <AdminTopLayout
                                nav={nav}
                                userInfo={userInfo}
                                setUserInfo={setUserInfo}
                                prevInterceptor={prevInterceptor}
                                setPrevInterceptor={setPrevInterceptor}
                                breadcrumbs={breadcrumbs}
                            />
                        }
                    >
                        {/*페이지별 라우팅*/}
                        <Route path="/" element={<DashboardPage
                            nav={nav}
                        />} />
                        <Route element={<DataManagePage />}>
                            <Route path="/project" element={<ProjectBoard />} />
                            <Route
                                path="/project/:prjId"
                                element={<DatasetBoard />}
                            />
                            <Route
                                path="/project/:prjId/:dtsId"
                                element={<DatasetInfo />}
                            />
                            <Route
                                path="/project/:prjId/:dtsId/file"
                                element={<FileDataManager />}
                            />
                            <Route
                                path="/project/:prjId/:dtsId/viewer"
                                element={<DataViewer />}
                            />
                        </Route>
                        <Route element={<DataProcessPage />}>
                            <Route path="/process" element={<ProcessBoard />} />
                            <Route
                                path="/process/:prcId"
                                element={<ProcessEditor />}
                            />
                        </Route>
                        <Route element={<DataWorkPage />}>
                            <Route
                                path="/dataWork"
                                element={<DataWorkBoard />}
                            />
                            <Route
                                path="/dataWork/:wkId"
                                element={<DataWorkManager />}
                            />
                            <Route
                                path="/dataWork/:wkId/:prtcpUsr"
                                element={<DataWorkHistory />}
                            />
                            <Route
                                path="/dataWork/mntr/:wkId"
                                element={<DataWorkMonitoring />}
                            />
                        </Route>
                    </Route>
                </Route>


                {/*위에서 해당없는 경로들은 모두 404 처리함*/}
                <Route path="/error/403" element={<ErrorPage errorCode={403} />} />
                <Route path="*" element={<ErrorPage errorCode={404} />} />
            </Routes>
        </div>
    );
};

export default App;
