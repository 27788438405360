import React, {useEffect, useRef, useState} from 'react';
import useChartCpu from "./useChartCpu";
import ManageService from "../../service/ManageService";



let useCpuChart = false;

const InteractiveCpu = (props) => {

    const [cpuData, setCpuData] = useState();

    // Mound, UnMount
    useEffect(() => {
        const cpuInterval = setInterval(function(){requestCPUData(cpuInterval)}, 2000);
        useCpuChart = true;
        return () => {
            clearInterval(cpuInterval);
            useCpuChart = false;
        }
    }, []);


    var index = 0;


    const requestCPUData = (cpuInterval) => {
        if(useCpuChart) {
            ManageService.requestCPUData().then((res) => {
                if(res) {
                    setCpuData(res.data);
                    var currentData = res.data.measurements[0].value * 100;

                    for (var i = index; i > 0; i--) {
                        data[i].value = data[i - 1].value;
                    }
                    data[0].value = currentData.toFixed(2);
                    if (index < 9) index += 1;
                }
                else {
                    clearInterval(cpuInterval);
                }
            });
        }
    };

    const canvasRef = useRef(null);
    const [data, setData] = useState({
            0: {
                value: 0,
                isVisible: true
            },

            1: {
                value: 0,
                isVisible: true
            },

            2: {
                value: 0,
                isVisible: true
            },

            3: {
                value: 0,
                isVisible: true
            },

            4: {
                value: 0,
                isVisible: true
            },

            5: {
                value: 0,
                isVisible: true
            },

            6: {
                value: 0,
                isVisible: true
            },

            7: {
                value: 0,
                isVisible: true
            },

            8: {
                value: 0,
                isVisible: true
            },
            9: {
                value: 0,
                isVisible: true
            }
        }
    )

    useChartCpu(canvasRef, {
        type: "line",

        data: {
            labels: Object.keys(data).filter(key => data[key].isVisible),

            datasets: [
                {
                    label: "CPU",

                    data: Object.keys(data)
                        .filter(key => data[key].isVisible)
                        .map(key => data[key].value),

                    backgroundColor: "rgba(255, 99, 132, 0.2)",

                    borderColor: "rgba(255, 99, 132, 1)",

                    borderWidth: 1,
                }
            ]
        },
        options: {
            legend: {
                display: true,
                labels: {
                    fontColor: "#ff6a5d"
                }
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min:0,
                            max:100
                        }
                    }
                ],
            },
            animation: {
                duration: 0
            }
        }
    });

    return (
        <canvas ref={canvasRef} width="400" height="200" />
    );
};

export default InteractiveCpu;
