import React, {useRef, useState} from 'react';
import {Card, Dropdown, Row, Stack} from "react-bootstrap";
import {useParams, useNavigate} from "react-router-dom";
import DataViewTableComponent from "./component/DataViewTableComponent";
import Swal from "sweetalert2";
import LightBtn from "../component/buttons";
import {Title2} from "../component/titles";
import DataService from "../service/DataService";
import {toast} from "react-toastify";
import ReactTooltip from "react-tooltip";

const DataViewer = (props) => {

    const nav = useNavigate();
    const params = useParams();
    const viewTableRef = useRef();

    const dtsId = params.dtsId?params.dtsId:props.dtsId;

    const [dataViewModalOpen, setDataViewModalOpen] = useState(false);
    const [dataViewModalType, setDataViewModalType] = useState("new");

    /**
     * 일괄 처리를 위한 선택 행 (Data)
     */
    const [selectedRows, setSelectedRows] = useState([]);


    const newDataShow = () => {
        if(selectedRows.length === 0) {

        }
        setDataViewModalType("new");
        setDataViewModalOpen(true);
    }

    const deleteRows = ()=>{
        const removeIds = selectedRows.map(d=>d.dataMeta.dtId);

        Swal.fire({
            title: '정말로 삭제하시겠습니까?',
            text: `삭제된 데이터는 복구할 수 없습니다. [데이터 : ${removeIds.length}개]`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'취소',
            confirmButtonText: '삭제'
        }).then((result) => {
            if (result.isConfirmed) {
                DataService.deleteRowData(dtsId, removeIds).then(r =>{
                    if(r.status===200){
                        toast(`삭제 성공 - ${removeIds.length}개 데이터`, {type: "success", autoClose: 2000});
                        viewTableRef.current.reloadData();
                        setSelectedRows([]);

                    }else {
                        toast(`삭제 실패`, {type: "error", autoClose: 2000});
                    }
                });

            }
        })

    }


    return (
        <Card className={props.title?"col-6":""}>
            <Card.Header>
                <Stack direction={"horizontal"}>
                    <Title2>{props.title?props.title:"데이터 뷰어"}</Title2>
                    <Dropdown className="ms-auto">
                        <Dropdown.Toggle className={"btn btn-blue btn-small"} id="dropdown-basic">
                            일괄 처리
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                data-tip data-for="dataViewerUpdate"
                                onClick={() => {
                                //TODO 일괄처리 기능 구현
                                Swal.fire("Not Implemented. ")
                            }}>수정</Dropdown.Item>
                            <ReactTooltip place="left" type="dark" effect="solid" id="dataViewerUpdate">
                                <span>데이터를 선택하고 수정합니다.</span>
                            </ReactTooltip>
                            <Dropdown.Item
                                data-tip data-for="dataViewerDelete"
                                onClick={() => {
                                //TODO 일괄처리 기능 구현
                                deleteRows();
                            }}>삭제</Dropdown.Item>
                            <ReactTooltip place="left" type="dark" effect="solid" id="dataViewerDelete">
                                <span>데이터를 선택하고 삭제합니다.</span>
                            </ReactTooltip>
                        </Dropdown.Menu>
                    </Dropdown>
                    {
                        props.title?null:
                        <>
                            <LightBtn color={"green"} size={"small"}
                                      data-tip data-for="dataViewerAdd"
                                      clickHandler={() => {
                                newDataShow();
                            }}>+ 데이터 추가</LightBtn>
                            <ReactTooltip place="top" type="dark" effect="solid" id="dataViewerAdd">
                                <span>데이터를 추가합니다.</span>
                            </ReactTooltip>
                            <LightBtn
                                color="gray"
                                size="small"
                                data-tip data-for="dataViewerBack"
                                clickHandler={() => {
                                    nav(-1)
                                }}
                            >목록</LightBtn>
                        </>
                    }
                    <ReactTooltip place="top" type="dark" effect="solid" id="dataViewerBack">
                        <span>프로세스 목록 페이지로 이동합니다.</span>
                    </ReactTooltip>
                </Stack>
            </Card.Header>
            <Card.Body>
                <Row>
                    <DataViewTableComponent
                        className={props.className}
                        ref={viewTableRef}
                        perPage={props.perPage?props.perPage:20}
                        dtsId={dtsId}
                        setSelectedRows={setSelectedRows}
                        dataViewModalOpen={dataViewModalOpen}
                        setDataViewModalOpen={setDataViewModalOpen}
                        dataViewModalType={dataViewModalType}
                        rowClick={(row) => {
                            //! modalType은 [ new(새로만들기) / modify(수정) / read(읽기) ] 3가지임.
                            setDataViewModalType("modify");
                            setDataViewModalOpen(true)
                        }}
                        title={props.title}
                        styleType={props.styleType}
                    />
                </Row>
            </Card.Body>


        </Card>
    );
};

export default DataViewer;
