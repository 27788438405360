import React from 'react';
import StepFooter from "./component/StepFooter";
import ManageService from "../page/service/ManageService";
import {Card, Form, Stack} from "react-bootstrap";
import {Title2} from "../page/component/titles";
import Swal from "sweetalert2";
import "./popup.css"

const FieldContent = (props) => {

    const saveField = () => {
        let order;
        if(props.fieldObj.fdParent === "") {
            props.fieldObj.fdParent = null;
        }
        if (props.fieldObj.fdParent != null) {
            for (var i in props.fieldList) {
                var f = props.fieldList[i];
                if (f.fdId === props.fieldObj.fdParent) {
                    if (f.child == null) {
                        order = 1;
                    } else {
                        order = f.child.length + 1;
                    }
                    break;
                }
            }
        } else {
            order = props.fieldList.length + 1
        }

        let field = {};
        field = {
            ...props.fieldObj,
            dtsId: props.dtsId,
            fdOrder: order
        };

        ManageService.saveField(field).then((r) => {
            if (r !== undefined && r.status === 200) {
                props.setFieldObj({
                    fdCd: '',
                    fdType: 'TEXT',
                    fdNm: '',
                    fdFormat: '',
                    fdParent: ''
                });
                props.loadDatasetInfo(props.dtsId);
            } else {
                Swal.fire("저장 실패")
            }

        })
    };

    const resetField = () => {
        props.setFieldObj({
            fdCd: '',
            fdType: 'TEXT',
            fdNm: '',
            fdFormat: '',
            fdParent: ''
        });
    }

    const fdTypeList = [
        "TEXT", "LARGE_TEXT",
        "INTEGER",
        "DATE", "DATE_RANGE",
        "DATETIME", "DATETIME_RANGE",
        "FILE_JSON"
    ];

    /**
     * 데이터 유형
     */
    const fdFormat = {
        "TEXT": "",
        "LARGE_TEXT": "",
        "INTEGER": ["NUMBER", "RANGE"],
        "DATE": "yyyy-MM-dd",
        "DATETIME": "yyyy-MM-dd HH:mm",
        "FILE_JSON": ["IMAGE", "VIDEO", "DOCUMENT", "ETC"]
    }

    return (
        <>
            <Card className={'data-project-list'}>
                <Card.Header>
                    <Stack direction="horizontal" gap={3}>
                        <Title2>필드 정보</Title2>
                    </Stack>
                </Card.Header>
                <Card.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>필드 코드</Form.Label>
                        <Form.Control type="text" value={props.fieldObj.fdCd || ""} style={{"maxWidth":"100%"}}
                                      onChange={(e) => {
                                          props.setFieldObj({...props.fieldObj, fdCd: e.target.value})
                                      }}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>필드 명</Form.Label>
                        <Form.Control type="text" value={props.fieldObj.fdNm || ""} style={{"maxWidth":"100%"}}
                                      onChange={(e) => {
                                          props.setFieldObj({...props.fieldObj, fdNm: e.target.value})
                                      }}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>순서</Form.Label>
                        <Form.Control type="text" value={props.fieldObj.fdOrder || ""} style={{"maxWidth":"100%"}}
                                      onChange={(e) => {
                                          props.setFieldObj({...props.fieldObj, fdOrder: e.target.value})
                                      }}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>데이터 분류</Form.Label>
                        <Form.Select value={props.fieldObj.fdType || ""} style={{"maxWidth":"100%"}}
                                     onChange={(e) => {
                                         props.setFieldObj({...props.fieldObj, fdType: e.target.value})
                                     }}>
                            {
                                fdTypeList.map((e, i) => {
                                    return (
                                        <option key={i} value={e}>{e}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>데이터 형식</Form.Label>
                        {
                            Array.isArray(fdFormat[props.fieldObj.fdType]) ?


                                <Form.Select value={props.fieldObj.fdFormat || ""} style={{"maxWidth":"100%"}}
                                             onChange={(e) => {
                                                 props.setFieldObj({...props.fieldObj, fdFormat: e.target.value})
                                             }}>
                                    {
                                        fdFormat[props.fieldObj.fdType] ? fdFormat[props.fieldObj.fdType].map((e, i) => {
                                            return (
                                                <option key={i} value={e}>{e}</option>
                                            )
                                        }) : ""
                                    }
                                </Form.Select>
                                :
                                <Form.Control type="text" value={props.fieldObj.fdFormat || ""} style={{"maxWidth":"100%"}}
                                              onChange={(e) => {
                                                  props.setFieldObj({...props.fieldObj, fdFormat: e.target.value})
                                              }}/>
                        }

                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>상위 필드</Form.Label>
                        <Form.Select value={props.fieldObj.fdParent || ""} style={{"maxWidth":"100%"}}
                                     onChange={(e) => {
                                         props.setFieldObj({...props.fieldObj, fdParent: e.target.value})
                                     }}>
                            <option value=""></option>
                            {
                                props.fieldList.map((e, i) => {
                                    return (
                                        <option key={i} value={e.fdCd}>{e.fdCd + ":" + e.fdNm}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Card.Body>
            </Card>
            <Card.Footer>
                <StepFooter
                    btnList={[{"Name":"취소", "Step":6}, {"Name":"저장", "Step":6}]}
                    goToStep={props.goToStep}
                    saveHandler={saveField}
                    check={props.fieldObj.fdCd}
                    cancelHandler={resetField}
                    message={"필드 코드 및 필드 명을 입력해주세요."}
                >
                </StepFooter>
            </Card.Footer>
        </>
    );
};

export default FieldContent;