import React, {useEffect, useState} from 'react';
import {Button, Card, Form, ListGroup, Modal, Row, Stack} from "react-bootstrap";
import LightBtn from "../../../component/buttons";
import memoize from "memoize-one";
import DataTableStyle from "../../../config/DataTableStyle";
import DataTable from "react-data-table-component";
import ManageService from "../../../service/ManageService";
import DatasetBuildManageTableComponent from "../DatasetBuildManageTableComponent";
import {toast} from "react-toastify";

/***
 * 프로젝트 관리 -> 데이터셋 가져오기 모달
 * ***/

const DatasetBuildManageModal = ({show, hide, prjId, copyMultipleDataset, reloadDataset}) => {

    //활용 가능 데이터 셋 객체
    const [utilizationData, setUtilizationData] = useState({});

    //가져오기 데이터 셋 리스트(data table)
    const [datasetList, setDatasetList] = useState([]);

    const loadUtlzData = () => {
        ManageService.getUtilizationData(prjId).then(r => {
            if (r !== undefined && r.status === 200){
                if (r.data !== undefined)
                    setUtilizationData(r.data.utlzDataset);
            }else{

            }
        })
    }

    const addDatasetList = (newItem) =>{
        setDatasetList([...datasetList, newItem])
        console.log(datasetList)
    }

    const addNewDatasetList = () => {
        if (utilizationData !== undefined){
            const prj = Object.values(utilizationData)[0]
            const datasetListItem = {prjId : prj.prjId,
                dtsList: prj.dtsList,
                dtsId: prj.dtsList !== undefined ? prj.dtsList[0].dtsId : undefined,
                type: 'share',
                ownedDts: prj.dtsList !== undefined ? prj.dtsList[0].ownedDts:false}
            datasetListItem.type = datasetListItem.ownedDts ? 'copy':'share';
            addDatasetList(datasetListItem)
        }
    }

    const onHide = () => {
        hide();
        setDatasetList([]);
    }

    const getMultipleDataset = (reqVo) => {
        const toast_id = toast.loading("데이터셋을 가져오는 중입니다.")
        copyMultipleDataset(reqVo, {
            onUploadProgress: p => {
                const progress = p.loaded / p.total;
                toast.update(toast_id, { render:progress });
            }
        }).then(r => {
            if (r !== undefined && r.status === 200){
                const sucessArr = Object.values(r.data.processingResult).filter(d => d)
                if (r.data.performanceResult)
                    toast.update(toast_id, { render: "데이터 셋 가져오기 성공", type: "success",autoClose: 2000, isLoading: false })
                else
                    toast.update(toast_id, { render: `데이터 셋 가져오기 실패, ${sucessArr.length}/${Object.values(r.data.processingResult).length}건 완료`, type: "error",autoClose: 2000, isLoading: false })
            }else{
                toast.update(toast_id, { render: `데이터 셋 가져오기 실패`, type: "error",autoClose: 2000, isLoading: false });
            }
            onHide();
            reloadDataset();
        });
    }

    useEffect( ()=>{
        loadUtlzData();
    }, [])


    return (
        <Modal
            enforceFocus={false}
            show={show} onHide={onHide}
        size={"xl"}>
            <Modal.Header closeButton>
                <Modal.Title>데이터셋 가져오기</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <DatasetBuildManageTableComponent datasetList={datasetList} setDatasetList={setDatasetList} utilizationData={utilizationData}></DatasetBuildManageTableComponent>
                </Row>
                <Row>
                    <Stack className={"col-md-5 mx-auto data-list-add"}>
                        <Button variant={"secondary"} onClick={() =>{
                            addNewDatasetList();
                        }}>+</Button>
                    </Stack>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={onHide}>
                    취소
                </LightBtn>
                <LightBtn color={"green"} size={"middle"} clickHandler={() => {
                    getMultipleDataset({'datasetList': datasetList, 'domianPrjId': prjId})
                }}>
                    완료
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default DatasetBuildManageModal;