import React, {useEffect, useState} from 'react';
import ManageService from "../../page/service/ManageService";
import CurrentFileData from "./component/CurrentFileData";
import CurrentDatasetInfo from "./component/CurrentDatasetInfo";
import CurrentFieldData from "./component/CurrentFieldData";
import UserManageService from "../../page/service/UserManageService";

const DetailUserPage = ({userManageInfo}) => {

    let dataCapacity = 50 * 1024 * 1024 * 1024;

    // 선택한 데이터셋 ID
    const [dtsId, setDtsId] = useState();
    // 프로젝트 Dict
    const [projectD, setProjectD] = useState({});
    // 데이터 최대 용량
    const [dataUsage, setDataUsage] = useState(0);
    // 데이터셋 목록
    const [datasetList, setDatasetList] = useState([]);
    // 선택한 데이터셋 순서
    const [dtIndex, setDtIndex] = useState(0);
    // 프로젝트 목록
    const projectList = userManageInfo.projectList;
    // 멤버 정보
    const memberInfo = userManageInfo.member;
    // 파일리스트
    const [files, setFiles] = useState([]);

    useEffect(() => {
        loadDataSizeInfo();
        setProjectDict();
        loadDatasetList();
    }, []);

    const loadDatasetList = () => {
        UserManageService.loadUserDatasetList(memberInfo.mbUsername).then((r) => {
            if (r !== undefined && r.status === 200) {
                var data = r.data;
                setDatasetList(data);
            }
        });
    };

    const loadDataSizeInfo = () => {
        var username = memberInfo.mbUsername;
        ManageService.getDashBoard(username).then((r) => {
            var data = r.data;
            var dataSize = data.userDataSize;
            setDataUsage(dataSize);
        });
    };

    const setProjectDict = () => {
        var projectDict = {};
        for (var i in projectList) {
            var prj = projectList[i].prj;
            var prjId = prj.prjId;
            var prjNm = prj.prjNm;

            projectDict[prjId] = prjNm;
        }

        setProjectD(projectDict);
    };

    return (
        <>
            <CurrentDatasetInfo
                memberInfo={memberInfo}
                projectD={projectD}
                setDtsId={setDtsId}
                datasetList={datasetList}
                dataUsage={dataUsage}
                dataCapacity={dataCapacity}
                size={5}
                setDtIndex={setDtIndex}
                setFiles={setFiles}
            />
            <div className={'mt-4 flexGrid-2'}>
                <CurrentFileData
                    dtsId={dtsId}
                    datasetField={datasetList.length!==0?datasetList[dtIndex]["tbFieldsByDtsId"]:null}
                    files={files}
                    setFiles={setFiles}
                />
                <CurrentFieldData
                    dtsId={dtsId}
                />
            </div>
        </>
    );
};

export default DetailUserPage;
