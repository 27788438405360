import React, {useEffect, useState} from 'react';
import {Card, Form, ListGroup, Stack} from "react-bootstrap";
import LightBtn from "../../../component/buttons";
import Swal from "sweetalert2";

const AddFieldSelectorTool = ({
                            localProperty,
                            targetField,
                            precdTask,
                            node,
                            value,
                            onChange
                        }) => {

    const [selectors, setSelectors] = useState([]);

    useEffect(()=>{
        if(value) {
            setSelectors(JSON.parse(value))
        }
    },[])

    useEffect(() => {
        onChange(JSON.stringify(selectors))
    }, [selectors]);


    const [newSelector, setNewSelector] = useState(undefined);


    const deleteSelector = ()=>{
        const result = selectors.filter(s => s.fieldName !== newSelector.fieldName);
        setSelectors([...result]);
        setNewSelector(undefined);
    };

    const saveSelector = ()=>{
        if(newSelector&& newSelector.name===""){
            Swal.fire("필터 이름을 입력해주세요.");

            return;
        }
        let isChange = false;
        for(let idx in selectors){
            if(selectors[idx].fieldName === newSelector.fieldName){
                selectors[idx]=newSelector;
                isChange=true;
            }
        }
        if(isChange)
        {
            setSelectors([...selectors]);
        }else{
            setSelectors([...selectors,
                newSelector]);
        }

        setNewSelector(undefined);
    };

    if(!precdTask){
        return <p>선행노드가 없습니다.</p>
    }else if(!precdTask.lastTest){
        return <p>선행 노드를 먼저 테스트해주세요.</p>
    }

    return (

        <Card>
            <Card.Header>
                <Stack direction="horizontal" gap={3}>
                    <div>Fields</div>
                    <div className="ms-auto">
                        <LightBtn  size="sm" color={"green"} size={"sm"} clickHandler={() => {
                            setNewSelector(
                                {
                                    userName: sessionStorage.authenticatedUser,
                                    targetDataset: precdTask.taskDef.localProperty.targetDataset,
                                    fieldCode: "",
                                    fieldName: "",
                                    fieldType: "TEXT",
                                }
                            )
                        }}>추가</LightBtn>
                    </div>
                </Stack>
            </Card.Header>
            <Card.Body className="border-bottom pb-1">
                {
                    newSelector!==undefined?<div>
                        <Form.Group className="mb-3">
                            <Form.Label>필드 코드 (필수)</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="Field Code"
                                value={newSelector.fieldCode ? newSelector.fieldCode : ""}
                                onChange={(e) => {
                                    setNewSelector({
                                        ...newSelector,
                                        fieldCode:e.target.value.toUpperCase()
                                    })
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>필드 이름 (필수)</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="Field Name"
                                value={newSelector.fieldName ? newSelector.fieldName : ""}
                                onChange={(e) => {
                                    setNewSelector({
                                        ...newSelector,
                                        fieldName:e.target.value
                                    })
                                }}
                            />
                        </Form.Group>
                        <Form.Label>필드 분류</Form.Label>
                        <Form.Select
                            size="sm"
                            onChange={(e) => {
                                setNewSelector({
                                    ...newSelector,
                                    fieldType:e.target.value
                                })
                            }}
                        >
                            <option value={"TEXT"} selected={newSelector.fieldType === "TEXT"}>{"TEXT"}</option>
                            <option value={"LARGE_TEXT"} selected={newSelector.fieldType === "LARGE_TEXT"} >{"LARGE_TEXT"}</option>
                            <option value={"INTEGER"} selected={newSelector.fieldType === "INTEGER"}>{"INTEGER"}</option>
                            <option value={"DATE"} selected={newSelector.fieldType === "DATE"}>{"DATE"}</option>
                            <option value={"DATETIME"} selected={newSelector.fieldType === "DATETIME"}>{"DATETIME"}</option>
                            <option value={"FILE"} selected={newSelector.fieldType === "FILE"}>{"FILE"}</option>
                        </Form.Select>

                        <LightBtn clickHandler={saveSelector} color={"blue"} size={"sm"}>저장</LightBtn>
                        <LightBtn className={"ms-2"} clickHandler={()=>{deleteSelector()}} color={"red"} size={"sm"}>삭제</LightBtn>
                    </div>:null
                }
            </Card.Body>
            <ListGroup variant="flush" className={"mt-1"}>
                {selectors.map((s, i) => {
                    return <Card key={s}>
                        <Card.Body className={"bg-transparent border-1 border-secondary p-1"}>
                            <Stack direction="horizontal" gap={3}>
                                <div>{s.fieldName}</div>
                                <div className="ms-auto">
                                    <LightBtn
                                        clickHandler={()=>{
                                            setNewSelector(s)
                                        }
                                        }
                                        color={"outline-warning"} size={"sm"}>수정</LightBtn>
                                </div>
                            </Stack>
                        </Card.Body>
                    </Card>;
                })
                }

            </ListGroup>
        </Card>
    );
};

export default AddFieldSelectorTool;
