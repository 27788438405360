const commonStyle = {
    tableWrapper: {
        style: {
            // overflowX: "auto",
            display: "block",
            '&::-webkit-scrollbar':{
                background: "transparent",
            },
            '& &':{
                overflowX:'auto',
            },
        }
    },
    table: {
        style: {
            border: 'none',
            borderBottom: '1px solid #405284',
            background: 'transparent',
        },
    },
    headRow: {
        style: {
            minHeight: '35px',
            margin: '0',
            border: 'none',
            borderBottom: '1px solid #405284',
            background: 'transparent',
        },
    },
    headCells: {
        style: {
            color: '#CED1F2',
            fontSize: '13px',
            borderBottom: '1px solid #405284',
            marginBottom: '-1px',
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
    rows: {
        style: {
            minHeight: '30px',
            background: 'transparent',
            border: 'none !important',
            '&:nth-child(odd) .rdt_TableCell': {
                background: 'rgba(0, 0, 0, 0.1)',
                borderBottom: '1px solid transparent',
            },
            '&:nth-child(even) .rdt_TableCell': {
                background: 'rgba(0, 0, 0, 0)',
                borderBottom: '1px solid transparent',
            },
            '&:hover .rdt_TableCell': {
                background: 'rgba(255,255,255,0.1)',
                borderBottom: '1px solid #70707E',
                outline: 'none',
            }
        },
        highlightOnHoverStyle: {
            background: 'rgba(255,255,255,0.1)',
            borderBottom: '1px solid #70707E',
            outline: 'none',
        },
        selectedHighlightStyle: {
            '&:nth-of-type(n)': {
                background: 'none',
                border: 'none',
            },
            '&:nth-of-type(n) .rdt_TableCell': {
                background: 'rgba(255,255,255,0.2)',
                borderBottom: '1px solid #70707E',
            },
        },
    },
    cells: {
        style: {
            color: '#eee',
            justifyContent: 'center',
            padding: '6px',
        }
    },
    expanderRow: {
        style: {
            background: 'rgba(0, 0, 0, 0.2)',
            borderTop: '1px solid #242D46',
        },
    },
    expanderButton: {
        style: {
            color: '#eee',
            fill: '#eee',
            backgroundColor: 'transparent !important',
            transition: '0.2s',
            height: '100%',
            width: '100%',
            '&:hover:enabled': {
                cursor: 'pointer',
            },
            '&:disabled': {
                color: 'transparent',
                position: 'relative',
            },
            '&:disabled::after': {
                position: 'absolute',
                content: '""',
                top: 'calc(50% - 1px)',
                left: 'calc(50% - 3px)',
                width: '6px',
                height: '2px',
                borderRadius: '2px',
                background: 'rgba(255, 255, 255, 0.4)',
            }
        },
    },
    pagination: {
        style: {
            border: 'none',
            background: 'transparent',
            justifyContent: 'center',
            color: '#fff',
            '& select': {
                background: '#2A2743',
            },
            '& option': {
                color: '#bbb',
            }
        },
        pageButtonsStyle: {
            fill: '#fff',
            borderRadius: '5px',
            width: '30px',
            height: '30px',
            padding: '0',
            '&:disabled': {
                fill: '#fff',
                opacity: '0.3',
            },
            '&:hover:not(:disabled)': {
                border: '1px solid #405284',
            },
        },
    },
    noData: {
        style: {
            color: '#9ca0c9',
            background: 'transparent',
            borderRadius: '5px',
            border: '1px solid #474a68',
        },
    },
    progress: {
        style: {
            color: '#9ca0c9',
            fontSize: '16px',
            fontWeight: '500',
            background: 'transparent',
            borderRadius: '5px',
            border: '1px solid #474a68',
        },
    },
}

const popupStyle = {
    tableWrapper: {
        style: {
            overflowX: "auto",
            display: "block",
            '&::-webkit-scrollbar':{
                background: "transparent",
            },
            '&::-webkit-scrollbar-thumb':{
                background: "#ddd",
            },
            '& &':{
                overflowX:'auto',
            },
        }
    },
    table: {
        style: {
            border: 'none',
            borderBottom: '1px solid #eee',
            background: 'transparent',
        },
    },
    headRow: {
        style: {
            minHeight: '40px',
            margin: '0',
            border: 'none',
            borderBottom: '1px solid #eee',
            background: '#FAFAFA',
        },
    },
    headCells: {
        style: {
            color: '#777',
            fontSize: '16px',
            '& > *':{
                fontFamily: '"SCDream5"',
                fontWeight:'600',
            },
            borderBottom: '1px solid #eee',
            marginBottom: '-1px',
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
    rows: {
        style: {
            minHeight: '40px',
            background: '#fff',
            border: 'none',
            borderTop: '1px solid #eee',
            borderBottom: '1px solid #eee',
        },
        highlightOnHoverStyle: {
            background: '#f9fcff',
            borderTop: '1px solid #eee',
            borderBottom: '1px solid #eee',
        },
        selectedHighlightStyle: {
            '&:nth-of-type(n)': {
                background: '#f9fcff',
            },
            '&:nth-of-type(n) .rdt_TableCell': {
                background: '#f9fcff',
            },
        },
    },
    cells: {
        style: {
            fontSize: '16px',
            color: '#333',
            justifyContent: 'center',
            padding: '6px',
        }
    },
    expanderRow: {
        style: {
            color: '#333',
            borderTop: '1px solid #eee',
            borderBottom: '1px solid #ccc',
            '& .rdt_Table':{
                border: "none",
            },
            '& .rdt_TableRow':{
                borderBottom: '1px solid #eee !important',
            },
            '& .rdt_TableCell':{
                color: "#333",
                fontSize: "16px",
                border: "none !important",
                background: "transparent !important",
            },
            '& .rdt_TableRow:hover .rdt_TableCell':{
                border: "none",
            },
        },
    },
    expanderCell: {
        style: {
            borderTop: '0',
        },
    },
    expanderButton: {
        style: {
            color: '#333',
            fill: '#333',
            backgroundColor: 'transparent',
            height: '100%',
            width: '100%',
            '&:hover:enabled': {
                cursor: 'pointer',
            },
            '&:disabled': {
                color: 'transparent',
                position: 'relative',
            },
            '&:disabled::after': {
                position: 'absolute',
                content: '""',
                top: 'calc(50% - 1px)',
                left: 'calc(50% - 3px)',
                width: '6px',
                height: '2px',
                borderRadius: '2px',
                background: '#ddd',
            }
        },
    },
    pagination: {
        style: {
            border: 'none',
            background: 'transparent',
            justifyContent: 'center',
            color: '#121212',
            '& select': {
                background: '#f5f5f5',
            },
            '& option': {
                color: '#121212',
            }
        },
        pageButtonsStyle: {
            fill: '#121212',
            borderRadius: '5px',
            width: '30px',
            height: '30px',
            padding: '0',
            '&:disabled': {
                fill: '#ddd',
            },
            '&:hover:not(:disabled)': {
                background:'#eee',
            },
        },
    },
    noData: {
        style: {
            color: '#777',
            background: 'transparent',
            borderRadius: '5px',
            border: '1px solid #ddd',
        },
    },
    progress: {
        style: {
            color: '#777',
            fontSize: '16px',
            fontWeight: '500',
            background: 'transparent',
            borderRadius: '5px',
            border: '1px solid #ddd',
        },
    },
}


class DataTableStyle {
    static customStyles = commonStyle;
    static compactGrid = commonStyle;
    static smCompactGrid = commonStyle;
    static popupStyle = popupStyle;
}


export default DataTableStyle;
