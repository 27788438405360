import React from 'react';
import {Form, ListGroup} from "react-bootstrap";

const TaskInputParamTable = ({selected, setSelected, params}) => {

    return (
        <ListGroup>
            {
                //= input params 중 #이 붙은 내용은 모두 숨김 처리함 ( 시스템용 )
                params ? params.filter(p=>!p.includes('#')).map((p) => (
                    <ListGroup.Item key={`input-params-${p}`}>
                        <Form.Check
                            type={'checkbox'}
                            id={`input-params-${p}`}
                            label={`${p}`}
                            checked={selected.includes(p)}
                            onChange={(e) =>{
                                if(e.target.checked){
                                    setSelected([
                                        ...selected,
                                        p
                                    ]);
                                }else {
                                    setSelected(selected.filter(s=>s!==p));
                                }
                            }}
                        />
                    </ListGroup.Item>
                )) : null
            }
        </ListGroup>
    );
};

export default TaskInputParamTable;
