import React, {useEffect, useState} from 'react';
import DataTableStyle from "../../../config/DataTableStyle";
import DataTable from "react-data-table-component";
import {Card} from "react-bootstrap";
import memoize from "memoize-one";


const TASK_OUTPUT_COLUMNS =  memoize(() =>[
    {
        name: '파라미터',
        selector: row => row.name,
    },
]);

const TaskOutputParamTable = ({lastTest}) => {

    const [data, setData] = useState([]);



    useEffect(() => {
        if (Object.keys(lastTest).length !== 0 && lastTest && lastTest.node.outputParams) {
            const outputParam =  lastTest.node.outputParams.filter(p=>!p.includes('#')).map(p=>{
                return {
                    name:p
                }
            })
            setData(outputParam);
        }else{
            setData([])
        }
    }, [lastTest]);



    return (
        <Card.Body>
            <DataTable
                columns={TASK_OUTPUT_COLUMNS()}
                data={data}
                customStyles={DataTableStyle.smCompactGrid}
                pagination
            />
        </Card.Body>
    );
};

export default TaskOutputParamTable;
