import React from 'react';
import {Link} from "react-router-dom";

const SummaryList = (props) => {
    return (
        <Link to={props.link}>
            <h4>{props.ttl}</h4>
            <ul className="dashboard-summary-count">
                <li>
                    <div>{props.list1}</div>
                    <div>
                        <span className="dashboard-owned-project">{props.count1}</span>
                        {props.unit1}
                    </div>
                </li>
                <li>
                    <div>{props.list2}</div>
                    <div>
                        <span className="dashboard-owned-data">{props.count2}</span>
                        {props.unit2}
                    </div>
                </li>
            </ul>
        </Link>
    );
};

export {SummaryList} ;