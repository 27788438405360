import React from 'react';

let nodeDefs = undefined;

class GraphService {


    static initNodeDef(nodeDefList) {
        nodeDefs = {}

        nodeDefList.forEach(n => {
            nodeDefs[n.nodeName] = n;
        })
    }


    /**
     * = NodeType으로 Node 정보를 가져옴
     * @param type
     * @returns {*}
     */
    static getNodeDefByNodeType(type) {
        if (!nodeDefs) {
            console.log("not inited yet.")
            return null;
        }else if(!nodeDefs[type]){
            console.log(`type not found : ${type}`)
            return null;
        }
        else {
            return nodeDefs[type];
        }
    }

    /**
     * = TaskList를 통해 Node와 Edge를 작성하여 반환함
     * @param tasks
     * @returns {([{data: {label: JSX.Element}, id: string, position: {x: number, y: number}, type: string}]|*[])[]}
     */
    static makeNodeEdgeByTask(tasks) {
        //= Node 작성
        const nodes = [{
            id: `0`, //! StartNode는 id : 0 을 가짐
            type: 'input',
            data: {
                label: (
                    <strong>Start</strong>
                )
            },
            position: {x: 0, y: 0},
        }]
        tasks.forEach((t, i) => {
            if (t) {
                nodes.push({
                    id: `${t.taskId}`,
                    type: 'DF_NODE',
                    data: {
                        taskId: t.taskId,
                        label: (
                            <strong>{t.taskNm}</strong>
                        )
                    },
                    position: {x: 0, y: (i + 1) * 72},
                })
            }
        })

        //= Edge 작성
        const edges = [];

        tasks.forEach(t => {
            if (t && t.precdTaskId !== undefined && t.precdTaskId !== null)
                edges.push({
                    id: `e_${t.precdTaskId}-${t.taskId}`,
                    source: `${t.precdTaskId}`,
                    target: `${t.taskId}`,
                    type: 'step',
                    animated: true,
                    label: '',
                },)
        })
        return [nodes, edges]
    }

    /**
     * = Node, Edge를 통해 TaskList를 작성함
     * @param prcId
     * @param nodes
     * @param edges
     * @returns {*[]}
     */
    static makeTaskFromNodeEdge(prcId, nodes, edges) {
        let edgeMap = {}
        edges.forEach(e => {
            edgeMap[e.target] = e.source;
        })

        const tasks = [];
        nodes.forEach(n => {
            if (n.id === '0') return; //! Start는 저장하지 않음
            tasks.push({
                ...n.data.meta,
                precdTaskId: edgeMap[n.id]
            })
        })

        return tasks;
    }
}

export default GraphService;
