import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Card, Row, Stack, Dropdown, DropdownButton} from "react-bootstrap";
import DataTableStyle from "../config/DataTableStyle";
import DataTable from "react-data-table-component";
import ManageService from "../service/ManageService";
import DatasetMakerModal from "./component/DatasetMakerModal";
import Swal from "sweetalert2";
import {Title2, Title3} from "../component/titles";
import LightBtn from "../component/buttons";
import BoxBlack from "../component/Boxes";
import manageService from "../service/ManageService";
import DatasetShareModal from "./component/modal/DatasetShareModal";
import DatasetCopyModal from "./component/modal/DatasetCopyModal";
import "./DatasetBoard.css"
import memoize from "memoize-one";
import ProjectRePermissionModal from "./component/modal/ProjectRePermissionModal";
import DataShareUserComponent from "./component/DataShareUserComponent";
import DatasetBuildManageModal from "./component/modal/DatasetBuildManageModal";
import DatasetService from "../service/DatasetService";
import ReactTooltip from "react-tooltip";
import TextUtilService from "../../component/service/TextUtilService";




const DATASET_COLUMNS = memoize((nav, params,
                                 setDtsId, newShareOpen,
                                 setPrjId, newCopyOpen,
                                 reloadDataset, setDatasetObj, setIsOpenNewModal) => [
    {
        name: '번호',
        maxWidth: '50px',
        center: true,
        selector: row => row.dtsId
    },
    {
        name: '데이터셋 명',
        selector: row => row.dtsTitle,
    },
    {
        name: '분류',
        selector: row => row.dtsType,
    },
    {
        name: '크기',
        selector: row => row.dataSize,
    },
    {
        name: '데이터 수',
        selector: row => row.dataCount,
    },
    {
        name: '소유자',
        selector: row => row.crUsr,
    },
    {
        name: '관리',
        button: true,
        minWidth: '150px',
        cell: row => <>
            <LightBtn size={'tiny'} color={'green'} icon={'link'}
                      data-tip data-for="dataSetManage"
                      clickHandler={() => {
                nav(`/project/${params.prjId}/${row.dtsId}`, {state: {dataset: row}})
            }}/>
            <ReactTooltip place="left" type="dark" effect="solid" id="dataSetManage">
                <span>데이터셋 정보 페이지로 이동합니다.</span>
            </ReactTooltip>

            <DropdownButton
                title={""}
            >
                <Dropdown.Item size={'sm'}
                   data-tip data-for="dataSetUpdate"
                   onClick={() => {
                    setDatasetObj({
                        dtsId: row.dtsId,
                        dtsType: row.dtsType,
                        dtsTitle: row.dtsTitle,
                        dtsDscr: row.dtsDscr
                    });
                    setIsOpenNewModal(true);
                }}>수정</Dropdown.Item>
                <ReactTooltip place="left" type="dark" effect="solid" id="dataSetUpdate">
                    <span>데이터셋 이름과 설명을 수정합니다.</span>
                </ReactTooltip>

                {row.prmsnTp !== 'G' ? "" :
                    <Dropdown.Item size={'sm'}
                                   data-tip data-for="dataSetShare"
                                   onClick={() => {
                        setDtsId(row.dtsId)
                        newShareOpen()
                    }}>공유</Dropdown.Item>
                }
                <ReactTooltip place="left" type="dark" effect="solid" id="dataSetShare">
                    <span>다른 사용자와 데이터셋을 공유하고 권한을 설정합니다.</span>
                </ReactTooltip>

                {row.prmsnTp !== 'R' ?
                    <Dropdown.Item size={'sm'}
                                   data-tip data-for="dataSetCopy"
                                   onClick={
                        () => {
                            setPrjId(params.prjId, row.dtsId, row.dtsTitle);
                            newCopyOpen();
                        }
                    }>복사</Dropdown.Item> : ""}
                <ReactTooltip place="left" type="dark" effect="solid" id="dataSetCopy">
                    <span>데이터셋을 복사합니다.</span>
                </ReactTooltip>

                <Dropdown.Item size={'sm'}
                               data-tip data-for="dataSetDelete"
                               onClick={() => {
                    DatasetService.datasetDeleteCheck(row.dtsId).then(r=>{
                        if(r.status===200){
                            Swal.fire({
                                title : '데이터셋을 삭제하시겠습니까?',
                                html : `<p>삭제될 데이터 수 : ${r.data.deleteDataCount}</p>
                                        <p>삭제될 데이터 크기 : ${TextUtilService.bytesToSize(r.data.deleteDataSize)}</p>
                                        <p>삭제될 파일 수 : ${r.data.deleteFilesCount}</p>
                                        <p>삭제될 파일 크기 : ${TextUtilService.bytesToSize(r.data.deleteFilesSize)}</p>
                                `,
                                type : "warning",
                                showCancelButton : true,
                                confirmButtonClass : "btn-danger",
                                confirmButtonText : "예",
                                cancelButtonText : "아니오",
                                closeOnConfirm : false,
                                closeOnCancel : true
                            }).then(result => {
                                if(result.isConfirmed) {
                                    DatasetService.datasetDelete(row.dtsId).then(r=> {
                                        if(r.status===200){
                                            Swal.fire("데이터셋이 삭제되었습니다.")
                                            reloadDataset();
                                        }else {
                                            Swal.fire("데이터셋 삭제를 실패했습니다.")
                                        }
                                    });

                                }
                            })
                        }
                    })
                }}>삭제</Dropdown.Item>
                <ReactTooltip place="left" type="dark" effect="solid" id="dataSetDelete">
                    <span>데이터셋을 삭제합니다.</span>
                </ReactTooltip>
            </DropdownButton>
        </>,

    }
]);


const DatasetBoard = (props) => {
    let params = useParams();

    const [datasetObj, setDatasetObj] = useState({
        dtsType: 'FILE',
        dtsTitle: '',
        dtsDscr: ''
    });

    const [isOpenNewModal, setIsOpenNewModal] = useState(false);

    const [datasetList, setDatasetList] = useState([]);

    const [projectUserInfo, setProjectUserInfo] = useState({});
    const [projectSummary, setProjectSummary] = useState({});

    const reloadDataset = () => {
        reloadProjectSummary();
        // ManageService.getDatasetBoard(params.prjId).then(r => {
        //     if (r !== undefined && r.status === 200) {
        //         var dataList = r.data;
        //         var dataMap = {};
        //         for(var i in dataList) {
        //             var data = dataList[i];
        //             dataMap[data.dtsId] = data;
        //             ManageService.getDatasetInfo(data.dtsId).then(d => {
        //                 dataMap[d.data.dataset.dtsId]["dataSize"] = d.data.totalDataSize;
        //                 setDatasetList(dataList);
        //             });
        //         }
        //         setDatasetList(r.data);
        //     }
        // })
    };

    const reloadProjectUserInfo = () => {
        ManageService.getProjectUser(params.prjId).then(r => {
            if (r !== undefined && r.status === 200) {
                setProjectUserInfo(r.data)
            }
        })
    };


    const reloadProjectSummary = () => {
        ManageService.getProjectSummary(params.prjId).then(r => {
            if (r !== undefined && r.status === 200) {
                setProjectSummary(r.data);
                const dataset = r.data.datasetSummaries.map(d=>{
                    return {
                        ...d.dataset,
                        dataSize: TextUtilService.bytesToSize(d.deleteDataSize+d.deleteFilesSize),
                        dataCount: d.deleteDataCount,
                        prmsnTp: d.datasetUser.prmsnTp
                    }
                });
                dataset.sort((d1, d2) => d2.crDt - d1.crDt);
                setDatasetList(dataset);
            }
        })
    };

    useEffect(() => {
        reloadProjectUserInfo();
        reloadProjectSummary();
        // loadShareProjectUserList();
    }, []);

    /**
     * 공유 관리 모달
     * */
    const [isOpenShareModal, setIsOpenShareModal] = useState(false);

    const newShareClose = () => {
        setIsOpenShareModal(false);
        setShareVo({
            dtsId: 0, strgId: 0
        });
    };
    const newShareOpen = () => setIsOpenShareModal(true);

    /**
     * 데이터셋 복제 모달
     * */
    const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);

    const newCopyClose = () => {
        setShareVo({
            dtsId: 0, shareUser: '', permissionType: ''
        });
        setIsOpenCopyModal(false);
    };
    const newCopyOpen = () => {
        setIsOpenCopyModal(true);
    };

    //데이터셋 가져오기 모달
    const [isOpenDatasetBuildModal, setIsOpenDatasetBuildModal] = useState(false);

    const newDatasetBuildClose = () => {setIsOpenDatasetBuildModal(false)};
    const newDatasetBuildOpen = () => {setIsOpenDatasetBuildModal(true)};


    //공유 기능 vo
    const [shareVo, setShareVo] = useState({dtsId: 0, shareUser: '', permissionType: 'G'});

    const [copyVo, setCopyVo] = useState({dtsId: 0, prjId: 0, shareUser: '', strgId: 0, copyName: ''});

    const setPrjId = (prjId, dtsId, dtsNm) => {
        setCopyVo({...shareVo, prjId: prjId, dtsId: dtsId, copyName: dtsNm, strgId: 1});
    };

    const setDtsId = (dtsId) => {
        setShareVo({...shareVo, dtsId: dtsId, permissionType: 'G'});
    };

    const setRepermission = (prjId, shareUser, perm, setRePermissionVo) => {
        setRePermissionVo({prjId: prjId, shareUser: shareUser, permissionType: perm});
    };

    const setRevoke = (prjId, shareUser) => {
        return {prjId: prjId, shareUser: shareUser}
    };
    useEffect(() => {
        reloadDataset();
        reloadProjectUserInfo();
    }, []);
    const nav = useNavigate();

    return (
        <>
            <div className={"flexGrid-2"}>
                <Card className={'data-project-info'}>
                    <Card.Header>
                        <Stack direction="horizontal">
                            <Title2>프로젝트 정보</Title2>
                        </Stack>
                    </Card.Header>
                    <Card.Body>
                        <BoxBlack>
                            <h4>{projectSummary.project?projectSummary.project.prjNm:""}</h4>
                            <ul>
                                <li>
                                    <Title3>소유자</Title3>
                                    <p>{projectUserInfo.prj?projectUserInfo.crUsr:""}</p>
                                </li>
                                <li>
                                    <Title3>크기</Title3>
                                    <p>{TextUtilService.bytesToSize(projectSummary.deleteDataSize + projectSummary.deleteFilesSize)}</p>
                                </li>
                                <li>
                                    <Title3>설명</Title3>
                                    <p>{projectSummary.project?projectSummary.project.prjDscr:""}</p>
                                </li>
                            </ul>
                        </BoxBlack>
                    </Card.Body>
                </Card>
                <Card className={'data-project-manage'}>
                    <Card.Header>
                        <Stack direction="horizontal">
                            <Title2>공유 유저 관리</Title2>
                            <LightBtn
                                color="gray"
                                size="small"
                                clickHandler={() => {
                                    nav(-1)
                                }}
                            >목록</LightBtn>
                        </Stack>
                    </Card.Header>
                    <Card.Body>
                        <DataShareUserComponent id={params.prjId}
                                                modal={<ProjectRePermissionModal></ProjectRePermissionModal>}
                                                setRepermission={setRepermission} setRevoke={setRevoke}
                                                loadShareList={ManageService.getShareUserProject}
                                                revokeUserPermission={manageService.revokeProject}
                                                infoVo={projectUserInfo}
                                                dataType={true}>
                        </DataShareUserComponent>
                    </Card.Body>
                </Card>

            </div>
            <div className={'mt-4'}>
                <Card className={'data-project-list'}>
                    <Card.Header>
                        <Stack direction="horizontal">
                            <Title2>데이터셋 목록</Title2>
                            <LightBtn
                                color="blue"
                                size="small"
                                data-tip data-for="dataSetGet"
                                clickHandler={() => {
                                    setIsOpenDatasetBuildModal(true);
                                }}>데이터셋 가져오기</LightBtn>
                            <ReactTooltip place="top" type="dark" effect="solid" id="dataSetGet">
                                <span>공유된 프로젝트 중에서 원하는 데이터셋을 가져옵니다.</span>
                            </ReactTooltip>
                            <LightBtn
                                color="green"
                                size="small"
                                data-tip data-for="dataSetAdd"
                                clickHandler={() => {
                                    setIsOpenNewModal(true)
                                }}>+ 데이터셋 추가</LightBtn>
                            <ReactTooltip place="top" type="dark" effect="solid" id="dataSetAdd">
                                <span>새로운 데이터셋을 만듭니다.</span>
                            </ReactTooltip>
                        </Stack>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <DataTable
                                columns={DATASET_COLUMNS(
                                    nav, params,
                                    setDtsId, newShareOpen,
                                    setPrjId, newCopyOpen,
                                    reloadDataset, setDatasetObj, setIsOpenNewModal)}
                                data={datasetList}
                                customStyles={DataTableStyle.customStyles}
                                noDataComponent={<div style={{"padding": "24px"}}>데이터셋이 존재하지 않습니다. 데이터셋을 추가해주세요. </div>}
                                highlightOnHover
                                // pointerOnHover
                                pagination
                            />
                        </Row>
                    </Card.Body>
                </Card>
            </div>
            <DatasetMakerModal prjId={params.prjId} isOpen={isOpenNewModal} setOpen={setIsOpenNewModal}
                               reloadDataset={reloadDataset} datasetObj={datasetObj} setDatasetObj={setDatasetObj}/>
            <DatasetShareModal isOpen={isOpenShareModal} onHide={newShareClose} shareVo={shareVo}
                               setShareVo={setShareVo} reloadDataset={reloadDataset}></DatasetShareModal>
            <DatasetCopyModal isOpen={isOpenCopyModal} onHide={newCopyClose} copyVo={copyVo} setCopyVo={setCopyVo}
                               reloadDataset={reloadDataset}></DatasetCopyModal>
            <DatasetBuildManageModal show={isOpenDatasetBuildModal} hide={newDatasetBuildClose} prjId={params.prjId} copyMultipleDataset={ManageService.datasetMultipleCopy} reloadDataset={reloadDataset}></DatasetBuildManageModal>
            {/*<ProjectRePermissionModal isOpen={isOpenRePermission} onHide={newRePermissionClose} rePermissionVo={rePermissionVo}*/}
            {/*                          setRePermissionVo={setRePermissionVo} reload={loadShareProjectUserList}></ProjectRePermissionModal>*/}
        </>
    );
};

export default DatasetBoard;
