import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import ProcessService from "../../service/ProcessService";
import LightBtn from "../../component/buttons";
import ManageService from "../../service/ManageService";
import {useParams} from "react-router-dom";
import Swal from "sweetalert2";

const AddDatasetModal = (props) => {

    const params = useParams();

    const [projectList, setProjectList] = useState([]);
    const [datasetList, setDatasetList] = useState([]);

    const [processDatasetObj, setProcessDatasetObj] = useState({
        prcId: params.prcId,
        dtsId: null
    });

    useEffect(() => {
        loadProjectList();
    }, []);

    const loadProjectList = () => {
        ManageService.getProjectBoard().then((r) => {
            if (r !== undefined && r.status === 200) {
                setProjectList(r.data);
                loadDatasetList(r.data[0].prjId);
            }
        })
    };

    const loadDatasetList = (prjId) => {
        ManageService.getDatasetBoard(prjId).then((r) => {
            if (r !== undefined && r.status === 200) {
                setDatasetList(r.data);
                if(r.data.length !== 0) {
                    setProcessDatasetObj({...processDatasetObj, dtsId:r.data[0].dtsId});
                }
            }
        })
    };

    const saveProcessDataset = () => {
        ProcessService.saveProcessDataset(processDatasetObj).then((r) => {
            if (r !== undefined && r.status === 200) {
                setProcessDatasetObj({
                    prcId: params.prcId,
                    dtsId: null
                });
                props.setOpen(false);
                props.reloadProcessDataset();
            } else {

            }

        })
    };

    return (
        <Modal
            enforceFocus={false}
            show={props.isOpen} onHide={() => {
            props.setOpen(false)
            loadDatasetList(projectList[0].prjId);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>데이터셋 추가</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>대상 프로젝트 정보</Form.Label>
                    <Form.Select
                                 onChange={(e) => {
                                     loadDatasetList(e.target.value);
                                 }}>
                        {
                            projectList.map((e, i) => {
                                return (
                                    <option key={i} value={e.prjId}>{e.prj.prjNm}</option>
                                )
                            })
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>대상 데이터셋 정보</Form.Label>
                    <Form.Select value={processDatasetObj.dtsId}
                                 onChange={(e) => {
                                     setProcessDatasetObj({...processDatasetObj, dtsId:e.target.value});
                                 }}>
                        {
                            datasetList.map((e, i) => {
                                return (
                                    <option key={i} value={e.dtsId}>{e.dts.dtsTitle}</option>
                                )
                            })
                        }
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn size={'middle'} color={'red'} clickHandler={() => {
                    props.setOpen(false)
                    loadDatasetList(projectList[0].prjId);
                }}>
                    취소
                </LightBtn>
                <LightBtn size={'middle'} color={'green'} clickHandler={()=>{
                    if(datasetList.length != 0) {
                        saveProcessDataset();
                        loadDatasetList(projectList[0].prjId);
                    }
                    else {
                        Swal.fire("선택된 데이터셋이 없습니다.")
                    }
                }}>
                    추가
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default AddDatasetModal;
