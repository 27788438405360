import React, {useEffect, useState} from 'react';
import {Card, Stack} from "react-bootstrap";
import DataTableStyle from "../config/DataTableStyle";
import DataTable from "react-data-table-component";
import {useNavigate, useParams} from "react-router-dom";
import DataWorkService from "../service/DataWorkService";
import memoize from "memoize-one";
import Swal from "sweetalert2";
import LightBtn from "../component/buttons";
import {Title2, Title3} from "../component/titles";
import DonutCircle from "../component/DonutCircle";
import ReactTooltip from "react-tooltip";
import WorkPrtcpModalComponent from "./component/WorkPrtcpModalComponent";

const columns = memoize((nav, params, DataWorkService, reloadBoard, roleList) => [
    {
        name: '번호',
        maxWidth: '50px',
        center: true,
        selector: row => row.wkId
    },
    {
        name: '소유자',
        selector: row => row.prtcpUsr,
    },
    {
        name: '승인 여부',
        selector: row => row.prtcpAcpt,
    },
    {
        name: '권한',
        selector: row => row.prtcpRole,
        // cell: row =>{
        //     if(row.prmsnTp==="G")
        //         return "G (모든 권한)";
        //     if(row.prmsnTp==="R")
        //         return "R (읽기)";
        //     if(row.prmsnTp==="W")
        //         return "W (쓰기)";
        //
        //     return row.prmsnTp;
        // }
    },
    {
        name: '작업 횟수',
        selector: row => row.prtcpCnt,
    },
    {
        name: '관리',
        button: true,
        minWidth: '180px',
        cell: row => <>
            <LightBtn size={'tiny'} color={"blue"}
                      data-tip data-for="roleAuth"
                      clickHandler={() => {
                Swal.fire({
                    title: '역할',
                    input: 'select',
                    inputOptions: roleList,
                    showCancelButton: true,
                    confirmButtonText: '승인',
                    showLoaderOnConfirm: true,
                    preConfirm: (role) => {
                        DataWorkService.acceptWorkPrtcpUser(row.wkId, row.prtcpUsr, role).then(() => {
                            reloadBoard();
                        });
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                })
            }}>승인</LightBtn>
            <ReactTooltip place="top" type="dark" effect="solid" id="roleAuth">
                <span>모니터링 작업 참여자의 역할을 수정 및 승인합니다. </span>
            </ReactTooltip>
            <LightBtn size={'tiny'} color={"red"}
                      data-tip data-for="roleAuth2"
                      clickHandler={() => {
                // TODO 진짜 추방할건지 다시 한번 확인할 창 추가 필요
                Swal.fire({
                    title: '추방 사유',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: '추방',
                    showLoaderOnConfirm: true,
                    preConfirm: (cause) => {
                        // setCause(cause);
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        DataWorkService.deleteWorkPrtcpUser(row.wkId, row.prtcpUsr).then(() => {
                            reloadBoard();
                        });
                    }
                })
            }}>추방</LightBtn>
            <ReactTooltip place="top" type="dark" effect="solid" id="roleAuth2">
                <span>모니터링 작업에서 참여자를 추방합니다.</span>
            </ReactTooltip>
            <LightBtn size={'tiny'} color={"green"} icon={"link"}
                      data-tip data-for="monitorWorkManage"
                      clickHandler={() => {
                nav(`/dataWork/${params.wkId}/${row.prtcpUsr}`);
            }}/>
            <ReactTooltip place="top" type="dark" effect="solid" id="monitorWorkManage">
                <span>참여한 작업 이력을 확인합니다.</span>
            </ReactTooltip>
        </>
    }
]);

const DataWorkManager = () => {

    //TODO 아래 두 값은 DB에서 가져오기 (for 도넛그래프)
    // let dataCapacity = 60;
    // let dataUsage = 23;

    const nav = useNavigate();
    // const [pp] = useOutletContext();
    const params = useParams();

    const [workUserList, setWorkUserList] = useState([]);
    const [dataCapacity, setDataCapacity] = useState(0);
    const [dataUsage, setDataUsage] = useState(0);
    const [show, setShow] = useState(false);
    const [workPrtcp, setWorkPrtcp] = useState({
        wkId: params.wkId,
        prtcpUsr: "",
        prtcpAcpt: "대기",
    });

    // 역할 리스트
    const roleList = {
        "승인권한자": "승인권한자",
        "참여자": "참여자"
    };

    const reloadBoard = () => {
        DataWorkService.getWork(params.wkId).then((r) => {
            if (r !== undefined && r.status === 200) {
                setDataCapacity(r.data.wkCount);
            }
        });

        DataWorkService.getWorkHistory(params.wkId).then((r) => {
            if (r !== undefined && r.status === 200) {
                setDataUsage(r.data.length);
            }
        })

        DataWorkService.getWorkPrtcpUser(params.wkId).then((r) => {
            if (r !== undefined && r.status === 200) {
                setWorkUserList(r.data);
            }
        })
    };

    useEffect(() => {
        reloadBoard();
    }, []);

    const onHide = () => {
        setShow(false);
    };
    return (
        <>
            <Card>
                <Card.Header>
                    <Stack direction={"horizontal"}>
                        <Title2>데이터 모니터링 작업 관리</Title2>
                        <LightBtn size={"small"} color={"green"}
                                  clickHandler={() => {
                                      setShow(true);
                                  }}>작업자 추가</LightBtn>
                        <LightBtn size={"small"} color={"green"}
                                  data-tip data-for="monitorWorkHistory"
                                  clickHandler={() => {
                            nav(`/dataWork/${params.wkId}/all`);
                        }}>전체 작업 이력</LightBtn>
                        <ReactTooltip place="top" type="dark" effect="solid" id="monitorWorkHistory">
                            <span>모니터링 전체 작업 이력 페이지로 이동합니다.</span>
                        </ReactTooltip>
                        <LightBtn
                            color="gray"
                            size="small"
                            clickHandler={() => {
                                nav(-1)
                            }}
                        >목록</LightBtn>
                    </Stack>
                </Card.Header>
                <Card.Body>
                    <div className={"flexGrid-2"}>

                        <div style={{flex:"0.3", minWidth: "302px"}}>
                            <Card>
                                <Card.Header>
                                    <Title3>진행률</Title3>
                                </Card.Header>
                                <Card.Body>
                                    <DonutCircle
                                        type={"sub"}
                                        dataUsage={dataUsage}
                                        dataCapacity={dataCapacity}
                                    />

                                    {/*<Row>*/}
                                    {/*    <WorkProcessComponent*/}
                                    {/*        text={"총 작업량\n100"}*/}
                                    {/*    />*/}
                                    {/*    <WorkProcessComponent*/}
                                    {/*        text={"진행 작업량\n57"}*/}
                                    {/*    />*/}
                                    {/*    <WorkProcessComponent*/}
                                    {/*        text={"잔여 작업량\n43"}*/}
                                    {/*    />*/}
                                    {/*    <WorkProcessComponent*/}
                                    {/*        text={"진행률\n57%"}*/}
                                    {/*    />*/}
                                    {/*</Row>*/}
                                </Card.Body>

                            </Card>
                        </div>

                        <div>
                            <Card>
                                <Card.Header>
                                    <Stack direction="horizontal" gap={3}>
                                        <Title3>참여자 목록</Title3>
                                    </Stack>
                                </Card.Header>
                                <Card.Body>
                                    <DataTable
                                        columns={columns(nav, params, DataWorkService, reloadBoard, roleList)}
                                        data={workUserList}
                                        customStyles={DataTableStyle.customStyles}
                                        highlightOnHover
                                        // pointerOnHover
                                        pagination
                                    />
                                </Card.Body>
                            </Card>
                        </div>

                    </div>
                </Card.Body>
            </Card>
            <WorkPrtcpModalComponent
                show={show}
                onHide={onHide}
                workPrtcp={workPrtcp}
                setWorkPrtcp={setWorkPrtcp}
                reloadBoard={reloadBoard}
            />
        </>
    );
};

export default DataWorkManager;
