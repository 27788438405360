import React, {useEffect, useState} from "react";
import "../../page/component/DounutCircle.css"
import TextUtilService from "../../component/service/TextUtilService";

const DataCircleComponent = (props) => {

    // 도넛 지름 고정값
    const circularGraphDiameter = 942;
    const circleProgress = 150;

    const [strokeDashoffset, setStrokeDashoffset] = useState(942);

    //NOTE 데이터단위 변경
    let dataUsageUnit;
    if(props.numType==='size'){
        dataUsageUnit=TextUtilService.bytesToSize(props.dataUsage);
    } else {
        dataUsageUnit=props.dataUsage?(props.dataUsage+"").replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0";
    }

    useEffect(() => {
        setStrokeDashoffset(0);
    }, [props.type]);

    return (
        <>
            <div className={"dashboard-circulargraph " + props.type}>
                {
                    <div className="dashboard-circulargraph-info">
                        <div className="ttl">
                            <h4>{props.title}</h4>
                            <span>{props.subTitle}</span>
                        </div>
                        <div className="count">
                            <div className="count-usage">{dataUsageUnit}</div>
                        </div>
                    </div>
                }
                <svg>
                    <circle
                        cx="50%"
                        cy="50%"
                        r={circleProgress}
                        style={
                            { strokeDasharray: circularGraphDiameter ,
                                strokeDashoffset: strokeDashoffset
                            }
                        }
                    ></circle>
                </svg>
            </div>
        </>
    );
};
export default DataCircleComponent;
