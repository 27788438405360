import React, {useEffect, useRef, useState} from 'react';
import ManageService from "../../service/ManageService";
import useChartMemory from "./useChartMemory";


let useMemoryChart = false;

const InteractiveMemory = (props) => {

    const [memoryData, setMemoryData] = useState();
    //Mount , UnMount
    useEffect(() => {

        const memoryInterval = setInterval(function(){requestMemoryMaxData(memoryInterval)}, 2000);
        useMemoryChart=true;

        return () => {
            clearInterval(memoryInterval);
            useMemoryChart=false;
        }
    }, []);

    var index = 0;


    const requestMemoryMaxData = (memoryInterval) => {
        if(useMemoryChart) {
            ManageService.requestMemoryMaxData().then((res) => {
                if(res) {
                    setMemoryData(res.data);

                    var currentData = res.data.measurements[0].value / 1024 / 1024;

                    for (var i = index; i > 0; i--) {
                        data[i].value = data[i - 1].value;
                    }
                    data[0].value = currentData.toFixed(2);
                    if (index < 9) index += 1;
                }
                else {
                    clearInterval(memoryInterval);
                }
            });
        }
    };

    const canvasRef = useRef(null);
    const [data, setData] = useState({
            0: {
                value: 0,
                isVisible: true
            },

            1: {
                value: 0,
                isVisible: true
            },

            2: {
                value: 0,
                isVisible: true
            },

            3: {
                value: 0,
                isVisible: true
            },

            4: {
                value: 0,
                isVisible: true
            },

            5: {
                value: 0,
                isVisible: true
            },

            6: {
                value: 0,
                isVisible: true
            },

            7: {
                value: 0,
                isVisible: true
            },

            8: {
                value: 0,
                isVisible: true
            },
            9: {
                value: 0,
                isVisible: true
            }
        }
    );

    useChartMemory(canvasRef, {
        type: "line",

        data: {
            labels: Object.keys(data).filter(key => data[key].isVisible),

            datasets: [
                {
                    label: "RAM",

                    data: Object.keys(data)
                        .filter(key => data[key].isVisible)
                        .map(key => data[key].value),

                    backgroundColor: "rgba(255, 99, 132, 0.2)",

                    borderColor: "rgba(255, 99, 132, 1)",

                    borderWidth: 1,
                }
            ]
        },
        options: {
            legend: {
                display: true,
                labels: {
                    fontColor: "#ff6a5d"
                }
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            min:0,
                            max:30000
                        }
                    }
                ]
            },
            animation: {
                duration: 0
            }
        }
    });

    return (
        <canvas ref={canvasRef} width="400" height="200" />
    );
};

export default InteractiveMemory;
