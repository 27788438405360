import React from 'react';
import {Form, Modal} from "react-bootstrap";
import LightBtn from "../../../page/component/buttons";
import UserManageService from "../../../page/service/UserManageService";

const AddUserModal = ({show, userInfo, setUserInfo, onHide, loadUserList}) => {

    const groupList = [
        "ADMIN",
        "MARKET_USER",
        "MNTR_USER"
    ];

    const saveUser = () => {
        if(userInfo.check) {
            UserManageService.modifyUser(userInfo).then(r => {
                if(r !== undefined && r.status === 200) {
                    onHide();
                    loadUserList();
                }
            });
        } else {
            UserManageService.saveUser(userInfo).then(r => {
                if(r !== undefined && r.status === 200) {
                    onHide();
                    loadUserList();
                }
            });
        }
    };

    return (
        <Modal
            enforceFocus={false}
            show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>유저 추가</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>유저명</Form.Label>
                    <Form.Control type="text" value={userInfo.name}
                                  onChange={(e) => {
                                      setUserInfo({...userInfo, name:e.target.value})
                                  }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>ID</Form.Label>
                    <Form.Control type="text" value={userInfo.username}
                                  onChange={(e) => {
                                      setUserInfo({...userInfo, username:e.target.value})
                                  }}/>

                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>그룹</Form.Label>
                    <Form.Select value={userInfo.group || ""}
                                 onChange={(e) => {
                                     setUserInfo({...userInfo, group: e.target.value})
                                 }}>
                        {
                            groupList.map((e, i) => {
                                return (
                                    <option key={i} value={e}>{e}</option>
                                )
                            })
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>비밀번호</Form.Label>
                    <Form.Control type="password" value={userInfo.password}
                                  onChange={(e) => {
                                      setUserInfo({...userInfo, password:e.target.value})
                                  }}/>

                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>이메일</Form.Label>
                    <Form.Control type="text" value={userInfo.email}
                                  onChange={(e) => {
                                      setUserInfo({...userInfo, email:e.target.value})
                                  }}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={onHide}>
                    취소
                </LightBtn>
                <LightBtn color={"green"} size={"middle"} clickHandler={() => {
                    saveUser();
                }}>
                    저장
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default AddUserModal;
