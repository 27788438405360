import React, {useEffect, useState} from 'react';
import {Card, Dropdown, Form, Stack} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import ManageService from "../service/ManageService";
import Swal from "sweetalert2";
import DatasetService from "../service/DatasetService";
import FieldTableComponent from "./component/FieldTableComponent";
import {Title2} from "../component/titles";
import LightBtn from "../component/buttons";
import BoxBlack from "../component/Boxes";
import DatasetAllDataModal from "./component/modal/DatasetAllDataModal";
import DataShareUserComponent from "./component/DataShareUserComponent";
import DatasetRePermissionModal from "./component/modal/DatasetRePermissionModal";
import FileService from "../service/FileService";
import ReactTooltip from "react-tooltip";
import ExcelDataResultModal from "./component/modal/ExcelDataResultModal";


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const DatasetInfo = (props) => {
    const nav = useNavigate();
    const params = useParams();

    // 필드 추가 / 수정 판단용 state
    const [fieldModify, setFieldModify] = useState(false);

    const [datasetInfo, setDatasetInfo] = useState({});

    const [fieldList, setFieldList] = useState([]);

    // 모달 관련
    const [isOpenNewModal, setIsOpenNewModal] = useState(false);
    // 모달 관련
    const [isOpenAllDataModal, setIsOpenAllDataModal] = useState(false);

    const [datasetUserInfo, setDatasetUserInfo] = useState({});

    // 엑셀 업로드 성공 시 결과 모달
    const [excelDataResult, setExcelDataResult] = useState(false);

    // 엑셀 저장 결과 크기 및 수
    const [excelDataCount, setExcelDataCount] = useState(0);
    const [excelDataSize, setExcelDataSize] = useState(0);

    //
    const [excelValidation, setExcelValidation] = useState([]);

    // 엑셀 업로드 경과 시간
    const [uploadTime, setUploadTime] = useState(0);

    const loadDatasetInfo = () => {
        DatasetService.loadDatasetInfo(params.dtsId, setDatasetInfo, setFieldList,
            (data, fields) => {
            },
            (msg) => {
                Swal.fire(msg)
            })
    };

    const reloadDatasetUserInfo = () => {
        ManageService.getDatasetUser(params.dtsId).then(r => {
            if (r !== undefined && r.status === 200) {
                setDatasetUserInfo(r.data)
            }
        })
    }


    const deleteField = (row) => {
        ManageService.deleteField(row).then((r) => {
            if (r !== undefined && r.status === 200) {
                loadDatasetInfo();
            } else {
                Swal.fire("삭제 실패")
            }

        })
    };

    const setRepermission = (dtsId, shareUser, perm, setRePermissionVo) => {
        setRePermissionVo({dtsId: dtsId, shareUser: shareUser, permissionType: perm});
    }

    const setRevoke = (dtsId, shareUser) => {
        return {dtsId: dtsId, shareUser: shareUser}
    }

    useEffect(() => {
        loadDatasetInfo();
        reloadDatasetUserInfo();
    }, []);


    return (
        <>
            <div className={"flexGrid-2"}>
                <Card>
                    <Card.Header>
                        <Stack direction="horizontal">
                            <Title2>데이터셋 정보</Title2>
                            <LightBtn
                                color="gray"
                                size="small"
                                clickHandler={() => {
                                    nav(-1)
                                }}
                            >목록</LightBtn>
                        </Stack>
                    </Card.Header>
                    <Card.Body>
                        <BoxBlack>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>데이터셋 이름</Form.Label>
                                    <Form.Control type="text" value={datasetInfo.dtsTitle || ''} readOnly/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>설명</Form.Label>
                                    <Form.Control as="textarea" rows={3} value={datasetInfo.dtsDscr || ''}
                                                  readOnly/>
                                </Form.Group>
                            </Form>
                        </BoxBlack>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Stack direction="horizontal">
                            <Title2>활용 프로세스 및 사용자 권한</Title2>
                            {
                                datasetInfo.dtsType === "FILE" ?
                                    <>
                                    <LightBtn color={'blue'} size={'small'}
                                              data-tip data-for="fileDataInput"
                                              clickHandler={() => {
                                                  nav(`/project/${params.prjId}/${datasetInfo.dtsId}/file/`);
                                              }}>파일 데이터 관리</LightBtn>

                                    <ReactTooltip place="top" type="dark" effect="solid" id="fileDataInput">
                                        <span>파일 관리 페이지로 이동합니다.</span>
                                    </ReactTooltip>
                                    </>
                                    : ""
                            }

                            {
                                datasetInfo.dtsType === "FIELD" ?
                                    <>
                                    <LightBtn color={'blue'} size={'small'}
                                              data-tip data-for="fieldDataInput"
                                              clickHandler={() => {
                                                  //TODO 일괄업로드 기능 구현
                                                  if (fieldList.length === 0) {
                                                      Swal.fire("필드를 최소 한개 이상 만들어주세요.")
                                                  } else {
                                                      setIsOpenAllDataModal(true);
                                                  }
                                                  // Swal.fire("Not Implemented. ")
                                              }}>일괄데이터 입력</LightBtn>

                                        <ReactTooltip place="top" type="dark" effect="solid" id="fieldDataInput">
                                            <span>제공하는 엑셀 양식에 데이터 입력 후 일괄 업로드를 합니다.</span>
                                        </ReactTooltip>
                                    </>
                                    : ""
                            }

                            <Dropdown>
                                <Dropdown.Toggle className={"btn btn-blue btn-small"} id="dropdown-basic">
                                    일괄 다운로드
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        data-tip
                                        data-for="filedown"
                                        onClick={() => {
                                            FileService.getZipFileData(datasetInfo.dtsId, datasetInfo.dtsTitle + ".zip");
                                        }}>파일 다운로드</Dropdown.Item>
                                    <ReactTooltip place="left" type="dark" effect="solid" id="filedown">
                                        <span>파일 type의 데이터를 zip형식으로 다운로드합니다.</span>
                                    </ReactTooltip>
                                    <Dropdown.Item
                                        data-tip
                                        data-for="datadown"
                                        onClick={() => {
                                            FileService.getDataExcel(datasetInfo.dtsId, datasetInfo.dtsTitle + ".xlsx");
                                        }}>데이터 다운로드</Dropdown.Item>
                                    <ReactTooltip place="left" type="dark" effect="solid" id="datadown">
                                        <span>데이터 type의 데이터를 다운로드 합니다.</span>
                                    </ReactTooltip>
                                </Dropdown.Menu>
                            </Dropdown>
                            <LightBtn color={'green'} size={'small'}
                                      data-tip
                                      data-for="viewer"
                                      clickHandler={() => {
                                          nav(`/project/${params.prjId}/${datasetInfo.dtsId}/viewer/`);
                                      }}>데이터 뷰어</LightBtn>
                            <ReactTooltip place="top" type="dark" effect="solid" id="viewer">
                                <span>데이터뷰어 페이지로 이동합니다.</span>
                            </ReactTooltip>

                        </Stack>

                    </Card.Header>
                    <Card.Body>
                        <DataShareUserComponent id={params.dtsId} modal={
                            <DatasetRePermissionModal></DatasetRePermissionModal>}
                                                setRepermission={setRepermission} setRevoke={setRevoke}
                                                loadShareList={ManageService.getShareUserDataset}
                                                revokeUserPermission={ManageService.revokeDataset}
                                                infoVo={datasetUserInfo}
                                                dataType={false}></DataShareUserComponent>
                    </Card.Body>
                </Card>
            </div>
            <div className={'mt-4'}>
                <Card>
                    <Card.Header>
                        <Stack direction={"horizontal"}>
                            <Title2>필드 목록</Title2>
                            <LightBtn color={'green'} size={'small'}
                                      data-tip
                                      data-for="fildAdd"
                                      clickHandler={() => {
                                          setIsOpenNewModal(true);
                                          setFieldModify(false);
                                      }}>+ 필드 추가</LightBtn>
                        </Stack>
                        <ReactTooltip place="top" type="dark" effect="solid" id="fildAdd">
                            <span>추가할 필드 정보를 입력합니다.</span>
                        </ReactTooltip>
                    </Card.Header>
                    <Card.Body>
                        <FieldTableComponent
                            dtsId={params.dtsId}
                            fieldList={fieldList}
                            setFieldList={setFieldList}
                            isOpenNewModal={isOpenNewModal}
                            setIsOpenNewModal={setIsOpenNewModal}
                            deleteField={deleteField}
                            fieldModify={fieldModify}
                            setFieldModify={setFieldModify}
                        />
                    </Card.Body>
                </Card>
            </div>
            <DatasetAllDataModal
                fieldList={fieldList}
                isOpenAllDataModal={isOpenAllDataModal}
                setIsOpenAllDataModal={setIsOpenAllDataModal}
                dtsTitle={datasetInfo.dtsTitle}
                setExcelDataResult={setExcelDataResult}
                setExcelDataCount={setExcelDataCount}
                setExcelDataSize={setExcelDataSize}
                setExcelValidation={setExcelValidation}
                setUploadTime={setUploadTime}
            />
            <ExcelDataResultModal
                fieldList={fieldList}
                excelDataResult={excelDataResult}
                setExcelDataResult={setExcelDataResult}
                excelDataCount={excelDataCount}
                excelDataSize={excelDataSize}
                excelValidation={excelValidation}
                uploadTime={uploadTime}
            />
        </>
    );
};

export default DatasetInfo;
