import React from 'react';
import { Form, Modal} from "react-bootstrap";
import ManageService from "../../service/ManageService";
import Swal from "sweetalert2";
import LightBtn from "../../component/buttons";
import "./DatasetMakerModal.css"

const DatasetMakerModal = (props) => {

    const saveDataset = () => {

        let dataset = {
            ...props.datasetObj,
            prjId: props.prjId
        }

        ManageService.saveDataset(dataset).then((r) => {
            if (r !== undefined && r.status === 200) {
                props.setDatasetObj({
                    dtsId: null,
                    dtsType: 'FILE',
                    dtsTitle: '',
                    dtsDscr: ''
                });
                props.reloadDataset();
                props.setOpen(false);
            } else {
                Swal.fire("저장 실패")
            }

        })


    }


    return (
        <Modal
            enforceFocus={false}
            show={props.isOpen} onHide={() => {
            props.setDatasetObj({
                dtsType: 'FILE',
                dtsTitle: '',
                dtsDscr: ''
            });
            props.setOpen(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>데이터셋 추가</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>데이터셋 명</Form.Label>
                    <Form.Control type="text" value={props.datasetObj.dtsTitle}
                                  onChange={(e) => {
                                      props.setDatasetObj({...props.datasetObj, dtsTitle: e.target.value})
                                  }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>설명</Form.Label>
                    <Form.Control as="textarea" rows={3} value={props.datasetObj.dtsDscr}
                                  onChange={(e) => {
                                      props.setDatasetObj({...props.datasetObj, dtsDscr: e.target.value})
                                  }}/>

                </Form.Group>

                <div>
                    <div className={"dtsTypeSelect"}>
                        <label onClick={() => {
                            props.setDatasetObj({...props.datasetObj, dtsType: 'FILE'})
                        }}>
                            <input type={"radio"} name={"dtsType"} defaultChecked={props.datasetObj.dtsType==="FILE"?true:false}/>
                            <span>파일 데이터셋</span>
                            <p>
                                파일 기반 데이터셋을 생성합니다. 자유롭게 파일을 업로드 할 수 있습니다.
                            </p>
                        </label>
                        <label onClick={() => {
                            props.setDatasetObj({...props.datasetObj, dtsType: 'FIELD'})
                        }}>
                            <input type={"radio"} name={"dtsType"} defaultChecked={props.datasetObj.dtsType==="FIELD"?true:false}/>
                            <span>필드 데이터셋</span>
                            <p>
                                필드 정의 기반의 데이터셋을 생성합니다. 데이터는 정의된 필드에 맞춰 입력되어야 합니다.
                            </p>
                        </label>
                        {/*<label onClick={() => {*/}
                        {/*    props.setDatasetObj({...props.datasetObj, dtsType: 'RDB'})*/}
                        {/*}}>*/}
                        {/*    <input type={"radio"} name={"dtsType"} />*/}
                        {/*    <span>RDB 데이터셋</span>*/}
                        {/*    <p>*/}
                        {/*        RDBMS 접속 정보 기반 데이터셋 뷰어를 작성합니다.*/}
                        {/*    </p>*/}
                        {/*</label>*/}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={() => {
                    props.setOpen(false);
                    props.setDatasetObj({
                        dtsType: 'FILE',
                        dtsTitle: '',
                        dtsDscr: ''
                    });
                }}>
                    취소
                </LightBtn>
                <LightBtn color={"green"} size={"middle"} clickHandler={() => {
                    if(!props.datasetObj.dtsTitle) {
                        Swal.fire("데이터셋 명을 입력해주세요.")
                    }
                    else if(!props.datasetObj.dtsType) {
                        Swal.fire("데이터셋 타입을 선택해주세요.")
                    }
                    else {
                        saveDataset();
                    }
                }}>
                    저장
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default DatasetMakerModal;
