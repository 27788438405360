import React, {useEffect, useState} from 'react';
import {Card, Stack} from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableStyle from "../config/DataTableStyle";
import {useNavigate, useParams} from "react-router-dom";
import memoize from "memoize-one";
import DataWorkService from "../service/DataWorkService";
import MakeFieldComponent from "../manage/component/MakeFieldComponent";
import DatasetService from "../service/DatasetService";
import Swal from "sweetalert2";
import LightBtn from "../component/buttons";
import {Title2, Title3} from "../component/titles";
import BoxBlack from "../component/Boxes";
import "./DataWorkHistory.css"
import ReactTooltip from "react-tooltip";

const columns = memoize((loadDetailData, convertDate, setCurrentHsId) => [
    {
        name: '번호',
        width: '60px',
        center: true,
        selector: (row, index) => (index + 1)
    },
    {
        name: '승인 여부',
        selector: row => row.hsAcpt,
    },
    {
        name: '작업 날짜',
        selector: row => convertDate(row.hsDt),
    },
    {
        name: '관리',
        button: true,
        minWidth: '80px',
        cell: row =>
            <>
                <LightBtn size={'tiny'} color={"green"} icon={"manage"}
                          data-tip data-for="monitorWorkHistoryManage"
                          clickHandler={() => {
                              loadDetailData(row);
                              setCurrentHsId(row.hsId);
                          }}/>
                <ReactTooltip place="top" type="dark" effect="solid" id="monitorWorkHistoryManage">
                    <span>해당 작업의 상세 데이터를 확인합니다.</span>
                </ReactTooltip>
            </>,
    }
]);

const DataWorkHistory = () => {
    const [currentHsId, setCurrentHsId] = useState(0);
    const [selectedData, setSelectedData] = useState({});
    const [detailOpen, setDetailOpen] = useState(false);

    const nav = useNavigate();
    const params = useParams();

    const [historyList, setHistoryList] = useState([]);

    const [fieldList, setFieldList] = useState([]);
    const [datasetInfo, setDatasetInfo] = useState({});

    const [dtId, setDtId] = useState(null);
    const [dtsId, setDtsId] = useState(null);

    // integer to date
    const convertDate = (date) => {
        var convertDate = new Date(date);
        var year = convertDate.getFullYear();
        var month = convertDate.getMonth() + 1;
        if (month < 10) month = "0" + month;
        var day = convertDate.getDate();
        if (day < 10) day = "0" + day;
        return year + "-" + month + "-" + day;
    };

    const reloadBoard = () => {
        var prtcpUsr = params.prtcpUsr;

        if (prtcpUsr === 'all') {
            DataWorkService.getWorkHistory(params.wkId).then((r) => {
                if (r !== undefined && r.status === 200) {
                    setHistoryList(r.data);
                    if (r.data.length !== 0) {
                        loadDatasetInfo(r.data[0].dtsId);
                    }
                }
            })
        } else {
            DataWorkService.getWorkUserHistory(params.wkId, params.prtcpUsr).then((r) => {
                if (r !== undefined && r.status === 200) {
                    setHistoryList(r.data);
                    if (r.data.length !== 0) {
                        loadDatasetInfo(r.data[0].dtsId);
                    }
                }
            })
        }
    };

    const loadDatasetInfo = (dtsId) => {
        DatasetService.loadDatasetInfo(dtsId, setDatasetInfo, setFieldList,
        (data, fields) => {
            //! Data Load
            // reloadData(0,10, dtsId);
            if (datasetInfo.length === 0) {

            }
        },
        (msg) => {
            Swal.fire(msg)
        })
    };

    const loadDetailData = (row) => {
        DataWorkService.getOneRowTempData(row.dtsId, row.dtId, params.wkId).then(r => {
            if (r !== undefined && r.status === 200) {
                setDtId(row.dtId);
                setDtsId(row.dtsId);
                setSelectedData(r.data);
                setDetailOpen(true);
            } else {
                //TODO  예외처리
            }
        })
    };

    const workReflect = () => {
        DataWorkService.workReflect(params.wkId).then(r => {

        });
    };

    useEffect(() => {
        reloadBoard();
    }, []);

    return (
        <Card>
            <Card.Header>
                <Stack direction={"horizontal"}>
                    <Title2>작업 이력 관리</Title2>
                    <LightBtn
                        color="green"
                        size="small"
                        clickHandler={() => {
                            workReflect();
                        }}
                    >승인 반영</LightBtn>
                    <LightBtn
                        color="gray"
                        size="small"
                        clickHandler={() => {
                            nav(-1)
                        }}
                    >목록</LightBtn>
                </Stack>
            </Card.Header>
            <Card.Body>
                <div className={"flexGrid-2 workHistory"}>
                    <div style={{flex: "0.7"}}>
                        <Card>
                            <Card.Header><Title3>작업 목록</Title3></Card.Header>
                            <Card.Body>
                                <DataTable
                                    columns={columns(loadDetailData, convertDate, setCurrentHsId)}
                                    data={historyList}
                                    customStyles={DataTableStyle.customStyles}
                                    highlightOnHover
                                    // pointerOnHover
                                    pagination
                                />
                            </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card>
                            <Card.Header><Title3>작업 상세</Title3></Card.Header>
                            <Card.Body>
                                <BoxBlack>
                                    {
                                        detailOpen === true ?
                                            fieldList.map(f => {
                                                return (
                                                    <MakeFieldComponent
                                                        key={f.fdCd}
                                                        field={f}
                                                        data={selectedData.values}
                                                        type={"read"}
                                                        setData={""}
                                                        dtId={dtId !== undefined ? dtId : null}
                                                        dtsId={dtsId !== undefined ? dtsId : null}
                                                    />
                                                )
                                            }) : null
                                    }
                                    {
                                        detailOpen === true ?
                                            <div className={"btns"}>
                                                <LightBtn color={"red"} size={"middle"} clickHandler={() => {
                                                    Swal.fire({
                                                        title: '거부 사유',
                                                        input: 'text',
                                                        inputAttributes: {
                                                            autocapitalize: 'off'
                                                        },
                                                        showCancelButton: true,
                                                        confirmButtonText: '거부',
                                                        showLoaderOnConfirm: true,
                                                        preConfirm: (cause) => {
                                                            DataWorkService.rejectWorkHistory(params.wkId, currentHsId, cause).then(() => {
                                                                reloadBoard();
                                                            });
                                                        },
                                                        allowOutsideClick: () => !Swal.isLoading()
                                                    })
                                                }}>거부</LightBtn>
                                                <LightBtn color={"green"} size={"middle"} clickHandler={() => {
                                                    DataWorkService.acceptWorkHistory(params.wkId, currentHsId).then(() => {
                                                        reloadBoard();
                                                    });
                                                }}>
                                                    승인
                                                </LightBtn>
                                            </div> : null
                                    }
                                </BoxBlack>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default DataWorkHistory;
