import React, {useEffect, useState} from 'react';
import {Card, Col, Form, ListGroup, Row, Stack} from "react-bootstrap";
import LightBtn from "../../../component/buttons";
import Swal from "sweetalert2";

const DataFilterTool = ({
                              localProperty,
                              targetField,
                              precdTask,
                              node,
                              value,
                              onChange
                          }) => {

    const [selectors, setSelectors] = useState([]);

    useEffect(()=>{
        if(value) {
            setSelectors(JSON.parse(value))
        }
    },[])

    useEffect(() => {
        onChange(JSON.stringify(selectors))
    }, [selectors]);


    const [newSelector, setNewSelector] = useState(undefined);


    const deleteSelector = ()=>{
        const result = selectors.filter(s => s.name !== newSelector.name);
        setSelectors([...result]);
        setNewSelector(undefined);
    }

    const saveSelector = ()=>{
        if(newSelector&& newSelector.name===""){
            Swal.fire("필터 이름을 입력해주세요.");

            return;
        }
        let isChange = false;
        for(let idx in selectors){
            if(selectors[idx].name === newSelector.name){
                selectors[idx]=newSelector;
                isChange=true;
            }
        }
        if(isChange)
        {
            setSelectors([...selectors]);
        }else{
            setSelectors([...selectors,
                newSelector]);
        }

        setNewSelector(undefined);
    };

    if(!precdTask){
        return <p>선행노드가 없습니다.</p>
    }else if(!precdTask.lastTest){
        return <p>선행 노드를 먼저 테스트해주세요.</p>
    }

    const searchRegExp =
        {
            "01(?:0|1|[6-9])[.-]?([0-9]{3}|[0-9]{4})[.-]?([0-9]{4})": '전화번호',
            "([a-z0-9_\.-]+)@(([\da-z\.-]+)\.([a-z\.]{2,6}))": '이메일주소',
            "(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}": 'URL',
            "[ㄱ-ㅣ가-힣]": '한글',
            "[ㄱ-ㅣ가-힣a-zA-Z]": '한글 및 영문자',
            "[ㄱ-ㅣ가-힣0-9]": '한글 및 숫자',
            "[a-zA-Z]": '영문자',
            "[0-9]": '숫자',
            "[a-zA-Z0-9]": '영문자 및 숫자',
        };

    const regExpSwal = () => {
        Swal.fire({
            title: '찾을 정규식',
            input: 'select',
            inputOptions: searchRegExp,
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: '확인',
            showLoaderOnConfirm: true,
            preConfirm: (key) => {
                setNewSelector({
                    ...newSelector,
                    selector:key
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }

    const openExp = () => {
        var url = `https://ko.wikipedia.org/wiki/%EC%A0%95%EA%B7%9C_%ED%91%9C%ED%98%84%EC%8B%9D`;
        window.open(url, "title", "width=1200, height=900, top=10, left=10");
    };

    return (

        <Card>
            <Card.Header>
                <Stack direction="horizontal" gap={3}>
                    <div>Filters</div>
                    <div className="ms-auto">
                        <LightBtn  size="sm" color={"green"} size={"sm"} clickHandler={() => {
                            setNewSelector(
                                {
                                    name: "",
                                    selector: "",
                                    selectorType: "equal"
                                }
                            )
                        }}>추가</LightBtn>
                    </div>
                </Stack>
            </Card.Header>
            <Card.Body className="border-bottom pb-1">
                {
                    newSelector!==undefined?<div>
                        <Form.Group className="mb-3">
                            <Form.Label>필터 이름 (필수)</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="Filter Name"
                                value={newSelector.name ? newSelector.name : ""}
                                onChange={(e) => {
                                    setNewSelector({
                                        ...newSelector,
                                        name:e.target.value.toUpperCase()
                                    })
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Filter</Form.Label>
                            <Row>
                                <Col md={9}>
                                    <Form.Control type="text"
                                                  size="sm"
                                                  value={newSelector.selector ? newSelector.selector : ""}
                                                  placeholder={"Filter"}
                                                  onChange={(e) => {
                                                      setNewSelector({
                                                          ...newSelector,
                                                          selector:e.target.value
                                                      })
                                                  }}
                                    />
                                </Col>
                                <Col>
                                    <LightBtn
                                        className={"ms-auto me-2"}
                                        clickHandler={()=>{
                                            regExpSwal()
                                        }
                                        }
                                        color={"outline-warning"} size={"sm"}>예시</LightBtn>
                                    <LightBtn
                                        clickHandler={()=>{
                                            openExp()
                                        }
                                        }
                                        color={"outline-info"} size={"sm"}>설명</LightBtn>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Label>Filter Type</Form.Label>
                        <Form.Select
                            size="sm"
                            onChange={(e) => {
                                setNewSelector({
                                    ...newSelector,
                                    selectorType:e.target.value
                                })
                            }}
                        >
                            <option value={"equal"} selected={newSelector.selectorType === "equal"} >{"일치"}</option>
                            <option value={"not"} selected={newSelector.selectorType === "not"} >{"미일치"}</option>
                            <option value={"contains"} selected={newSelector.selectorType === "contains"}>{"포함 (정규식)"}</option>
                            <option value={"notContains"} selected={newSelector.selectorType === "notContains"}>{"미포함 (정규식)"}</option>
                            {/*<option value={"greaterE"} selected={newSelector.selectorType === "greaterE"}>{">="}</option>*/}
                            {/*<option value={"less"} selected={newSelector.selectorType === "less"}>{"<"}</option>*/}
                            {/*<option value={"greater"} selected={newSelector.selectorType === "greater"}>{">"}</option>*/}
                        </Form.Select>

                        <LightBtn clickHandler={saveSelector} color={"blue"} size={"sm"}>저장</LightBtn>
                        <LightBtn className={"ms-2"} clickHandler={()=>{deleteSelector()}} color={"red"} size={"sm"}>삭제</LightBtn>
                    </div>:null
                }
            </Card.Body>
            <ListGroup variant="flush" className={"mt-1"}>
                {selectors.map((s, i) => {
                    return <Card>
                        <Card.Body className={"bg-transparent border-1 border-secondary p-1"}>
                            <Stack direction="horizontal" gap={3}>
                                <div>{s.name}</div>
                                <div className="ms-auto">
                                    <LightBtn
                                        clickHandler={()=>{
                                            setNewSelector(s)
                                        }
                                        }
                                        color={"outline-warning"} size={"sm"}>수정</LightBtn>
                                </div>
                            </Stack>
                        </Card.Body>
                    </Card>;
                })
                }

            </ListGroup>
        </Card>
    );
};

export default DataFilterTool;
