import axios from "axios";
import DataService from "./DataService";

class FileService {

    static getDataExcel(dtsId, filename, popupCheck) {
        FileService.downloadFile(`/dataset/data/download/${dtsId}`, {}, filename, DataService.deleteDataFile, dtsId, popupCheck);
        console.log(dtsId, filename)
    }

    static getZipFileData(dtsId, filename, popupCheck) {
        FileService.downloadFile(`/api/file/zip/${dtsId}`, {}, filename, DataService.deleteZipFile, dtsId, popupCheck);
        console.log(dtsId, filename)
    }

    static excelDownloadFileByDtsId(dtsId, filename) {
        FileService.downloadFile(`/dataset/excel/download/${dtsId}`, {}, filename)
        console.log(dtsId, filename)
    }

    static downloadFileByDtId(dtsId, dtId, fdCd, filename) {
        FileService.downloadFile(`/api/file/${dtsId}/${dtId}?fdCd=${fdCd}`, {}, filename)
        console.log(dtsId, dtId, fdCd, filename)
    }

    static async downloadFile(targetUrl, data, filename, deleteFunction, id, popupCheck) {

        const config = {
            method: "GET",
            url: targetUrl,
            headers: {
                // Authorization: tokenType + " " + accessToken,
                ContentType: "application/json",
            },
            responseType: "blob",
            data: data,
        };
        const response = await axios(config);
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        link.style.cssText = "display:none";
        document.body.appendChild(link);
        link.click();
        link.remove();

        if(popupCheck) {
            if( window.self !== window.top ) {
                // 아이프레임일 경우 부모창 함수 호출
                window.parent.fn_iframe_delete(id);
            }
        }

        if(deleteFunction) {
            deleteFunction(id);
        }
    }

}

export default FileService;
