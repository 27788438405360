import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-bootstrap";
import DataCircleComponent from "../component/DataCircleComponent";
import ManageService from "../../page/service/ManageService";
import DataStatComponent from "../component/DataStatComponent";
import {Title2, Title3} from "../../page/component/titles";
import BoxBlack from "../../page/component/Boxes";
import InteractiveCpu from "../../page/manage/component/InteractiveCpu";
import InteractiveMemory from "../../page/manage/component/InteractiveMemory";

const DataStatPage = ({datasetList}) => {

    // 총 데이터 크기
    const [dtSize, setDtSize] = useState(0);
    // 총 데이터 수
    const [dtCount, setDtCount] = useState(0);
    // 총 파일 크기
    const [flSize, setFlSize] = useState(0);
    // 총 파일 수
    const [flCount, setFlCount] = useState(0);
    // 프로젝트, 데이터셋, 프로세스, 작업 수
    const [stat, setStat] = useState({});

    useEffect(() => {
        loadStat();
    }, []);

    useEffect(() => {
        calcDts();
    }, [datasetList]);


    // 프로젝트, 데이터셋, 프로세스, 작업 개수 불러오기
    const loadStat = () => {
        ManageService.loadStat().then((r) => {
            setStat(r.data);
        });
    };

    // datasetList 기반으로 데이터, 파일 수 및 크기 계산
    const calcDts = () => {
        var allDtCount = 0;
        var allDtSize = 0;
        var allFlCount = 0;
        var allFlSize = 0;
        for(var i in datasetList) {
            var dataset = datasetList[i];
            // 각 데이터셋에 대한 크기
            var fileCount = dataset["fileCount"];
            var fileSize = dataset["fileSize"];
            var dtsCount = dataset["dtsCount"];
            var dtsSize = dataset["dtsSize"];

            allDtCount += dtsCount;
            allDtSize += (dtsSize - fileSize);
            allFlCount += fileCount;
            allFlSize += fileSize;
        }

        setDtCount(allDtCount);
        setDtSize(allDtSize);
        setFlCount(allFlCount);
        setFlSize(allFlSize);
    };

    return (
        <>
            <Row>
                <DataStatComponent
                    title={"프로젝트 수"}
                    content={stat["project"]}
                />
                <DataStatComponent
                    title={"데이터셋 수"}
                    content={stat["dataset"]}
                />
                <DataStatComponent
                    title={"프로세스 수"}
                    content={stat["process"]}
                />
                <DataStatComponent
                    title={"작업 수"}
                    content={stat["work"]}
                />
            </Row>
            <Row>
                <Col>
                    <DataCircleComponent
                        type={"sub"}
                        numType={"size"}
                        title={"데이터 크기"}
                        subTitle={"Data Size"}
                        dataUsage={dtSize ? dtSize : 0}
                    />
                </Col>
                <Col>
                    <DataCircleComponent
                        type={"sub"}
                        numType={"num"}
                        title={"데이터 수"}
                        subTitle={"Data Count"}
                        dataUsage={dtCount ? dtCount : 0}
                    />
                </Col>
                <Col>
                    <DataCircleComponent
                        type={"sub"}
                        numType={"size"}
                        title={"파일 크기"}
                        subTitle={"File Size"}
                        dataUsage={flSize ? flSize : 0}
                    />
                </Col>
                <Col>
                    <DataCircleComponent
                        type={"sub"}
                        numType={"num"}
                        title={"파일 수"}
                        subTitle={"File Count"}
                        dataUsage={flCount ? flCount : 0}
                    />
                </Col>
            </Row>
            <div className="dashboard-graph">
                <Title2>서버 상태</Title2>
                <BoxBlack>
                    <div className="flexGrid-2">
                        <div className="dashboard-graph-wrapper">
                            <Title3>CPU 사용량 현황</Title3>
                            <div>
                                <InteractiveCpu

                                />
                            </div>
                        </div>
                        <div className="dashboard-graph-wrapper">
                            <Title3>RAM 사용량 현황</Title3>
                            <div>
                                <InteractiveMemory

                                />
                            </div>
                        </div>
                    </div>
                </BoxBlack>
            </div>
        </>
    );
};

export default DataStatPage;
