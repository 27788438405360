import {useEffect} from "react";
import Chart from "chart.js";

const useChartCpu = (nodeRef, options) => {

    useEffect(() => {
        if(window.cpuChart) {
            window.cpuChart.destroy();
        }
        window.cpuChart = new Chart(nodeRef.current, options);
    }, [nodeRef, options]);

    return {};
};

export default useChartCpu;
