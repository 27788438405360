import React, {useEffect, useState} from 'react';
import CurrentDatasetInfo from "../user/component/CurrentDatasetInfo";
import UserManageService from "../../page/service/UserManageService";
import ManageService from "../../page/service/ManageService";
import CurrentFileData from "../user/component/CurrentFileData";
import CurrentFieldData from "../user/component/CurrentFieldData";
import {Tab, Tabs} from "react-bootstrap";
import DataStatPage from "./DataStatPage";
import {useNavigate} from "react-router-dom";

const ManageDataPage = (props) => {

    const navigate = useNavigate();

    const size = 10;

    const [dtsId, setDtsId] = useState();

    const [datasetList, setDatasetList] = useState([]);
    const [allDatasetList, setAllDatatsetList] = useState([]);

    const [projectD, setProjectD] = useState({});

    const [dtIndex, setDtIndex] = useState(0);

    const [tabKey, setTabKey] = useState("stat");

    // 파일리스트
    const [files, setFiles] = useState([]);

    useEffect(() => {
        loadDatasetList();
    }, []);

    useEffect(() => {
        loadProjectNm();
    }, [datasetList]);

    const loadDatasetList = () => {
        UserManageService.loadUserDatasetList("").then((r) => {
            if (r !== undefined && r.status === 200) {
                var data = r.data.sort((d1, d2) => d2.dataDt - d1.dataDt).slice(0, size);
                setDatasetList(data);
                setAllDatatsetList(r.data);
            }else {
                    navigate('/error/403')
            }
        });
    };

    const loadProjectNm = () => {
        var prDict = {};
        var index = 0;
        for(var i in datasetList) {
            var dts = datasetList[i];
            ManageService.getInfoProject(dts.prjId).then((r) => {
                prDict[r.data.prjId] = r.data.prjNm;
                index+=1;
                if(index === 10) {
                    setProjectD(prDict);
                }
            });
        }
    }

    return (
        <>
            <Tabs
                activeKey={tabKey}
                onSelect={(e) => {
                    setTabKey(e);
                }}
                defaultActiveKey="stat"
                transition={false}
                className="mb-3 task-info-tab">
                <Tab eventKey="stat" title="통계">
                    <DataStatPage
                        datasetList={allDatasetList}
                    />
                </Tab>
                <Tab eventKey="data" title="최근 등록 데이터">
                    <CurrentDatasetInfo
                        memberInfo={null}
                        projectD={projectD}
                        setDtsId={setDtsId}
                        datasetList={datasetList}
                        dataUsage={null}
                        dataCapacity={null}
                        size={size}
                        loadProjectNm={loadProjectNm}
                        setDtIndex={setDtIndex}
                        setFiles={setFiles}
                    />
                    <div className={'mt-4 flexGrid-2'}>
                        <CurrentFileData
                            dtsId={dtsId}
                            datasetField={datasetList.length!==0?datasetList[dtIndex]["tbFieldsByDtsId"]:null}
                            files={files}
                            setFiles={setFiles}
                        />
                        <CurrentFieldData
                            dtsId={dtsId}
                        />
                    </div>
                </Tab>
            </Tabs>
        </>
    );
};

export default ManageDataPage;
