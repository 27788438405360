class LayoutService {
    /**
     * 메뉴 그룹 작성
     * @param permit
     * @returns 메뉴 결과
     */
    static permitSplitter(permit) {
        let topMenus = {}

        permit.forEach((p) => {
            if (p['menuDepth'] === 0) {
                p['child'] = [];
                topMenus[p['menuId']] = p;
            }
        })
        permit.forEach((p) => {
            if (p['menuDepth'] === 1) {
                const parent = topMenus[p['menuParent']];
                if (parent['child']) {
                    parent['child'].push(p);
                    parent['child'] = parent['child'].sort(function (a, b) { // 오름차순
                        return a['menuOrder'] - b['menuOrder'];
                    });
                } else {
                    parent['child'] = [p]
                }
            }
        })
        let menus = [];
        for (var menuId in topMenus) {
            menus.push(topMenus[menuId])
        }

        return menus.sort(function (a, b) { // 오름차순
            return a['menuOrder'] - b['menuOrder'];
        });
    }
}

export default LayoutService;
