import React, {useEffect, useState} from "react";
import SmtLogoTxtW from "../../../../images/df_logo_w.png";
import BtnLogout from "../../../../images/btn_logout.png";
import BtnUser from "../../../../images/btn_user.png";
import {Link} from "react-router-dom";
import {Container, Image, Nav, Navbar} from "react-bootstrap";
import ATMenusComponent from "./ATMenusComponent";
import axios from "axios";
import ManageService from "../../../../page/service/ManageService";

const ATNavComponent = ({menus}) => {

    const [user, setUser] = useState();
    const auth = sessionStorage.getItem('authenticatedUser');
    useEffect(() => {
        ManageService.loadMemberByUsername().then(r => {
            setUser(r.data.mbName);
        });
    }, [auth]);

    return (
        <Navbar
            className={"navbar-dark navbar-overlap d-print-none"}
            collapseOnSelect
            expand="md"
            variant="dark"
        >
            <Container fluid="xl">
                <Navbar.Brand>
                    <Link to="/">
                        <Image
                            src={SmtLogoTxtW}
                            height="40"
                            className="navbar-brand-image"
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <ATMenusComponent menus={menus}/>
                    </Nav>
                    <div className="nav-user">
                        <Image src={BtnUser}/>
                        <strong>
                            {user}
                        </strong>
                        <span>님</span>
                    </div>
                    <Nav>
                        <Nav.Link
                            className="nav-logout"
                            as={Link}
                            to={"/login"}
                        >
                            {/*<Link className="text-decoration-none d-flex lh-1 text-reset p-0" to={"/login"}>*/}
                            <Image src={BtnLogout}/>
                            {/*</Link>*/}
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default ATNavComponent;
