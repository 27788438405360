import React from 'react';
import StepFooter from "./component/StepFooter";
import memoize from "memoize-one";
import DataTable from "react-data-table-component";
import DataTableStyle from "../page/config/DataTableStyle";
import {Button, Card} from "react-bootstrap";
import "./popup.css"
import PopupATPageHeader from "./component/PopupATPageHeader";

const PROJECT_COLUMNS = memoize((props) => [
    {
        name: '번호',
        maxWidth: '50px',
        center: true,
        selector: row => row.prjId
    },
    {
        name: '프로젝트 명',
        selector: row => row.prj.prjNm,
    },
    {
        name: '분류',
        selector: row => row.tgtType,
    },
    {
        name: '관리',
        button: true,
        cell: row => <>
            <button
                className={"popupBtn popupBtn-white"}
                onClick={() => {
                props.setNProject({
                    "prjId": row.prj.prjId,
                    "prjNm": row.prj.prjNm,
                    "prjDscr": row.prj.prjDscr,
                    "crUsr": row.prj.crUsr,
                    "crDt": row.prj.crDt
                });
                props.goToStep(4);
                props.reloadDataset(row.prjId);
                props.setPrjId(row.prjId);
            }}>바로가기</button>
        </>,

    }
]);

const ProjectList = (props) => {

    return (
        <>
            <div className={"popup-wrapper"}>
                <div className={"popup-header"}>
                    <div className={"ph-ttl"}>
                        {/*<PopupATPageHeader*/}
                        {/*    pageList={[*/}
                        {/*        {*/}
                        {/*            "header": "프로젝트",*/}
                        {/*            "step":3*/}
                        {/*        }*/}
                        {/*    ]}*/}
                        {/*    goToStep={props.goToStep}*/}
                        {/*/>*/}
                        <h5> 프로젝트 목록</h5>
                        <p>사용자님이 소유한 프로젝트 목록입니다.</p>
                    </div>
                </div>
                <div className={"popup-body"}>
                    <DataTable
                        columns={PROJECT_COLUMNS(props)}
                        data={props.projectList}
                        customStyles={DataTableStyle.popupStyle}
                        highlightOnHover
                        // pointerOnHover
                        pagination
                    />
                    <div className={"popup-footer"}>
                        {/*<StepFooter*/}
                        {/*    btnList={[{"Name": "이전", "Step": 2}]}*/}
                        {/*    goToStep={props.goToStep}*/}
                        {/*>*/}
                        {/*</StepFooter>*/}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectList;
