import React, {useEffect, useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import DataService from "../../service/DataService";
import Swal from "sweetalert2";
import MakeFieldComponent from "./MakeFieldComponent";
import LightBtn from "../../component/buttons";

const DataViewModal = (props) => {
    const [data, setData] = useState({});
    // const [tempData, setTempData] = useState({});
    const [uploadDtId, setUploadDtId] = useState(props.data.dataMeta !== undefined ? props.data.dataMeta.dtId : null);
    const [saveCheck, setSaveCheck] = useState(false);

    function initNewData() {
        // console.log('initNewData');
        let tempData = {};
        /**
         * 저장공간 작성
         */
        props.fieldList.forEach(f => {

            let defaultValue = null;

            tempData = {
                ...tempData,
                [f.fdCd]: defaultValue
            };

            if (f.child !== undefined) {
                f.child.forEach(fc => {
                    let defaultValue = null;

                    tempData = {
                        ...tempData,
                        [fc.fdCd]: defaultValue
                    }
                })
            }
        });

        setData(tempData);
        // setTempData(tempData);
    }

    useEffect(() => {
        /**
         * = 모달 Type에 따른 값 초기화 여부 결정
         */
        if (props.show === true) {
            if (props.type === 'new') {
                initNewData();
            } else if (props.type === 'modify' || props.type === 'read') {
                setData(props.data.values);
                // setTempData(props.data.values);
            }
        }
    }, [props.show]);

    useEffect(() => {
        if (uploadDtId && props.show) {
            uploadClick();
            props.onHide();
            setUploadDtId(null);
            setSaveCheck(null);
        }
    },[uploadDtId]);

    const uploadClick = () => {
        var headerTagList = document.getElementsByClassName("dz-ui-header");
        for (var i in headerTagList) {
            // var headerTag = document.getElementsByClassName("dz-ui-header")[0];
            if(i === "length") break;
            var headerTag = headerTagList[i];
            if(headerTag) {
                var point = headerTag.getElementsByTagName("svg")[0].getBoundingClientRect();
                var x = point.left + 10;
                var y = point.top + 10;
                var evt = document.createEvent("MouseEvents");
                evt.initMouseEvent("click", true, true, window, 0,0,0,0,0, false, false, false, 0, null);
                var cb = document.elementFromPoint(x, y);
                try {
                    cb.dispatchEvent(evt);
                }
                catch {
                    console.log("Not Image");
                }
            }
        }
    };

    /**
     * 데이터 저장
     */
    const saveData = (sData) => {
        const dataLength = Object.values(sData).length;
        const fileLength = props.fieldList.filter(e => ["FILE", "FILE_JSON"].includes(e.fdType)).length;
        const nullLength = Object.values(sData).filter(e => null === e).length;
        if(nullLength === dataLength && (dataLength !== fileLength || fileLength === 0)) {
            Swal.fire("데이터를 최소 하나 이상 채워주세요")
        }
        else {
            let dtId = null;
            if (props.type === 'modify')
                dtId = props.data.dataMeta.dtId;
            DataService.saveRowData(props.dtsId, dtId, sData).then(r => {
                if (r !== undefined && r.status === 200) {
                    setUploadDtId(r.data);
                    setSaveCheck(true);
                    props.reloadData();


                } else {
                    Swal.fire("저장 실패")
                }
            });
        }
    };

    const updateSetData = (dtId) => {
        DataService.getOneRowData(props.dtsId, dtId).then(res => {
            setData(res.data.values);
        });
    };

    return (
        <Modal
            className={props.className}
            enforceFocus={false}
            show={props.show} onHide={() => {
            props.onHide(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>데이터 추가</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {
                        props.fieldList.map(f => {
                            return (
                                <MakeFieldComponent
                                    key={f.fdCd}
                                    field={f}
                                    data={data}
                                    type={props.type}
                                    setData={setData}
                                    dtId={props.data.dataMeta !== undefined ? props.data.dataMeta.dtId : null}
                                    dtsId={props.dtsId}
                                    reloadData={props.reloadData}
                                    updateSetData={updateSetData}
                                    uploadDtId={uploadDtId}
                                    saveCheck={saveCheck}
                                />
                            )
                        })
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={() => {
                    props.onHide(false);
                }}>
                    취소
                </LightBtn>
                <LightBtn color={"green"} size={"middle"} clickHandler={() => {
                    saveData(data);
                }}>
                    저장
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default DataViewModal;
