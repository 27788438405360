import React from 'react';
import "../popup.css"

const PopupATPageHeader = (props) => {
    return (
        <div className="page-header d-print-none">
            <div className="col-auto ms-auto">
                <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item active"><a className="" onClick={() => {
                        props.goToStep(2)
                    }}><span style={{"color":"#121212"}}>Main</span></a></li>
                    {
                        props.pageList.map((e, i) => {
                            return (
                                <li key={i} className="breadcrumb-item active"><a aria-current="page" className="active"
                                                                          onClick={() => {
                                                                              props.goToStep(e.step)
                                                                          }}><span style={{"color":"#121212"}}>{e.header}</span></a></li>
                            )
                        })
                    }
                </ol>
            </div>
        </div>
    );
};

export default PopupATPageHeader;