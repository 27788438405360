
import React from 'react';
import ProcessService from "./ProcessService";
import Swal from "sweetalert2";
import axios from "axios";
import {toast} from "react-toastify";

let PROCESS_TASK_MAP = undefined;
let PROCESS_TASK_TREE_MAP = undefined;

/**
 * = 태스크 정보 가져오기, 저장하기 등을 관리
 */
class TaskService {
    /**
     *= 모든 작업 상태 초기화 => READY
     */
    static initAllState(){
        const list = TaskService.getAllTasks();
        list.forEach(task => {
            task['taskState'] = 'READY'
        })
    }

    /**
     *= 작업 상태 변경
     * @param taskId 작업 번호
     * @param state 상태
     */
    static setTaskState(taskId,state){
        if(PROCESS_TASK_MAP[taskId]){
            PROCESS_TASK_MAP[taskId]['taskState']=state
        }
    }

    /**
     *= 작업 목록 가져오기 ( Array )
     */
    static getAllTasks(){
        if(PROCESS_TASK_MAP!==undefined)
            return Object.values(PROCESS_TASK_MAP);
        else{
            return [];
        }
    }

    /**
     * 작업 목록 -> 작업 트리 맵
     */
    static makeTreeMap(){
        const allTask = this.getAllTasks();

        if(allTask==null){
            return null;
        }
        //실행 트리맵 작성
        else {
            let treeMap = {};
            allTask.forEach((task) => {
                if (treeMap[task.precdTaskId]) {
                    treeMap[task.precdTaskId] = [
                        ...treeMap[task.precdTaskId],
                        task
                    ]
                } else {
                    treeMap[task.precdTaskId] = [task];
                }
            })

            PROCESS_TASK_TREE_MAP = treeMap;
            return treeMap;
        }

    }

    /**
     * 작업 트리맵에서 다음 수행 작업을 가져옴
     * @param prevTaskId 직전 작업 번호
     */
    static getNextFromTreeMap(prevTaskId){

        this.makeTreeMap();
        if(PROCESS_TASK_TREE_MAP && PROCESS_TASK_TREE_MAP[prevTaskId]){
            //= return Next task
            return PROCESS_TASK_TREE_MAP[prevTaskId]
        }else if(!PROCESS_TASK_TREE_MAP){
            console.log('정의된 프로세스가 없습니다.')
            return null;
        }else{
            return null;
        }
    }

    /**
     * DB로부터 작업 목록 불러오기
     * @param prcId
     * @param handler
     */
    static loadAndInitTask(prcId, handler){
        ProcessService.getTaskList(prcId).then(r => {
            if (r !== undefined && r.status === 200) {
                this.initTaskMapByList(r.data);

                if(handler){
                    handler(PROCESS_TASK_MAP);
                }

            } else {
                Swal.fire("태스크 정보를 가져오는데 실패하였습니다.")
            }
        })
    }

    /**
     * 작업 수행
     * @param task 작업 정보
     * @param inputData
     * @param successHandler 성공 핸들러
     * @param errorHandler 실패 핸들러
     * @param toastId 토스트 번호
     */
    static runTask(task,inputData, successHandler, errorHandler,toastId){

        task.taskDef['inputData'] = inputData;
        // 작업 상태 변경 -> PREGRESS
        TaskService.setTaskState(task.taskId,"PROGRESS")
        // 작업 수행 요청
        TaskService.testTask(task).then((r) => {
            if (r && r.status === 200) {
                //= 수행결과 저장
                this.taskSaveToLocal(task.taskId,{
                    ...task,
                    lastTest:r.data,
                    taskState:r.data.state
                })

                //= 수행 내역 중 Exception이 있으면 Error
                if (r.data.exceptions.length > 0) {
                    errorHandler(r.status,'Error 발생 [1001] ',r.data,task,toastId);
                    TaskService.setTaskState(task.taskId,"ERROR")
                } else {
                    successHandler(r.data,task,toastId);
                }
            } else {
                console.log('status != 200 : result',r)
                TaskService.setTaskState(task.taskId,"ERROR")
                errorHandler(r.status,'Error 발생 [1002] ',toastId);
            }
        }).catch((reason) => {
            TaskService.setTaskState(task.taskId,"ERROR")
            errorHandler(reason.response.status,'Error 발생 [1003]',reason.response.data,task,toastId);
            console.log('reason',reason)
        })
    }

    /**
     * 작업 목록 -> 작업 맵 , 작업 맵 초기화
     * @param taskList
     */
    static initTaskMapByList(taskList) {
        //= Text taskDef to Object taskDef
        const parsedData = taskList.map(d => {


            let taskDef;
            let lastTest;
            if(d.taskDef) {
                taskDef = JSON.parse(d.taskDef)
            }

            if(d.lastTest){

                lastTest = JSON.parse(d.lastTest)
            }


            return {
                ...d,
                taskDef: taskDef,
                lastTest:lastTest
            }
        })


        PROCESS_TASK_MAP = {}
        parsedData.forEach(task => {
            PROCESS_TASK_MAP[task.taskId] = task
        })
    }

    /**
     * 작업 가져오기
     * @param taskId 작업 번호
     */
    static getTask(taskId){
        if(PROCESS_TASK_MAP){
            return PROCESS_TASK_MAP[taskId];
        }
        else  return null;
    }

    /**
     * 작업 로컬 임시 저장
     * @param taskId
     * @param taskMeta
     */
    static taskSaveToLocal(taskId, taskMeta){
        PROCESS_TASK_MAP[taskId]=taskMeta;
    }

    /**
     * 프로세스 저장 (DB에 작업목록 저장)
     * @param prcId 프로세스번호
     * @param taskList 작업 목록
     * @returns {Promise<AxiosResponse<any>>}
     */
    static saveProcess(prcId, taskList){
        taskList = taskList.filter(task =>task).map(t=>{

            //마지막 실행 결과 중 데이터 부분은 제외함
            const lastTest={
                ...t.lastTest,
                inputData:undefined,
                outputData:undefined
            }

            //데이터 부분 제외
            const taskDef={
                ...t.taskDef,
                inputData:undefined
            }


            return {
                ...t,
                taskDef:JSON.stringify(taskDef),
                lastTest:JSON.stringify(lastTest)
            }
        })


        return axios.post(`/api/prc/tasks/save/${prcId}`,
            taskList);
    }

    /**
     * 작업 삭제 (로컬에 저장된 작업 삭제)
     * @param taskId
     */
    static removeTask(taskId){
        delete PROCESS_TASK_MAP[taskId];

        Object.values(PROCESS_TASK_MAP).forEach(p=>{
            if(p.precdTaskId && p.precdTaskId === taskId){
                delete p.precdTaskId;
            }
        })
    }

    /**
     * 작업 테스트 수행 요청
     * @param taskMeta
     * @returns {Promise<AxiosResponse<any>>}
     */
    static testTask(taskMeta){

        taskMeta = {
            ...taskMeta,
            taskDef:JSON.stringify(taskMeta.taskDef),
            lastTest:undefined
        }

        return axios.post(`/api/prc/task/run`,
            taskMeta);
    }


}

export default TaskService;
