import React, {useEffect, useState} from 'react';
import {Card, Stack} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import DataWorkService from "../service/DataWorkService";
import MakeFieldComponent from "../manage/component/MakeFieldComponent";
import DatasetService from "../service/DatasetService";
import Swal from "sweetalert2";
import DataService from "../service/DataService";
import LightBtn from "../component/buttons";
import {Title2, Title3} from "../component/titles";
import BoxBlack from "../component/Boxes";
import "./DataWorkHistory.css"

const DataWorkMonitoring = () => {
    const [currentDtId, setCurrentDtId] = useState(0);
    const [selectedData, setSelectedData] = useState({});
    const [detailOpen, setDetailOpen] = useState(false);

    const nav = useNavigate();
    const params = useParams();

    const [mntrType, setMntrType] = useState("");
    const [btnType, setBtnType] = useState(null);

    const [fieldList, setFieldList] = useState([]);
    const [datasetInfo, setDatasetInfo] = useState({});

    const [dtId, setDtId] = useState(0);
    const [dtsId, setDtsId] = useState(null);

    const [wkCondition, setWkCondition] = useState(null);

    const uploadClick = () => {
        var headerTagList = document.getElementsByClassName("dz-ui-header");
        for (var i in headerTagList) {
            // var headerTag = document.getElementsByClassName("dz-ui-header")[0];
            if(i === "length") break;
            var headerTag = headerTagList[i];
            if(headerTag) {
                var point = headerTag.getElementsByTagName("svg")[0].getBoundingClientRect();
                var x = point.left + 10;
                var y = point.top + 10;
                var evt = document.createEvent("MouseEvents");
                evt.initMouseEvent("click", true, true, window, 0,0,0,0,0, false, false, false, 0, null);
                var cb = document.elementFromPoint(x, y);
                try {
                    cb.dispatchEvent(evt);
                }
                catch {
                    console.log("Not Image");
                }
            }
        }
        if(headerTagList.length !== 0) {
            setDtId(null);
        }
    };

    const loadWork = () => {
        // WkId => DtsId, 저장 후 DtId
        DataWorkService.getWork(params.wkId).then((r) => {
            var data = r.data;
            var conditionList = JSON.parse(data.wkCondition);
            var conditionMap = {};
            for(var i in conditionList) {
                var condition = conditionList[i];
                var column = condition["column"];
                var type = condition["type"];
                var check = condition["check"];
                conditionMap[column] = {type:type, check:check};
            }
            setWkCondition(conditionMap);
            setDtsId(data.dtsId);
            setSelectedData({});
            setMntrType(r.data.wkType);
            setDetailOpen(true);
            loadDatasetInfo(data.dtsId);
            loadMntrData(false, r.data.wkType, conditionMap);
        });
    };

    const loadMntrData = (check, type, conditionMap) => {
        if(type === "수집") {
            if(check) {
                DataService.saveRowData(dtsId, btnType==="prev"?dtId:null, selectedData, "mntr").then((r) => {
                    if(btnType==="prev") setDtId(0);
                    else setDtId(r.data);
                    setCurrentDtId(r.data);
                    setSelectedData({});
                    if(btnType !== "prev") {
                        saveHistory(dtsId, r.data);
                    }
                });
            }
        }
        else if(type === "라벨링") {
            DataWorkService.loadMntrData(params.wkId, wkCondition?wkCondition:conditionMap).then((r) => {
                var dataMeta = r.data.dataMeta;
                var status = r.status;
                var message = "";
                if(status === 204) {
                    message = "작업할 데이터가 없습니다.";
                    Swal.fire(message);
                } else if(status === 406) {
                    message = "작업 권한이 없습니다.";
                    Swal.fire(message);
                }
                else {
                    setDtId(dataMeta.dtId);
                    setSelectedData(r.data.values);
                    setCurrentDtId(dataMeta.dtId);
                    setDetailOpen(true);
                    loadDatasetInfo(dataMeta.dtsId);
                    if(check) {
                        saveHistory(dataMeta.dtsId, currentDtId);
                        saveTempValue(currentDtId, selectedData);
                    }
                }
            });
        }
    };

    const reloadBoard = () => {
        // var prtcpUsr = params.prtcpUsr;
        //
        // DataWorkService.getMntrValueList(params.wkId).then((r) => {
        //     if (r !== undefined && r.status === 200) {
        //         setHistoryList(r.data);
        //         if (r.data.length !== 0) {
        //             loadDatasetInfo(r.data[0].dtsId);
        //         }
        //     }
        // })

    };

    const loadDatasetInfo = (dtsId) => {
        DatasetService.loadDatasetInfo(dtsId, setDatasetInfo, setFieldList,
            (data, fields) => {
                //! Data Load
                // reloadData(0,10, dtsId);
                if (datasetInfo.length === 0) {

                }
            },
            (msg) => {
                Swal.fire(msg)
            })
    };

    const saveTempValue = (dtId, value) => {

        var saveValueList = [];

        for(var v in value) {
            var saveValue = {};
            saveValue["dtId"] = dtId;
            saveValue["fdCd"] = v;
            saveValue["vlVal"] = value[v];
            saveValueList.push(saveValue);
        }

        DataWorkService.saveTempValue(saveValueList);
    };

    const saveHistory = (dtsId, dtId) => {

        var saveHistory = {};
        saveHistory["wkId"] = params.wkId;
        saveHistory["dtsId"] = dtsId;
        saveHistory["dtId"] = dtId;

        DataWorkService.saveHistory(saveHistory);
    };

    const getPrevMntr = (dtId) => {
        setBtnType("prev");
        DataWorkService.getPrevMntrData(dtId, params.wkId, mntrType).then(r => {
            var dataMeta = r.data.dataMeta;
            setDtId(dataMeta.dtId);
            setSelectedData(r.data.values);
            setCurrentDtId(dataMeta.dtId);
        });
    };

    const getNextMntr = (dtId) => {
        setBtnType("next");
        if(mntrType === "라벨링") {
            DataWorkService.getNextMntrData(dtId, params.wkId).then(r => {
                loadMntrData(true, mntrType);
            });
        }
        else if(mntrType === "수집") {
            setSelectedData({});
            loadMntrData(true, mntrType);
        }
    };

    useEffect(() => {
        // reloadBoard();
        loadWork();
    }, []);

    useEffect(() => {
        if(dtId && btnType !== "prev") {
            uploadClick();
        }
    }, [dtId]);

    return (
        <Card>
            <Card.Header>
                <Stack direction={"horizontal"}>
                    <Title2>작업 진행</Title2>
                    <LightBtn
                        color="gray"
                        size="small"
                        clickHandler={() => {
                            nav(-1)
                        }}
                    >목록</LightBtn>
                </Stack>
            </Card.Header>
            <Card.Body>
                <div style={{margin:"auto", maxWidth:"700px"}} className={"flexGrid-2 workHistory"}>
                    <div>
                        <Card>
                            <Card.Header><Title3>작업 상세</Title3></Card.Header>
                            <Card.Body>
                                <BoxBlack>
                                    {
                                        detailOpen === true ?
                                            fieldList.map(f => {
                                                var wc = wkCondition[f.fdCd];
                                                var type;
                                                if(wc) {
                                                    type = wkCondition[f.fdCd].type;
                                                } else {
                                                    type = "read"
                                                }
                                                return (
                                                    <MakeFieldComponent
                                                        key={f.fdCd}
                                                        field={f}
                                                        data={selectedData}
                                                        type={type}
                                                        setData={setSelectedData}
                                                        reloadData={reloadBoard}
                                                        dtId={dtId ? dtId : null}
                                                        dtsId={dtsId ? dtsId : null}
                                                        uploadDtId={dtId ? dtId : null}
                                                        mntrCheck={"M"}
                                                    />
                                                )
                                            }) : null
                                    }
                                    {
                                        detailOpen === true ?
                                            <div className={"btns"}>
                                                <LightBtn color={"red"} size={"middle"} clickHandler={() => {
                                                    getPrevMntr(dtId);
                                                }}> 이전 </LightBtn>
                                                <LightBtn color={"green"} size={"middle"} clickHandler={() => {
                                                    getNextMntr(currentDtId);
                                                }}>
                                                    다음
                                                </LightBtn>
                                            </div> : null
                                    }
                                </BoxBlack>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default DataWorkMonitoring;
