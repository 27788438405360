import React from 'react';
import ProjectType from "./component/ProjectType";
import StepFooter from "./component/StepFooter";
import "./popup.css"

const ProjectTypeSelect = (props) => {
    return (
        <div className={"popup-wrapper"}>
            <div className={"popup-header"}>
                <div className={"ph-ttl"}>
                    <h5>프로젝트 작업 선택</h5>
                    <p>새 프로젝트를 생성하거나 기존 프로젝트를 사용하여 진행합니다.</p>
                </div>
            </div>
            <div className={"popup-body"}>
                <div className={"project-type-container"}>
                    <ProjectType
                        ttl={"새 프로젝트 생성"}
                        ettl={"Create new project"}
                        sttl={"새로운 프로젝트를 만들어 데이터 등록을 진행합니다."}
                        icon={"create"}
                        nextStep={props.goToStep}
                        setPrjId={props.setPrjId}
                        setDtsId={props.setDtsId}
                        setProjectList={props.setProjectList}
                        setDatasetList={props.setDatasetList}
                        setFieldList={props.setFieldList}
                        setNProject={props.setNProject}
                        stepN={4}
                    >
                    </ProjectType>
                    <div className={"project-type-split"}>OR</div>
                    <ProjectType
                        ttl={"기존 프로젝트 선택"}
                        ettl={"Select existing project"}
                        sttl={"이미 보유하고있는 프로젝트를 선택하여 진행합니다."}
                        icon={"select"}
                        nextStep={props.goToStep}
                        reloadBoard={props.reloadBoard}
                        stepN={3}
                    >

                    </ProjectType>
                </div>
            </div>
            <StepFooter
                btnList={[]}
            >
            </StepFooter>
        </div>
    );
};

export default ProjectTypeSelect;
