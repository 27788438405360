import ManageService from "./ManageService";
import Swal from "sweetalert2";

class DataStorageService{
    static loadStorageList = (setStorageList) => {
        ManageService.getStorageList().then((r) => {
            if (r !== undefined && r.status === 200) {
                setStorageList(r.data)
            } else {
                Swal.fire("유저 검색 실패")
            }
        })
    };
}
export default DataStorageService;