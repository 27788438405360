import React from 'react';
import ProcessService from "../../service/ProcessService";
import DataTable from "react-data-table-component";
import DataTableStyle from "../../config/DataTableStyle";
import LightBtn from "../../component/buttons";
import Swal from "sweetalert2";
import memoize from "memoize-one";
import ReactTooltip from "react-tooltip";

const processDatasetListColumns = memoize((deleteProcessDataset, prcId) => [
    {
        width: "50px",
        name: 'ID',
        center: true,
        selector: row => row.dtsId
    },
    {
        width: "70px",
        name: '분류',
        center: true,
        selector: row => row.dtsType
    },
    {
        name: '데이터셋 명',
        selector: row => row.dtsTitle
    },
    {
        name: '관리',
        button: true,
        minWidth: '150px',
        cell: row => <>
            <LightBtn size={'tiny'} color={'red'} icon={'delete'} clickHandler={() => {
                //TODO 삭제 기능 구현
                Swal.fire({
                    title : '',
                    text : "프로세스 데이터셋을 삭제하시겠습니까?",
                    type : "warning",
                    showCancelButton : true,
                    confirmButtonClass : "btn-danger",
                    confirmButtonText : "예",
                    cancelButtonText : "아니오",
                    closeOnConfirm : false,
                    closeOnCancel : true
                }).then(result => {
                    if(result.isConfirmed) {
                        const processDataset = {
                            prcId: prcId,
                            dtsId: row.dtsId
                        };

                        deleteProcessDataset(processDataset);
                        Swal.fire("프로세스 데이터셋이 삭제되었습니다.")
                    }
                })
                // Swal.fire("Not Implemented. ")
            }}/>
        </>,
    }
])


const DatasetListComponent = (props) => {

    const deleteProcessDataset = (processDataset) => {
        ProcessService.deleteProcessDataset(processDataset).then((r) => {
            if (r !== undefined && r.status === 200) {
                props.reloadProcessDataset();
            } else {

            }
        });
    };

    return (
        <DataTable
            columns={processDatasetListColumns(deleteProcessDataset, props.prcId)}
            data={props.datasetList}
            customStyles={DataTableStyle.smCompactGrid}
            onRowClicked={props.rowClick}
        />

    );
};

export default DatasetListComponent;
