import React from 'react';
import {Form} from "react-bootstrap";
import FdTypeFormComponent from "./FdTypeFormComponent";

const MakeFieldComponent = (props) => {

    // const params = useParams();

    return (
        <>
            <Form.Group key={props.field.fdCd} className="mb-3">
                <Form.Label style={{width:'100%'}}>{props.field.fdNm}</Form.Label>
                <FdTypeFormComponent
                    field={props.field}
                    data={props.data}
                    type={props.type}
                    setData={props.setData}
                    dtId={props.dtId}
                    dtsId={props.dtsId}
                    reloadData={props.reloadData}
                    updateSetData={props.updateSetData}
                    uploadDtId={props.uploadDtId}
                    saveCheck={props.saveCheck}
                    mntrCheck={props.mntrCheck?props.mntrCheck:"V"}
                />
                {props.field.child !== undefined ? (
                    <div className={"childField"}>
                        {
                            props.field.child.map(fc => {
                                return (
                                    <MakeFieldComponent
                                        key={fc.fdCd}
                                        field={fc}
                                        data={props.data}
                                        type={props.type}
                                        setData={props.setData}
                                        dtId={props.dtId}
                                        dtsId={props.dtsId}
                                    />)
                            })
                        }
                    </div>
                ) : ""}
            </Form.Group>
        </>
    );
};

export default MakeFieldComponent;
