import React, {useState} from 'react';
import {Outlet} from "react-router-dom";

const DataWorkPage = () => {

    const [pp, setPp] = useState('pageParam');

    return (
        <div className={'pt-2'}>
            {/*data manage page*/}
            <Outlet
                context={[pp]}
                setPp={setPp}
            />
        </div>
    );
};

export default DataWorkPage;
