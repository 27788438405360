import React, {useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import DataTableStyle from "../../../config/DataTableStyle";
import memoize from "memoize-one";

const VALIDATION_DATA_COLUMNS = memoize(() => [
    {
        name: '필드 명',
        center: true,
        selector: row => row.fdNm
    },
    {
        name: '유효 데이터 수',
        selector: row => row.vali,
    },
    {
        name: '비 유효 데이터 수',
        selector: row => row.notVali,
    },
]);

const ExcelResultTable = (props) => {

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        var fieldM = {};
        for(var i in props.fieldList) {
            var fdNm = props.fieldList[i]["fdNm"];
            var fdCd = props.fieldList[i]["fdCd"];
            var childList = props.fieldList[i]["child"];
            if(childList) {
                fieldM = childField(childList, fieldM);
            }
            fieldM[fdCd] = fdNm;
        }

        var tableList = [];
        if(props.excelValidation) {
            for(var key in props.excelValidation) {
                var vali = props.excelValidation[key];
                var field = fieldM[key];
                var tableMap = {};
                tableMap["fdNm"] = field;
                tableMap["vali"] = props.excelDataCount - vali;
                tableMap["notVali"] = vali;
                tableList.push(tableMap);
            }
        }
        setTableData(tableList);
    }, [props.excelValidation]);
    
    const childField = (childList, fieldM) => {
        for(var i in childList) {
            var child = childList[i];
            var fdNm = child["fdNm"];
            var fdCd = child["fdCd"];
            fieldM[fdCd] = fdNm;
        }
        return fieldM;
    };

    return (
        <DataTable
            columns={VALIDATION_DATA_COLUMNS()}
            data={tableData}
            customStyles={DataTableStyle.smCompactGrid}
            highlightOnHover
            // pointerOnHover
            pagination
        />
    )
};

export default ExcelResultTable;