import React, {useMemo, useState} from 'react';
import AuthenticationService from "../auth/AuthenticationService";
import {useParams} from "react-router-dom";
import axios from "axios";
import {Card, Row, Container} from "react-bootstrap";
import ProjectTypeSelect from "./ProjectTypeSelect";
import ProjectContent from "./ProjectContent";
import DatasetContent from "./DatasetContent";
import ProjectList from "./ProjectList";
import StepWizard from "react-step-wizard";
import ManageService from "../page/service/ManageService";
import FieldList from "./FieldList";
import FieldContent from "./FieldContent";
import DataList from "./DataList";
import DataContent from "./DataContent";
import DatasetService from "../page/service/DatasetService";
import "./popup.css"
import Swal from "sweetalert2";
import DataService from "../page/service/DataService";
import FileDataContent from "./FileDataContent";
import StartPopup from "./StartPopup";
import HtmlPopup from "./HtmlPopup";


const ManagePopup = (props) => {

    const params = useParams();

    const stepUrl = window.location.search.split("=")[1];

    const [prjId, setPrjId] = useState(null);
    const [dtsId, setDtsId] = useState(null);

    /**
     *= 인증 정보 등록
     */
    useMemo(() => {

        const prevInterceptor = AuthenticationService.getPrevInterceptor();
        if (AuthenticationService.getPrevInterceptor() !== undefined) {
            if(prevInterceptor['request']) axios.interceptors.request.eject(prevInterceptor['request'])
            if(prevInterceptor['response']) axios.interceptors.response.eject(prevInterceptor['response'])
        }



        const requestInterceptor = axios.interceptors.request.use(
        config => {
            const token = params.token;
            if (token) {
                config.headers['Authorization'] = AuthenticationService.createJWTToken(token);
            }
            return config;
        },
        error => {
            Promise.reject(error)
        });


        AuthenticationService.setPrevInterceptor({
            request : requestInterceptor
        })

    }, []);

    const [projectList, setProjectList] = useState([]);
    const [datasetList, setDatasetList] = useState([]);
    const [fieldList, setFieldList] = useState([]);
    const [datasetInfo, setDatasetInfo] = useState({});
    const [data, setData] = useState({});
    const [rData, setRData] = useState({});

    const reloadBoard = () => {
        ManageService.getProjectBoard().then((r) => {
            if (r !== undefined && r.status === 200)
                setProjectList(r.data);
        })
    };

    const reloadDataset = (prjId) => {
        if(prjId) {
            ManageService.getDatasetBoard(prjId).then(r => {
                if (r !== undefined && r.status === 200) {
                    setDatasetList(r.data);
                }
            })
        }
    };

    const [columns, setColumns] = useState([]);
    const [pending, setPending] =  useState(true);
    const [totalRows, setTotalRows] = useState(0);

    const fieldToColumn = (fields) => {
        const columnData = [{
            name: '데이터번호',
            selector: row => row.dataMeta.dtId,
            sortable: true
        }];

        fields.forEach(f => {
            columnData.push({
                name: f.fdNm,
                selector: row => row.values[f.fdCd],
            })

            if (f.child !== undefined) {
                f.child.forEach(c => {
                    columnData.push({
                        name: c.fdNm,
                        selector: row => row.values[c.fdCd],
                    })
                });
            }
        });

        return columnData;
    };

    const loadDatasetInfo = (dtsId) => {
        if(dtsId) {
            DatasetService.loadDatasetInfo(dtsId, setDatasetInfo, setFieldList,
                (data, fields) => {
                    setTotalRows(data.totalDataSize);
                    const c = fieldToColumn(fields);
                    setColumns(c);
                    setPending(false);
                    if(datasetInfo.length === 0) {

                    }
                    reloadData(dtsId, 0, 20);
                },
                (msg) => {
                    Swal.fire(msg)
                })
        }
    };

    const reloadData = (_dtsId, nPape, pPage) => {
        if(_dtsId === null) {
            _dtsId = dtsId;
        }
        if(_dtsId) {
            DataService.getRowData(_dtsId, nPape, pPage).then(r => {
                if (r !== undefined && r.status === 200) {
                    setRData(r.data);
                } else {
                    //TODO  예외처리
                }
            })
        }
    };

    const [nProject, setNProject] = useState({prjNm: '', prjDscr: ''});

    const [datasetObj, setDatasetObj] = useState({
        dtsType: 'FILE',
        dtsTitle: '',
        dtsDscr: ''
    });

    const [fieldObj, setFieldObj] = useState({
        fdCd: '',
        fdType: 'TEXT',
        fdNm: '',
        fdFormat: '',
        fdParent: ''
    });

    return (
        <>
            <Card className={'popup-flow'}>
                <Card.Body>
                    <Row>
                        <StepWizard>
                            <StartPopup
                                setPrjId={setPrjId}
                                setDtsId={setDtsId}
                                stepUrl={stepUrl}
                                reloadDataset={reloadDataset}
                                loadDatasetInfo={loadDatasetInfo}
                                setNProject={setNProject}
                                setDatasetObj={setDatasetObj}
                            />
                            <ProjectTypeSelect
                                reloadBoard={reloadBoard}
                                setPrjId={setPrjId}
                                setDtsId={setDtsId}
                                setProjectList={setProjectList}
                                setDatasetList={setDatasetList}
                                setFieldList={setFieldList}
                                setNProject={setNProject}
                            />
                            <ProjectList
                                reloadDataset={reloadDataset}
                                projectList={projectList}
                                setPrjId={setPrjId}
                                setNProject={setNProject}
                            />
                            <ProjectContent
                                loadDatasetInfo={loadDatasetInfo}
                                datasetList={datasetList}
                                prjId={prjId}
                                setDtsId={setDtsId}
                                setPrjId={setPrjId}
                                nProject={nProject}
                                setNProject={setNProject}
                                setDatasetObj={setDatasetObj}
                            />
                            <DatasetContent
                                prjId={prjId}
                                dtsId={dtsId}
                                setDtsId={setDtsId}
                                setDatasetObj={setDatasetObj}
                                datasetInfo={datasetInfo}
                                datasetObj={datasetObj}
                                reloadDataset={reloadDataset}
                            />
                            <FieldList
                                prjId={prjId}
                                dtsId={dtsId}
                                datasetObj={datasetObj}
                                fieldList={fieldList}
                                fieldObj={fieldObj}
                                setFieldObj={setFieldObj}
                                datasetInfo={datasetInfo}
                                loadDatasetInfo={loadDatasetInfo}
                            />
                            <FieldContent
                                dtsId={dtsId}
                                fieldList={fieldList}
                                fieldObj={fieldObj}
                                setFieldObj={setFieldObj}
                                loadDatasetInfo={loadDatasetInfo}
                            />
                            <DataList
                                columns={columns}
                                pending={pending}
                                totalRows={totalRows}
                                data={rData}
                                dtsId={dtsId}
                                reloadData={reloadData}
                            />
                            <DataContent
                                data={data}
                                setData={setData}
                                pData={data}
                                dtsId={dtsId}
                                fieldList={fieldList}
                                loadDatasetInfo={loadDatasetInfo}
                                reloadData={reloadData}
                            />
                            <FileDataContent
                                dtsId={dtsId}
                            />
                            <HtmlPopup
                            />
                        </StepWizard>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default ManagePopup;
