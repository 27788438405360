import React from 'react';
import {Form, Modal} from "react-bootstrap";
import LightBtn from "../../../component/buttons";
import manageService from "../../../service/ManageService";
import Swal from "sweetalert2";

const DatasetRePermissionModal = ({isOpen, onHide, rePermissionVo, setRePermissionVo, reload}) => {

    const hide = () => {
        onHide();
        reload();
        setRePermissionVo({
            dtsId: 0, shareUser: '', permissionType: ''
        });
    }

    const rePermissionDataset = () => {
        manageService.rePermissionDataset(rePermissionVo).then((r)=>{
            if (r !== undefined && r.status === 200) {
                hide();
            }else{
                Swal.fire("권한 변경 실패")
            }
        })
    }
    return (
        <Modal show={isOpen} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>데이터셋 사용자 공유 권한 재설정</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>사용자 명</Form.Label>
                    <Form.Control disabled type="text" value={rePermissionVo.shareUser}></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>권한</Form.Label>
                    <Form.Select defaultValue={rePermissionVo.permissionType} onChange={(e) => {
                        setRePermissionVo({...rePermissionVo, permissionType: e.target.value})
                    }}>
                        <option value={"R"}>Read (읽기)</option>
                        <option value={"W"}>Write (읽기, 쓰기/수정, 삭제)</option>
                        <option value={"G"}>Grant (모든 권한) </option>
                    </Form.Select>

                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={hide}>
                    취소
                </LightBtn>
                <LightBtn color={"green"} size={"middle"} clickHandler={rePermissionDataset}>
                    재설정
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default DatasetRePermissionModal;