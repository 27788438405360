import React, {Component} from 'react';
import "./ErrorPage.css"
import {Link} from "react-router-dom";

class ErrorPage extends Component {
    render() {
        let engMsg = "";
        let korMsg = "";

        if (this.props.errorCode == '404') {
            engMsg = 'Page Not Be Found';
            korMsg = ' 페이지를 찾을 수 없습니다.';
        } else if (this.props.errorCode == '401') {
            engMsg = 'Unauthorized';
            korMsg = ' 유효한 인증 자격 증명이 없습니다.';
        } else if (this.props.errorCode == '403') {
            engMsg = 'Forbidden';
            korMsg = ' 접근 권한이 없습니다.';
        } else if (this.props.errorCode == '500') {
            engMsg = 'Internal Server error';
            korMsg = ' 내부 서버 오류 일시 오류';
        }

        return (
            <div>
                <div id="notfound">
                    <div className="notfound">
                        <div className="notfound-404"/>
                        <h1> {this.props.errorCode}</h1>
                        <h2>{engMsg}</h2>
                        <p>{korMsg}</p>
                        <Link to="/">Back to homepage</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorPage;
