import React from 'react';
import StepFooter from "./component/StepFooter";
import {Card} from "react-bootstrap";
import "./popup.css"
import DataViewer from "../page/manage/DataViewer";
import DataViewTableComponent from "../page/manage/component/DataViewTableComponent";
import PopupATPageHeader from "./component/PopupATPageHeader";

const DataList = (props) => {

    return (
        <>
            <div className={"popup-wrapper"}>
                <div className={"popup-header"}>
                    <div className={"ph-ttl"}>
                        <h5>데이터 목록</h5>
                        <p>데이터셋에 저장된 데이터 정보를 보여주고 데이터를 추가할 수 있습니다.</p>
                    </div>
                </div>
                <div className={"popup-body"}>
                    {
                        props.dtsId !== null ?
                            <DataViewer
                                className={"popupModal"}
                                perPage={20}
                                dtsId={props.dtsId}
                                styleType={"popup"}
                            /> : null
                    }
                    <div className={"popup-footer"}>
                        <StepFooter
                            btnList={[{"Name": "이전", "Step": 6}]}
                            goToStep={props.goToStep}
                        >
                        </StepFooter>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DataList;
