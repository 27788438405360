import React from 'react';
import './index.css';
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {CookiesProvider} from "react-cookie";

import {createRoot} from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <BrowserRouter>
        <CookiesProvider>
            <App/>
            <footer>Copyright 2022. (주)사미텍. All rights reserved.</footer>
        </CookiesProvider>
    </BrowserRouter>
);
