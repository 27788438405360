import React, {memo} from 'react';

import {Handle} from 'react-flow-renderer';
import {ProgressBar, Stack} from "react-bootstrap";
import "./ProcessGraphNode.css"
import TaskService from "../../../service/TaskService";

const nodeWidth = 160;
const nodeHeight = 45;

export default memo(({data, isConnectable, sourcePosition, targetPosition}) => {

    const taskMeta = TaskService.getTask(data.taskId);


    let processBar = null;
    let stateColor = "";
    if(taskMeta)
    {
        if(taskMeta.taskState === "READY"){
            processBar = <ProgressBar animated variant="secondary" now={100}/>;
            stateColor="bg-secondary";
        }
        else if(taskMeta.taskState === "ERROR"){
            processBar = <ProgressBar animated striped variant="warning" now={100}/>;
            stateColor="bg-warning";
        }
        else if(taskMeta.taskState === "STOP"){
            processBar = <ProgressBar animated striped variant="danger" now={100}/>;
            stateColor="bg-danger";
        }
        else if(taskMeta.taskState === "PROGRESS"){
            processBar = <ProgressBar animated striped variant="success" now={100}/>;
            stateColor="bg-success";
        }
        else if(taskMeta.taskState === "DONE"){
            processBar = <ProgressBar animated striped variant="info" now={100}/>;
            stateColor="bg-info";
        }
    }else{
        processBar = <ProgressBar animated variant="light" now={100}/>;
    }


        return (
        <>
            <div className={"dft-node"} style={{width: nodeWidth, height: nodeHeight}}>
                <Handle
                    type="target"
                    position={targetPosition}
                    style={{background: '#555'}}
                    // onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                />
                <Stack className={"node-header"} direction="horizontal">
                    <div className="node-header-type">{taskMeta ? taskMeta.taskType : ""}</div>
                    <div className={`node-header-state ms-auto ${stateColor}`}>
                        {taskMeta?taskMeta.taskState:"READY"}
                    </div>
                </Stack>
                <div className={"node-body"}>
                    <strong>{taskMeta.taskNm} </strong>
                </div>
                {processBar}
                <Handle
                    type="source"
                    position={sourcePosition}
                    style={{background: '#555'}}
                    isConnectable={isConnectable}
                />
            </div>
        </>
    )
        ;
});
