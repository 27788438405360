import React, {useEffect, useState} from 'react';
import {Card, Form, ListGroup, Stack} from "react-bootstrap";
import LightBtn from "../../../component/buttons";
import Swal from "sweetalert2";

const HtmlSelectorTool = ({
                              localProperty,
                              targetField,
                              precdTask,
                              node,
                              value,
                              onChange
                          }) => {

    const [selectors, setSelectors] = useState([]);

    useEffect(()=>{
        if(value) {
            setSelectors(JSON.parse(value))
        }
    },[])

    useEffect(() => {
        onChange(JSON.stringify(selectors))
    }, [selectors]);


    const [newSelector, setNewSelector] = useState(undefined);


    const deleteSelector = ()=>{
        const result = selectors.filter(s => s.name !== newSelector.name);
        setSelectors([...result]);
        setNewSelector(undefined);
    }

    const saveSelector = ()=>{
        if(newSelector&& newSelector.name===""){
            Swal.fire("선택자 이름을 입력해주세요.");

            return;
        }
        let isChange = false;
        for(let idx in selectors){
            if(selectors[idx].name === newSelector.name){
                selectors[idx]=newSelector;
                isChange=true;
            }
        }
        if(isChange)
        {
            setSelectors([...selectors]);
        }else{
            setSelectors([...selectors,
            newSelector]);
        }

        setNewSelector(undefined);
    }

    return (

        <Card>
            <Card.Header>
                <Stack direction="horizontal" gap={3}>
                    <div>Selectors</div>
                    <div className="ms-auto">
                        <LightBtn  size="sm" color={"green"} size={"sm"} clickHandler={() => {
                            setNewSelector(
                                {
                                    name: "",
                                    selector: "",
                                    selectorType: "Item",
                                    componentType: "text"
                                }
                            )
                        }}>추가</LightBtn>
                    </div>
                </Stack>
            </Card.Header>
            <Card.Body className="border-bottom pb-1">
                {
                    newSelector!==undefined?<div>
                        <Form.Group className="mb-3">
                            <Form.Label>선택자 이름 (필수)</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="Selector Name"
                                value={newSelector.name ? newSelector.name : ""}
                                onChange={(e) => {
                                    setNewSelector({
                                        ...newSelector,
                                        name:e.target.value.toUpperCase()
                                    })
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Selector</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                          size="sm"
                              value={newSelector.selector ? newSelector.selector : ""}
                              onChange={(e) => {
                                  setNewSelector({
                                      ...newSelector,
                                      selector:e.target.value
                                  })
                              }}
                            />
                        </Form.Group>
                        <Form.Label>Selector Type</Form.Label>
                        <Form.Select
                            size="sm"
                            onChange={(e) => {
                                setNewSelector({
                                    ...newSelector,
                                    selectorType:e.target.value
                                })
                            }}
                        >
                            <option value={"Item"} selected={newSelector.selectorType === "Item"} >Item</option>
                            <option value={"List"} selected={newSelector.selectorType === "List"}>List</option>
                        </Form.Select>
                        <Form.Group className="mb-3">
                            <Form.Label>Component Type</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="text|html|attr:href"
                                value={newSelector.componentType ? newSelector.componentType : ""}
                                onChange={(e) => {
                                    setNewSelector({
                                        ...newSelector,
                                        componentType:e.target.value
                                    })
                                }}
                            />
                        </Form.Group>

                        <LightBtn clickHandler={saveSelector} color={"blue"} size={"sm"}>저장</LightBtn>
                        <LightBtn className={"ms-2"} clickHandler={()=>{deleteSelector()}} color={"red"} size={"sm"}>삭제</LightBtn>
                    </div>:null
                }
            </Card.Body>
            <ListGroup variant="flush" className={"mt-1"}>
                {selectors.map((s, i) => {
                    return <Card>
                        <Card.Body className={"bg-transparent border-1 border-secondary p-1"}>
                            <Stack direction="horizontal" gap={3}>
                                <div>{s.name}</div>
                                <div className="ms-auto">
                                    <LightBtn
                                        clickHandler={()=>{
                                            setNewSelector(s)
                                        }
                                        }
                                        color={"outline-warning"} size={"sm"}>수정</LightBtn>
                                </div>
                            </Stack>
                        </Card.Body>
                    </Card>;
                })
                }

            </ListGroup>
        </Card>
    );
};

export default HtmlSelectorTool;
