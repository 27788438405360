import React, {useEffect} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import PopupATPageHeader from "./component/PopupATPageHeader";
import StepFooter from "./component/StepFooter";
import FileDataManager from "../page/manage/FileDataManager";
import {useParams} from "react-router-dom";


const FileDataContent = (props) => {

    const params = useParams();

    useEffect(() => {
        sessionStorage.setItem("token", params.token);
    }, []);

    return (
        <>
            <div className={"popup-wrapper"}>
                <div className={"popup-header"}>
                    <div className={"ph-ttl"}>
                        <h5>파일 데이터셋</h5>
                        <p>파일 데이터를 업로드하고 저장합니다.</p>
                    </div>
                </div>
                <div className={"popup-body"}>
                    {
                        props.dtsId !== null ?
                            <FileDataManager
                                dtsId={props.dtsId}
                            /> : null
                    }
                    <div className={"popup-footer"}>
                        {/*<StepFooter*/}
                        {/*    btnList={[{"Name": "이전", "Step": 5}]}*/}
                        {/*    goToStep={props.goToStep}*/}
                        {/*>*/}
                        {/*</StepFooter>*/}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FileDataContent;
