import {useEffect} from "react";
import Chart from "chart.js";

const useChartMemory = (nodeRef, options) => {

    useEffect(() => {
        if(window.memoryChart) {
            window.memoryChart.destroy();
        }
        window.memoryChart = new Chart(nodeRef.current, options);
    }, [nodeRef, options]);

    return {};
};

export default useChartMemory;
