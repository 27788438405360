import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import AuthenticationService from "./AuthenticationService";

function RequireAuth(props) {

    let location = useLocation();

    const isLogin = AuthenticationService.isUserLoggedIn(props.nav)
    if (!isLogin) {
        return <Navigate to="/login" state={{from: location}}/>;
    }

    return <Outlet/>;
}

export default RequireAuth;
