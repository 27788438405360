import React, {useEffect, useState} from "react";
import {Image} from "react-bootstrap";
import DashboardVisual from "../../images/dashboard_visual.png";
import "./DashboardPage.css";
import DonutCircle from "../component/DonutCircle";
import {Title2, Title3} from "../component/titles";
import {SummaryList} from "./component/summaryList";
import BoxBlack from "../component/Boxes";
import DashboardSlider from "./component/DashboardSlider";
import ManageService from "../service/ManageService";
import TextUtilService from "../../component/service/TextUtilService";
import UserManageService from "../service/UserManageService";


const DashboardPage = ({nav}) => {

    //TODO 아래 두 값은 DB에서 가져오기 (for 도넛그래프)
    let dataCapacity = 50 * 1024 * 1024 * 1024;

    const [dataUsage, setDataUsage] = useState(0);
    const [summary, setSummary] = useState({});

    const [user, setUser] = useState();
    const auth = sessionStorage.getItem('authenticatedUser');

    useEffect(() => {
        UserManageService.userCheck().then(r => {
            if(r.data.mbLevel === "4"){
                nav(`/manage`);
            }
        });
    }, []);

    useEffect(() => {
        ManageService.loadMemberByUsername().then(r => {
            setUser(r.data.mbName);
        });
    }, [auth]);


    const loadData = () => {
        ManageService.getDashBoard("").then((r) => {

            r.data.projectSummaries.sort((p1, p2) => p2.project.crDt - p1.project.crDt);
            r.data.workSummaries.sort((w1, w2) => w1.tbWork.crDt - w2.tbWork.crDt);

            let sortedBySize = [...r.data.projectSummaries];
            sortedBySize.sort((p1, p2) => (p2.deleteDataSize + p2.deleteFilesSize) - (p1.deleteDataSize + p1.deleteFilesSize));

            let sortedByCount = [...r.data.projectSummaries];
            sortedByCount.sort((p1, p2) => (p2.deleteDataCount) - (p1.deleteDataCount));

            let sortedByWorkSize = [...r.data.workSummaries];
            sortedByWorkSize.sort((w1, w2) => (w2.historySize - w1.historySize));

            let sortedByWorkAcptSize = [...r.data.workSummaries];
            sortedByWorkAcptSize.sort((w1, w2) => (w2.historyAcptSize) - (w1.historyAcptSize));

            r.data = {
                ...r.data,
                sortedBySize : sortedBySize,
                sortedByCount:sortedByCount,
                sortedByWorkSize:sortedByWorkSize,
                sortedByWorkAcptSize:sortedByWorkAcptSize
            }
            setSummary(r.data);
        });

        // ManageService.getUsrDataUsage().then((r) => {
        //     setDataUsage(r.data);
        // });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <section className="content">
            <div className="dashboard-header">
                <div>
                    <Image src={DashboardVisual}/>
                    <div className="dashboard-ttl">
                        <h1>
                            DATA
                            <br/>
                            FACTORY
                        </h1>
                        {/*<span>Write the subtext here</span>*/}
                    </div>
                </div>
                <div>
                    <div className="dashboard-summary">
                        <SummaryList
                            link={"/project"}
                            ttl={"소유 프로젝트"}
                            list1={"프로젝트 수"}
                            count1={summary&&summary.userProjectCount ? (summary.userProjectCount + "").replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0"}
                            unit1={'개'}
                            list2={"전체 데이터"}
                            count2={summary&&summary.userDataCount ? (summary.userDataCount + "").replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0"}
                            unit2={'개'}
                        />
                        <SummaryList
                            link={"/project"}
                            ttl={"공유 프로젝트"}
                            list1={"프로젝트 수"}
                            count1={summary&&summary.sharedProjectCount ? (summary.sharedProjectCount + "").replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0"}
                            unit1={'개'}
                            list2={"데이터셋 수"}
                            count2={summary&&summary.sharedDatasetCount ? (summary.sharedDatasetCount + "").replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0"}
                            unit2={'개'}
                        />
                        <SummaryList
                            link={"/project"}
                            ttl={"파일 데이터"}
                            list1={"파일 수"}
                            count1={summary&&summary.userFileCount ? (summary.userFileCount + "").replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0"}
                            unit1={'개'}
                            list2={"파일 용량"}
                            count2={summary&&summary.userFileCount?TextUtilService.bytesToSize(summary.userFileSize):0}
                            unit2={''}
                        />
                    </div>
                    <DonutCircle
                        type={"main"}
                        dataUsage={summary&&summary.userDataSize ?(summary.userDataSize):0}
                        dataCapacity={dataCapacity}
                    />
                </div>
            </div>

            <div className="dashboard-table flexGrid-2">
                <div>
                    <Title2>{user}의 프로젝트</Title2>
                    <BoxBlack>
                        <DashboardSlider title={"최근 작성된 프로젝트"} list={
                            summary&&summary.projectSummaries? summary.projectSummaries.map(ps => {
                                return {
                                    title: ps.project.prjNm,
                                    progress: `데이터 ${ps.deleteDataCount?ps.deleteDataCount:0} 개, ${TextUtilService.bytesToSize(ps.deleteDataSize)}`,
                                    linkTo:`/project/${ps.project.prjId}`
                                }
                            }) : []
                        }/>
                        <DashboardSlider title={"대용량 프로젝트"} list={
                            summary&&summary.sortedBySize? summary.sortedBySize.map(ps => {
                                return {
                                    title: ps.project.prjNm,
                                    progress: `데이터 ${ps.deleteDataCount?ps.deleteDataCount:0} 개, ${TextUtilService.bytesToSize(ps.deleteDataSize)}`,
                                    linkTo:`/project/${ps.project.prjId}`
                                }
                        }) : []
                        }/>
                        <DashboardSlider title={"데이터가 많은 프로젝트"} list={
                            summary&&summary.sortedByCount? summary.sortedByCount.map(ps => {
                                return {
                                    title: ps.project.prjNm,
                                    progress: `데이터 ${ps.deleteDataCount?ps.deleteDataCount:0} 개, ${TextUtilService.bytesToSize(ps.deleteDataSize)}`,
                                    linkTo:`/project/${ps.project.prjId}`
                                }
                            }) : []
                        }/>
                    </BoxBlack>
                </div>
                <div>
                    <Title2>{user}의 작업</Title2>
                    <BoxBlack>
                        <DashboardSlider title={"최근 작성된 작업"} list={
                            summary&&summary.workSummaries? summary.workSummaries.map(wk => {
                                return {
                                    title: wk.tbWork.wkNm,
                                    progress: `데이터 ${wk.historySize?wk.historySize:0} 개`,
                                    linkTo:`/dataWork/${wk.tbWork.wkId}`
                                }
                            }) : []
                        }/>
                        <DashboardSlider title={"작업량이 많은 작업"} list={
                            summary&&summary.sortedByWorkSize? summary.sortedByWorkSize.map(wk => {
                                return {
                                    title: wk.tbWork.wkNm,
                                    progress: `데이터 ${wk.historySize?wk.historySize:0} 개`,
                                    linkTo:`/dataWork/${wk.tbWork.wkId}`
                                }
                            }) : []
                        }/>
                        <DashboardSlider title={"승인 대기가 많은 작업"} list={
                            summary&&summary.sortedByWorkAcptSize? summary.sortedByWorkAcptSize.map(wk => {
                                return {
                                    title: wk.tbWork.wkNm,
                                    progress: `데이터 ${wk.historyAcptSize?wk.historyAcptSize:0} 개`,
                                    linkTo:`/dataWork/${wk.tbWork.wkId}`
                                }
                            }) : []
                        }/>
                    </BoxBlack>
                </div>
            </div>
        </section>
    );
};
export default DashboardPage;
