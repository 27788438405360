import React from 'react';
import Swal from "sweetalert2";

const StepFooter = (props) => {
    return (
        <div>
            {
                props.btnList.map((e, i) => {
                    if(e.Name === "완료"){
                        return <button key={i} className={"popupBtn popupBtn-blue"} onClick={() => {
                            props.goToStep(e.Step);
                        }}
                        >
                            {e.Name}
                        </button>
                    }
                    if(e.Name === "이전" || e.Name === "취소"){
                        return <button key={i} className={"popupBtn popupBtn-gray"} onClick={() => {
                            props.goToStep(e.Step);
                            if(props.cancelHandler !== undefined) {
                                props.cancelHandler();
                            }
                        }}
                        >
                            {e.Name}
                        </button>
                    }else
                    {
                        return <button key={i} className={"popupBtn popupBtn-blue"} onClick={() => {
                            if(props.check !== "") {
                                props.goToStep(e.Step);
                                if(props.saveHandler !== undefined && (props.id === undefined || props.id === null)) {
                                    props.saveHandler();
                                }
                            }
                            else if(props.message !== undefined) {
                                Swal.fire(props.message)
                            }
                        }}
                        >
                            {e.Name}
                        </button>
                    }
                })
            }
        </div>
    );
};

export default StepFooter;