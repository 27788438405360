import React from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import ManageService from "../../../service/ManageService";
import Swal from "sweetalert2";
import LightBtn from "../../../component/buttons";

const ProjectCreateModal = ({show, onHide, nProject, setNProject,reloadBoard}) => {


    const saveProject = () => {
        ManageService.saveProject(nProject).then((r) => {
            if (r !== undefined && r.status === 200) {

                setNProject({
                    prjId: null,
                    prjNm: '', prjDscr: ''
                });
                reloadBoard();
                onHide();
            } else {
                Swal.fire("저장 실패")
            }

        })
    }

    return (
        <Modal
            enforceFocus={false}
            show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>프로젝트 추가</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>프로젝트명</Form.Label>
                    <Form.Control type="text" value={nProject.prjNm}
                                  onChange={(e) => {
                                      setNProject({...nProject, prjNm: e.target.value})
                                  }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>설명</Form.Label>
                    <Form.Control as="textarea" rows={3} value={nProject.prjDscr}
                                  onChange={(e) => {
                                      setNProject({...nProject, prjDscr: e.target.value})
                                  }}/>

                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={onHide}>
                    취소
                </LightBtn>
                <LightBtn color={"green"} size={"middle"} clickHandler={() => {
                    if(!nProject.prjNm) {
                        Swal.fire("프로젝트 명을 입력해주세요.");
                    }
                    else {
                        saveProject();
                    }
                }}>
                    저장
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default ProjectCreateModal;
