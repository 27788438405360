import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// import React from "react";

let prevInterceptor = undefined;

export default class AuthenticationService {


    static getPrevInterceptor(){
        return prevInterceptor;

    }

    static setPrevInterceptor(_prevInterceptor){
        prevInterceptor=_prevInterceptor
    }

    static executeJwtAuthenticationService(username, password) {
        return axios.post('/auth/login', {
            username,
            password
        })
    }

    static refreshJwtAuthenticationService() {
        return axios.post('/auth/refresh')
    }

    static refreshToken(nav) {
        const _this = this;
        this.refreshJwtAuthenticationService().then(response => {
            if (response.data.token) {
                _this.registerSuccessfulLoginForJwt(
                    sessionStorage.getItem('authenticatedUser'),
                    response.data.token,
                    response.data.expiration,
                )
                console.log('토큰 연장 성공')
            } else {
                Swal.fire("토큰 연장 실패")
                //! login 페이지로
                nav('/login')
            }

        })
    }

    static registerSuccessfulLoginForJwt(username, token, expiration) {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('token-exp', expiration);
        sessionStorage.setItem('authenticatedUser', username);
        sessionStorage.setItem('userLevel', username);
    }

    static createJWTToken(token) {
        return 'smt ' + token
    }

    static setupAxiosInterceptors(setUserInfo, nav) {
        const _this = this;

        if (prevInterceptor !== undefined) {
            if(prevInterceptor['request']) axios.interceptors.request.eject(prevInterceptor['request'])
            if(prevInterceptor['response']) axios.interceptors.response.eject(prevInterceptor['response'])
        }

        const requestInterceptor = axios.interceptors.request.use(
            config => {
                const token = sessionStorage.getItem('token');
                if (token) {
                    config.headers['Authorization'] = this.createJWTToken(token);
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });

        const responseInterceptor = axios.interceptors.response.use(
            response => {
                // 응답 데이터를 가공

                return response;
            },
            error => {
                // 오류 응답을 처리
                if (error.response.status === 401 && error.request.responseURL.indexOf("auth/login") === -1) {
                    Swal.fire({
                        title: '시간 만료로 로그아웃되었습니다. 다시 로그인하시겠습니까?',
                        html: '비밀번호 입력',
                        input: 'password',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: '로그인',
                        showLoaderOnConfirm: true,
                        cancelButtonText: '다른 계정으로 로그인',
                        preConfirm: (password) => {
                            const userName = sessionStorage.getItem('authenticatedUser')
                            _this
                                .executeJwtAuthenticationService(userName, password)
                                .then((response) => {
                                    console.log('response',response);
                                    if (response&&response.status===200 && response.data.token) {
                                        _this.registerSuccessfulLoginForJwt(
                                            userName,
                                            response.data.token,
                                            response.data.expiration,
                                        )
                                        setUserInfo({
                                            username: userName,
                                            token: response.data.token,
                                            expiration: response.data.expiration
                                        })
                                        nav(0);
                                    }
                                    else{
                                        console.log(Swal.getFooter());
                                        Swal.showValidationMessage('인증 실패');
                                        return false;
                                    }
                                }).catch((e) => {
                                Swal.fire("로그인 실패", e.message)
                            })
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                        if (result.isConfirmed) {
                            //! 페이지 리로드


                        } else {
                            //!다른 계정으로 로그인
                            nav('/login')
                        }
                    })


                } else return Promise.reject(error);
            });


        prevInterceptor = {
            request: requestInterceptor,
            response: responseInterceptor
        };


    }

    static logout() {
        sessionStorage.removeItem("authenticatedUser");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("token-exp");
    }

    static isUserLoggedIn(nav) {
        const _this = this;
        const token = sessionStorage.getItem('token');
        const tokenExp = sessionStorage.getItem('token-exp');

        if (!token)
            return false;

        const now = new Date();
        const expired = new Date(tokenExp);

        //TODO 일정 시간 이하로 남았을 경우 토큰 재발행 수행
        const elapsedMSec = expired.getTime() - now.getTime();

        const elapsedMin = elapsedMSec / 1000 / 60;

        //! 로그인 토큰 만료
        if (elapsedMin < 0) {

            // let username = sessionStorage.getItem("authenticatedUser");
            const LockScreenSwal = withReactContent(Swal)
            LockScreenSwal.fire('로그인 세션 만료됨')
            return false;
        } else if (/*elapsedMin > 6 &&*/ elapsedMin < 20) {
            //! 30분 이내로 남은 경우 자동 세션 연장
            console.log('Auto refresh token')
            _this.refreshToken(nav);
        }
        // else if (elapsedMin < 6) {
        //     //! n 분 이하로 남으면 연장여부 선택
        //
        //     let timerInterval
        //     Swal.fire({
        //         title: '로그인 시간을 연장하시겠습니까?',
        //         html: '세션 만료까지 <b></b> 초',
        //         timer: elapsedMSec - 10000,
        //         timerProgressBar: true,
        //         showDenyButton: true,
        //         denyButtonText: '로그인 연장',
        //         reverseButtons: true,
        //         didOpen: () => {
        //             Swal.showLoading()
        //             const b = Swal.getHtmlContainer().querySelector('b')
        //             timerInterval = setInterval(() => {
        //                 b.textContent = (Swal.getTimerLeft() / 1000).toFixed(1)
        //             }, 100)
        //         },
        //         willClose: () => {
        //             clearInterval(timerInterval)
        //         }
        //     }).then((result) => {
        //         if (result.dismiss === Swal.DismissReason.timer) {
        //             console.log('I was closed by the timer')
        //             nav('/login')
        //             // window.location.href='/login';
        //         } else if (result.isDenied) {
        //             _this.refreshToken();
        //         }
        //     })
        // }


        // console.log("===UserloggedInCheck - Succeeded===");
        // console.log('현재시간 : ',new Date());
        // console.log('만료시간 : ',new Date(tokenExp));

        // this.setupAxiosInterceptors(this.createJWTToken(token));
        return true;
    }

    static expireLogin(expireDate) {
        const now = new Date();

        const cExpired = new Date(expireDate);

        //TODO 일정 시간 이하로 남았을 경우 토큰 재발행 수행
        const cElapsedMSec = cExpired.getTime() - now.getTime();

        const cElapsedMin = cElapsedMSec / 1000 / 60;

        if (cElapsedMin < 0) {
            return false;
        }
        return true;
    }

    static getLoggedInUserName() {
        //let user = sessionStorage.getItem('authenticatedUser')
        let user = sessionStorage.getItem('authenticatedUser');
        if (user === null) return '';
        return user;
    }

    static getMenus() {
        return axios.post('/auth/menus')
    }
}
