import React, {useEffect, useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import manageService from "../../../service/ManageService";
import Swal from "sweetalert2";
import LightBtn from "../../../component/buttons";
import DataStorageService from "../../../service/DataStorageService";
import {toast} from "react-toastify";

const ProjectCopyModal = ({show, onHide, copyVo, setCopyVo,reloadBoard}) => {

    const [storageList, setStorageList] = useState([])

    const [projectNm, setProjectNm] = useState(false);

    useEffect(() => {
        if (show)
            DataStorageService.loadStorageList(setStorageList);
    }, [show])

    const copyProject = () =>{
        const toast_id = toast.loading("프로젝트 복제 진행")
        manageService.copyProject(copyVo, {
            onUploadProgress: p => {
                toast.update(toast_id, { render:(p.loaded / p.total) });
            }
        }).then((r)=>{
            if (r !== undefined && r.status === 200) {
                reloadBoard();
                onHide();
                toast.update(toast_id, { render: "프로젝트 복제 성공", type: "success",autoClose: 2000, isLoading: false })
            }else{
                toast.update(toast_id, { render: `프로젝트 복제 실패`, type: "error",autoClose: 2000, isLoading: false });
            }
        })
    }

    return (
        <Modal
            enforceFocus={false}
            show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>프로젝트 복제</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>프로젝트 명</Form.Label>
                    <Form.Control isInvalid={projectNm}
                        type="text" value={copyVo.copyName}
                                  onChange={(e) => {
                                      if (e.target.value.length > 0 ){
                                          setProjectNm(false)
                                        setCopyVo({...copyVo, copyName: e.target.value})
                                      }else {
                                          setCopyVo({...copyVo, copyName: e.target.value})
                                          setProjectNm(true)
                                      }
                                  }}/>
                    <Form.Control.Feedback type="invalid">프로젝트 명을 입력해주세요.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>데이터 스토리지</Form.Label>
                    <Form.Select onChange={(e)=>{
                        setCopyVo({...copyVo, strgId: e.target.value})
                    }}>
                        {
                            storageList.map((e, i) => {
                                return (
                                    <option key={e.strgId} value={e.strgId}>{e.strgNm}</option>
                                )
                            })
                        }
                    </Form.Select>

                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={onHide}>
                    취소
                </LightBtn>
                <LightBtn color={"green"} size={"middle"} disabled={projectNm} clickHandler={copyProject}>
                    복제
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default ProjectCopyModal;
