import React from 'react';
import {Col, Row} from "react-bootstrap";
import "../data/DataStatPage.css"

const DataStatComponent = (props) => {

    return (
        <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
                    <span className="info-box-icon"
                          style={{"backgroundColor": "#6AAFE6 !important", "color": "#fff !important"}}>
                        <i className="fas fa-spell-check"></i>
                    </span>

                <div className="info-box-content">
                    <span className="info-box-text">{props.title}</span>
                    <span className="info-box-number">{props.content?(props.content+"").replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0"}</span>
                </div>
            </div>
        </div>
    );
};

export default DataStatComponent;