import React from 'react';

const LightBtn = (props) => {
    return (
        <button
            {...props}
            className={("btn btn-" + props.color + " btn-" + props.size + " " + ((props.icon)?props.icon:""))+" "+(props.className?props.className:"")}
            type={props.type}
            onClick={props.clickHandler}
            disabled={props.disabled}
            style={props.style?props.style:null}
        >
            {props.children}
        </button>
    );
};

export default LightBtn;
